import React, { useEffect, useState } from "react";
import vetCustomerCreditService from "../../../services/vet_customer_credit.service";
import Credit from "../../components/Credits/Credit";

function VetCustomerCredit() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [TotalCredits, setTotalCredits] = useState(0);
  const [paidCredits, setPaidCredits] = useState([]);
  const [unpaidCredits, setUnpaidCredits] = useState([]);
  const [credits, setCredit] = useState([]);
    const [loading, setLoading] = useState(false);

  const handleApprovePayment = async (creditId, status) => {
    if (status) {
      try {
        const response =
          await vetCustomerCreditService.updateCustomerCreditStatus(
            creditId,
            "Paid"
          );
        if (response.status === "success") {
          setSuccessMessage(response.message);
        } else {
          setError("Failed to update status. Please try again.");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        setError("Error editing status. Please try again.");
      }
    }
  };

   useEffect(() => {
     const fetchCustomerCredit = async () => {
       setLoading(true); // Set loading to true before fetching data
       try {
         const response = await vetCustomerCreditService.getAllCredits();

         if (response.status === "success") {
           setCredit(response.data);
           setTotalCredits(response.data.length);
           const paid = response.data.filter(
             (credit) => credit.status === "Paid"
           );
           const unpaid = response.data.filter(
             (credit) => credit.status === "Unpaid"
           );

           setPaidCredits(paid);
           setUnpaidCredits(unpaid);
         } else {
           console.error(`Error fetching customer credit: ${response.status}`);
         }
       } catch (error) {
         console.error("Error fetching customer credit:", error.message);
       } finally {
         setLoading(false);
       }
     };

     // Initial fetch
     fetchCustomerCredit();

     // Fetch every 10 seconds
     const intervalId = setInterval(fetchCustomerCredit, 3000);

     // Cleanup function to clear interval on component unmount
     return () => clearInterval(intervalId);
   }, []);


  const handleDeleteCredit = async (CreditId) => {
    if (window.confirm("Are you sure you want to delete this Credit?")) {
      try {
        const response = await vetCustomerCreditService.deleteCustomerCredit(
          CreditId
        );
        if (response.status === 200) {
          const responseData = await response.json(); // Parse response JSON
          setSuccessMessage(responseData.message); // Display server message
        } else {
          throw new Error("Error: Credit not found");
        }
      } catch (error) {
        setError("Fail: " + error.message);
      }
    }
  };
  const handleEditCredit = async (event, CreditId, updatedCreditData) => {
    event.preventDefault();

    try {
      const response = await vetCustomerCreditService.updateCustomerCredit(
        CreditId,
        updatedCreditData
      );

      if (response.status === "success") {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.location.href = "/dashboard/showroom/vet/customer/credits";
        }, 3000);
      } else {
        setError("Failed to update credit. Please try again.");
      }
    } catch (error) {
      console.error("Error editing credit:", error);
      setError("Error editing credit. Please try again.");
    }
  };
  return (
    <Credit
      TotalCredits={TotalCredits}
      paidCredits={paidCredits}
      unpaidCredits={unpaidCredits}
      credits={credits}
      successMessage={successMessage}
      Error={Error}
      handleEditCredit={handleEditCredit}
      handleDeleteCredit={handleDeleteCredit}
      handleApprovePayment={handleApprovePayment}
      service={"vet"}
      isLoading={loading}
    />
  );
}

export default VetCustomerCredit;
