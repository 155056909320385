import React, { useState, useRef } from "react";
import LockClockIcon from "@mui/icons-material/LockClock";
import loginService from "../../../services/login.service";
import { useAuth } from "../../../Context/AuthContext";

const VerifyOTP = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpError, setOtpError] = useState("");
  const [serverError, setServerError] = useState("");
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));
  const { employee } = useAuth();

  // Retrieve employeeId from localStorage
  const employeeId = JSON.parse(
    localStorage.getItem("employeeID")
  )?.employee_id;

  const handleInputChange = (index, value) => {
    setOtpError(""); // Clear previous error

    // Update the OTP state
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus on the next input if a digit is entered
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const enteredOtp = otp.join("");

    if (!enteredOtp) {
      setOtpError("Please enter the OTP");
      return;
    }

    try {
      // Call the service to verify OTP
      const response = await loginService.verifyOTP(employeeId, enteredOtp);

      if (response.status === "success") {
        if (response.data.employee_token) {
          // console.log(response.data);
          localStorage.setItem("employee", JSON.stringify(response.data));
        }

        window.location.href = "/dashboard/employee/personal/account";

        // Remove employeeId from localStorage
        localStorage.removeItem("employeeID");
        localStorage.removeItem("authMessage");
      } else {
        // Show an error message
        setServerError(response.message);
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setServerError("An error has occurred. Please try again later.");
    }
  };

  return (
    <div className="input-field otp-container">
      <div className="otp-form-container">
        <header className="mb-4 --otp-icon-- text-center">
          <LockClockIcon className="otp-icon" />
        </header>
        <h4>Confirm Your Identity</h4>
        <p className="otp-inform">
          Verify using the one-time password sent to your email.
        </p>
        {serverError && <p className=" text-danger">{serverError}</p>}
        {otpError && <div className=" text-danger">{otpError}</div>}{" "}
        <form onSubmit={handleSubmit} className="otp-form">
          <div className="otp-input-field">
            {otp.map((digit, index) => (
              <input
                key={index}
                className={`me-2 ${otpError ? "otp-err" : ""}`}
                type="number"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                ref={inputRefs.current[index]}
                maxLength="1" // Limit the input length to 1
              />
            ))}
          </div>
          <div className="otp-btn-container">
            <button
              className="btn btn-md bg-primary text-white btn-otp mt-2"
              type="submit"
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOTP;
