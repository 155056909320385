import React, { useState, useEffect } from "react";
import PettyPaymentComponent from "../../../../components/Petty_Payments/PettyPaymentComponent";
import { useAgroPettyCashBalance } from "../../../../components/APIs/FetchAPIs";
import agroPettyCash from "../../../../../services/agro_petty_cash.service";

function PettyPayment() {
  const { agroPettyCashBalance } = useAgroPettyCashBalance();
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [Payments, SetPayments] = useState([]);
  const [TotalPayments, SetTotalPayments] = useState(0);
  const [showDates, setShowDates] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  const handleAddBalance = async (event, BalanceId, BalanceData) => {
    event.preventDefault();
    if (BalanceData.amount === "" || BalanceData.amount === 0) {
      alert("Please enter a valid amount to deposit.");
    }
    try {
      const response = await agroPettyCash.addBalance(BalanceId, BalanceData);

      if (response.status === "success") {
        setSuccessMessage("Balance Deposit successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to Deposit Balance. Please try again.");
      }
    } catch (error) {
      console.error("Error Depositing balance:", error);
      setError("Error Depositing balance. Please try again.");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await agroPettyCash.getAllPayments();

        if (response.status === "success") {
          SetPayments(response.data);
          SetTotalPayments(response.data.length);
        } else {
          setIsLoading(false);
          console.error(`Error fetching Payments: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching Payments:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const handleAddPayment = async (paymentData) => {
    try {
      const response = await agroPettyCash.addPayment(paymentData);
      if (response.status === "success") {
        return { success: true, message: response.message };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      console.error("Error handling add payment:", error.message);
      return { success: false, error: error.message };
    }
  };

  const DELETEPayment = async (PaymentID) => {
    try {
      const response = await agroPettyCash.deletePayment(PaymentID);
      if (response.status === 200) {
        const responseData = await response.json();
        SetPayments((prevData) =>
          prevData.filter((item) => item.payment_id !== PaymentID)
        );
        setSuccessMessage(responseData.message);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        throw new Error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };

  const handleEditPayment = async (event, PaymentID, updatedPaymentData) => {
    event.preventDefault();
    try {
      const response = await agroPettyCash.updatePayment(
        PaymentID,
        updatedPaymentData
      );
      if (response.status === "success") {
        setSuccessMessage("Payment updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setError("Failed to update Payment. Please try again.");
      }
    } catch (error) {
      console.error("Error editing order:", error);
      setError("Error editing order. Please try again.");
    }
  };

  return (
    <div>
      <PettyPaymentComponent
        balanceData={agroPettyCashBalance}
        handleAddBalance={handleAddBalance}
        Error={Error}
        Payments={Payments}
        handleAddPayment={handleAddPayment}
        setSuccessMessage={setSuccessMessage}
        setError={setError}
        showDates={showDates}
        SuccessMessage={SuccessMessage}
        handleShowDates={handleShowDates}
        TotalPayments={TotalPayments}
        DELETEPayment={DELETEPayment}
        handleEditPayment={handleEditPayment}
        isLoading={IsLoading}
      />
    </div>
  );
}

export default PettyPayment;
