import React, { useState, useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
function AgroCustomersPieChart({ agroCustomer }) {
  const chartRef = useRef(null);

  useEffect(() => {
    // Process the agroCustomer data to get the count of each sex
    const sexData = agroCustomer.reduce((acc, customer) => {
      acc[customer.customer_sex] = (acc[customer.customer_sex] || 0) + 1;
      return acc;
    }, {});

    // Extract labels and data for Chart.js
    const labels = Object.keys(sexData);
    const data = Object.values(sexData);

    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Render the Pie Chart
    const ctx = document.getElementById("sexPieChart").getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: ["#FF6384", "#36A2EB"], // You can customize colors
          },
        ],
      },
    });
  }, [agroCustomer]);

  return <canvas id="sexPieChart"></canvas>;
}

export default AgroCustomersPieChart;
