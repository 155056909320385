import React, { useState, useEffect } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import SearchIcon from "@mui/icons-material/Search";
import agroShowroomService from "../../../../../services/agro_sold_product.service";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../../../Context/AuthContext";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";

function SaleProduct() {
  const { customerId } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [serverError, setServerError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { employee } = useAuth();
  const { agroproducts, APIError } = useAgroShowroomProducts();

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSelectProduct = (productId) => {
    // Add the selected product to the list of selected products
    const selectedProduct = agroproducts.find(
      (product) => product.product_id === productId
    );
    setSelectedProducts([...selectedProducts, selectedProduct]);
  };

  // console.log(selectedProducts);
  const handleSellProducts = async () => {
    try {
      // Map selected products to send only the specified properties
      const productsToSend = selectedProducts.map((product) => ({
        product_id: product.product_id,
        product_name: product.product_name,
        product_type: product.product_type,
        product_unit: product.product_unit,
        product_quantity: product.saleQuantity,
        product_unit_price: product.product_unit_price,
        branch: employee?.employee_branch,
        customer_id: customerId,
      }));

      // Perform sale operation for all selected products
      const response = await agroShowroomService.sellAgroProduct(
        productsToSend
      );

      // Check if all products were sold successfully
      if (response.status === "true") {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      } else {
        setServerError(response.error);
      }
    } catch (error) {
      setServerError(`Error: ${error}`);
    }
  };

  const handleRemoveProduct = (indexToRemove) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleCancelSearch = () => {
    setSearchInput("");
  };
  const filteredProducts = agroproducts
    ? agroproducts
        .filter((product) =>
          [
            "product_id",
            "product_name",
            "product_type",
            "product_unit",
            "product_unit_price",
          ].some((field) =>
            String(product[field])
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
        )
        .slice(0, 5)
    : [];

  return (
    <Dashboard>
      <div>
        {serverError && <div className="alert alert-danger">{serverError}</div>}
        {APIError && <div className="alert alert-danger">{APIError}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className="tab-header text-white text-start">
          <div className="text-start w-50 mt-4 text-white">
            <label>
              <SearchIcon className="me-2" />
              Search Product
            </label>
            <input
              className="form-control form-control-lg mb-3 w-50 p-3 mt-1"
              type="search"
              placeholder="Search Product"
              value={searchInput}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="col-sm-12 col-xl-12">
          {searchInput && filteredProducts.length > 0 ? (
            <>
              <div className="bgcustom rounded h-100 p-4">
                <div className="d-flex justify-content-between align-items-center ">
                  <h4 className="mb-4 text-start text-white">
                    Available Products
                  </h4>
                  <Link
                    onClick={handleCancelSearch}
                    className="mb-4 badge rounded-pill bg-warning text-black   text-decoration-none "
                  >
                    Cancel
                  </Link>
                </div>

                <table className="table table-borderless table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit </th>
                      <th scope="col">Product Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.map((product) => (
                      <tr key={product.product_id}>
                        <th scope="row">{product.product_id}</th>
                        <td>{product.product_name}</td>
                        <td>{product.product_type}</td>
                        <td>{product.product_unit}</td>
                        <td>{product.product_quantity}</td>
                        <td>{product.product_unit_price}</td>
                        <td>
                          <button
                            className={`btn btn-md btn-primary ${
                              selectedProducts.some(
                                (selectedProduct) =>
                                  selectedProduct.product_id ===
                                  product.product_id
                              )
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectProduct(product.product_id)
                            }
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="col-sm-12 col-xl-12 text-white">
              {searchInput && (
                <div className="alert custom-alert-warning">
                  <h6>Your search returned no matches.</h6>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="col-sm-12 col-xl-12">
          <div
            className={`  ${
              selectedProducts.length > 0 ? "bsecondary" : "bsecondaryns"
            } rounded h-100 p-4 mt-3 mb-5`}
          >
            <div className="d-flex justify-content-between">
              <h4 className="mb-4 text-start text-white">
                {" "}
                {selectedProducts.length > 0
                  ? "Selected Product(s)"
                  : "Please Select Product"}
              </h4>
              <button
                type="button"
                onClick={handleSellProducts}
                className="btn btn-lg btn-success mb-2"
                disabled={
                  !selectedProducts.every((product) => product.saleQuantity) ||
                  !selectedProducts.length > 0
                }
              >
                Sell The Product(s)
              </button>
            </div>
            {selectedProducts.map((selectedProduct, index) => (
              <table
                key={index}
                className="table table-borderless table-dark text-start border-1"
              >
                <thead>
                  <tr>
                    <th scope="col">Product ID</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Product Unit</th>
                    <th scope="col">Enter Product QTY</th>
                    <th>
                      <Link
                        onClick={() => handleRemoveProduct(index)}
                        className="badge bg-danger text-decoration-none"
                      >
                        Remove item {index + 1}
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>
                      <input
                        type="text"
                        className="form-control w-50 disabledInput"
                        value={selectedProduct.product_id}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control w-50 disabledInput"
                        value={selectedProduct.product_name}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control w-50 disabledInput"
                        value={selectedProduct.product_type}
                        disabled
                      />
                    </td>
                    <td>
                      <div className="input-group mb-3 w-75">
                        <span className="input-group-text">ETB</span>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={selectedProduct.product_unit_price}
                          disabled
                        />
                      </div>
                    </td>
                    <td>
                      <div className="input-group mb-3 w-75">
                        <span className="input-group-text">QTY</span>
                        <input
                          type="text"
                          className="form-control w-50"
                          value={selectedProduct.saleQuantity}
                          onChange={(e) => {
                            const updatedProducts = [...selectedProducts];
                            updatedProducts[index].saleQuantity =
                              e.target.value;
                            setSelectedProducts(updatedProducts);
                          }}
                          required
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default SaleProduct;
