import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import ET from "../../../assets/images/ET-flag.png";
// import { useReminders } from "../APIs/EmployeeAPI";
import EmployeeProfileView from "../upload/EmployeeProfileView";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { useAuth } from "../../../Context/AuthContext";
import loginService from "../../../services/login.service";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DailyQuote from "../Widgets/DailyQuote";
import { Offline, Online } from "react-detect-offline";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import NoInternet from "../Statuses/NoInternet";
import StatusPill from "../ContributionGraph/StatusPill";
import Employeereportmessage from "../Messages/Employeereportmessage";
import SurveyChecker from "../../pages/Gen/Survey/SurveyChecker";

function Dashboard({ children }) {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [ProfileDropdown, setProfileDropdown] = useState(false);
  const { setIsLogged, employee, isAdmin } = useAuth();
  // const { reminders } = useReminders();
  const storedVisibility = localStorage.getItem("sidebarVisible");

  const NotAv = () => {
    alert(
      "Apologies, the service is currently unavailable. We're working hard to bring you an improved experience with version 2.1. Please check back soon!"
    );
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.log(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.log(
            `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
          );
        });
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === ",") {
        setSidebarVisible(true);
      } else if (event.ctrlKey && event.key === ".") {
        setSidebarVisible(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = days[currentDate.getDay()];
  const month = months[currentDate.getMonth()];
  const date = currentDate.getDate();

  const formattedDate = `${day} ${month} ${date}`;

  const handleDropdownprofile = () => {
    setProfileDropdown(!ProfileDropdown);
  };

  const toggleSidebar = () => {
    const newVisibility = !isSidebarVisible;
    setSidebarVisible(newVisibility);
    localStorage.setItem("sidebarVisible", JSON.stringify(newVisibility));
  };
  const goBack = () => {
    window.history.back();
  };
  const goForward = () => {
    window.history.forward();
  };
  const logOut = () => {
    // Display a confirmation dialog
    const confirmLogout = window.confirm(
      "Are you sure you want to log out? You may need to enter the OTP code again to log in."
    );

    // Check the user's response
    if (confirmLogout) {
      // Call the logout function from the login service
      loginService.logOut();
      // Set the isLogged state to false
      setIsLogged(false);
      window.location.href = "/";
    }
  };
  // const EmployeeID = employee?.employee_id;

  return (
    <div className="container-fluid position-relative d-flex p-0">
      {/* Content Start */}
      <div
        className={`content content__ ${
          !isSidebarVisible || storedVisibility !== "true"
            ? "open_ content_ "
            : ""
        }`}
      >
        {/* Navbar Start */}
        <nav className="navbar bg-dark  navbar-expand navbar-dark sticky-top px-4 py-0">
          {/* <Link to="/" className="navbar-brand d-flex d-lg-none me-4">
            <h2 className="bg-dark  mb-0">
              <i className="fa fa-user-edit">Dashboard</i>
            </h2>
          </Link> */}
          <div onClick={goBack} className="text-white dsmnone">
            <div className="">
              <ArrowLeftIcon className="w-100" />
            </div>
          </div>
          <Link
            onClick={toggleSidebar}
            className="sidebar-toggler flex-shrink-0"
          >
            <MenuIcon className="menu-icon" />
          </Link>
          <div onClick={goForward} className="text-white me-2 dsmnone">
            <div className="">
              <ArrowRightIcon className="w-100" />
            </div>
          </div>
          <Link
            className="nav-link align-items-center dsmnone"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <FullscreenExitIcon className="materialuiicon text-white fs-2" />
            ) : (
              <FullscreenIcon className="materialuiicon text-white fs-2" />
            )}
          </Link>
          <div className="d-none d-md-flex ms-3 text-white">
            <input
              className="form-control bg-dark DashboardSearch text-info "
              type="search"
              id="DashboardSearchinput"
              placeholder="Search"
            />
          </div>
          <StatusPill />
          <div className="navbar-nav align-items-center  ms-auto ">
            <DailyQuote className="text-gray" />

            {/* <div className="nav-item dropdown ">
              <button className="nav-link align-items-center">
                <img src={ET} width="25" />
              </button>
            </div>{" "} */}
            <div className="nav-item dropdown ">
              <button onClick={NotAv} className="nav-link align-items-center">
                <MailOutlinedIcon className="msg-notif-icon  rounded-circle" />

                <span className="d-none d-lg-inline-flex m-1 text-light-gray">
                  Message
                </span>
              </button>
            </div>
            <div className="nav-item dropdown align-items-center">
              <button onClick={NotAv} className="nav-link ">
                <NotificationsNoneOutlinedIcon className="msg-notif-icon rounded-circle" />

                <span className="d-none d-lg-inline-flex m-1 text-light-gray">
                  Notification
                </span>
              </button>
            </div>
            <div className="nav-item dropdown "></div>
            <div className="nav-item dropdown">
              <Link
                className="nav-link d-flex align-items-center"
                onClick={handleDropdownprofile}
              >
                <EmployeeProfileView
                  nav={true}
                  picstyle={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                  }}
                />
                
                
                {/* <AccountCircleIcon className="matrialuiicon- text-warning rounded-circle " /> */}
                <span className="d-none d-lg-inline-flex m-1 ms-2">
                  {employee?.employee_first_name} {employee?.employee_last_name}
                </span>
                
              </Link>
              <div
                className={`dropdown-menu ${
                  ProfileDropdown ? "show" : ""
                } bg-dark border-0 `}
              >
                <Link
                  to="/dashboard/employee/personal/account"
                  className="dropdown-item"
                >
                  My Profile
                </Link>
                <Link onClick={NotAv} className="dropdown-item">
                  Settings
                </Link>
                <Link onClick={logOut} className="dropdown-item">
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {/* Navbar End */}
        {employee?.employee_role !== 3 && <Employeereportmessage />}

        <SurveyChecker />
        <div className="container-fluid pt-4 px-4">
          <div className="row bg-dark mobile-v-stl rounded dashboard-main justify-content-center mx-0">
            <div className="col-md-12 text-center">
              {/* <Online> {children}</Online> */}
              {/* <Offline>
                <NoInternet />
              </Offline> */}
              {/* Tempo */}
              {children}
            </div>
          </div>
        </div>
        {/* Footer Start */}
        <div className="container-fluid pt-4 px-4 border-light  ">
          <div className="bg-dark rounded-top p-4 text-secondary ">
            <div className="row d-sm-block">
              <div className="d-md-flex  d-sm-block align-items-center ">
                <div className="col-12 col-sm-6 text-center text-sm-start">
                  &copy;{" "}
                  <Link to="/" className="text-success">
                    Buee Agricultural One Stop Shop Center
                  </Link>
                  , All Right Reserved.
                </div>
                <div className="text-white font-monospace bg-danger text-center rounded-1 align-items-center ">
                  <h6 className="pt-1 ms-1 me-1">{formattedDate}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
      </div>
      {/* Content End */}

      {/* Back to Top */}
      <Link className="btn back-to-top">
        <ArrowCircleUpIcon className="upicon" />
      </Link>
    </div>
  );
}

export default Dashboard;
