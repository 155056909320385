import React from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import "./Header.css";

function MobileMenu({
  isMobileMenuOpen,
  toggleMobileMenu,
  employee,
  isLogged,
}) {
  return (
    <div
      className={`root-mobile-menu root-navbar-target ${
        isMobileMenuOpen ? "open" : ""
      }`}
    >
      <div className="root-mobile-menu-header text-end">
        <div className="root-mobile-menu-close mt-3" onClick={toggleMobileMenu}>
          <span className="icon-close2 js-menu-toggle text-black">
            <ClearIcon className="text-black nav-icon me-3" />
          </span>
        </div>
      </div>
      <div className="root-mobile-menu-body">
        <ul>
          <li className="_lists_">
            <Link
              to="Home"
              offset={-100}
              smooth={true}
              duration={500}
              activeClass="active"
              spy={true}
              className="home-nav-link text-left"
            >
              Home
            </Link>
          </li>
          <li className="_lists_">
            <Link
              to="about"
              smooth={true}
              duration={500}
              offset={-120}
              activeClass="active"
              spy={true}
              className="home-nav-link text-left"
            >
              About Us
            </Link>
          </li>
          <li className="_lists_">
            <Link
              to="services"
              smooth={true}
              duration={500}
              offset={-130}
              activeClass="active"
              spy={true}
              className="home-nav-link text-left"
            >
              Our Services
            </Link>
          </li>
          <li className="_lists_">
            <Link
              to="projects"
              smooth={true}
              duration={500}
              offset={-120}
              activeClass="active"
              spy={true}
              className="home-nav-link text-left"
            >
              Projects
            </Link>
          </li>
          <li className="_lists_">
            <Link
              to="partners"
              smooth={true}
              activeClass="active"
              spy={true}
              duration={500}
              offset={-100}
              className="home-nav-link text-left"
            >
              Our Partners
            </Link>
          </li>
          <li className="_lists_">
            <Link
              to="contact"
              smooth={true}
              duration={500}
              offset={-120}
              activeClass="active"
              spy={true}
              className="home-nav-link text-left"
            >
              Contact
            </Link>
          </li>
          <li className="login_lists_">
            {isLogged ? (
              <RouterLink
                to="/dashboard/employee/personal/account"
                className="home-nav-link text-left"
              >
                <button className="btn btn-sm btn-outline-light ">
                  <span>My Dashboard</span>
                </button>
              </RouterLink>
            ) : (
              <RouterLink to="/login" className="home-nav-link text-left">
                <button className="btn-grad1">
                  <span>Login</span>
                </button>
              </RouterLink>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;
