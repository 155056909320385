// Import from the env
const api_url = process.env.REACT_APP_API_URL;

const getAgroChemicalSalesReport = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/agro/sales/reports`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro showroom products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all agro showroom products:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const AgroChemicalSalesReportService = {
  getAgroChemicalSalesReport,
};

export default AgroChemicalSalesReportService;
