import React from "react";
import { useOthersEmployeesActivity } from "../APIs/EmployeeAPI";
import ContributionGraph from "./ContributionGraph";

function OtherEmployeeContribution() {
  const { otheremployeeActivity } = useOthersEmployeesActivity();
  return (
    <div className="">
      <div className=" align-items-center ">
        {otheremployeeActivity.map((item) => (
          <div key={item.activity.activity_Id}>
            {/* Render employee data */}
            <div className="d-flex  align-items-center">
              {/* Render profile picture */}
              {item.profilePicture && (
                <img
                  src={`data:image/jpeg;base64,${getBase64String(
                    item.profilePicture.profilePicture.data
                  )}`}
                  alt="Employee"
                  className="ms-3"
                  style={{
                    alignItems: "center",
                    maxWidth: "95px",
                    maxHeight: "95px",
                    borderRadius: "50%",
                  }}
                />
              )}
              <div className="d-block ms-3">
                <h5>{item.activity.employee_name}</h5>
                <p>Last Status: {item.activity.status}</p>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
      {/* Pass activity data to ContributionGraph component */}
      <ContributionGraph
        data={otheremployeeActivity.map((item) => ({
          activity_Id: item.activity.activity_Id,
          employee_name: item.activity.employee_name,
          Total: item.activity.Total,
          activity_type: item.activity.activity_type,
          status: item.activity.status,
          created_at: item.activity.created_at,
          // Add more fields as needed
        }))}
      />
    </div>
  );
}

function getBase64String(bufferData) {
  const byteArray = new Uint8Array(bufferData);
  const byteString = String.fromCharCode.apply(null, byteArray);
  return btoa(byteString);
}

export default OtherEmployeeContribution;
