import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 26,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
    fontStyle: "bold",
  },
});

const BillTo = ({ Info, service }) => (
  <View style={styles.headerContainer}>
    {service !== "addedproducts" && (
      <>
        <Text style={styles.billTo}>Delivered To:</Text>
        <Text>{Info.ToBranch} AOSS CENTER</Text>
      </>
    )}

    <Text>ETH, Buee Admin, Main Branch</Text>
    <Text>+251-922225363</Text>
    <Text>support@aosscenter.com</Text>
  </View>
);

export default BillTo;
