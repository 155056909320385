import React, { useEffect, useState } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import BranchService from "../../../../services/branch.service";

function Branch() {
  const [successMessage, setSuccessMessage] = useState("");
  const [branchs, setBranchs] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [formData, setFormData] = useState({
    branch_name: "",
    manager_name: "",
    country: "",
    city: "",
    region_or_state: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        setApiError("No data returned from the server");
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await BranchService.AddBranch(formData);
      setBranchs((prevBranchs) => [...prevBranchs, response.data]);
      setFormData({
        branch_name: "",
        manager_name: "",
        country: "",
        city: "",
        region_or_state: "",
      });
      setSuccessMessage("Branch added successfully");
    } catch (error) {
      console.error("Error adding branch:", error);
     
    }
  };

  const handleDelete = async (branchId) => {
    if (window.confirm("Are you sure you want to delete this branch?")) {
      try {
        const response = await BranchService.deleteBranch(branchId);
        if (response.ok) {
          setBranchs((prevBranchs) =>
            prevBranchs.filter((branch) => branch.branch_id !== branchId)
          );
          setSuccessMessage("Branch deleted successfully");
        } else {
          console.error(`Error deleting branch: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting branch:", error);
      }
    }
  };

  return (
    <Dashboard>
      <div className="bg-dark text-secondary text-start">
        {successMessage && (
          <div className="alert alert-success text-dark" role="alert">
            {successMessage}
          </div>
        )}
        {apiError && (
          <div className="alert alert-danger text-dark" role="alert">
            {apiError}
          </div>
        )}
        <div className="rounded p-4 col-sm-12 col-xl-7">
          <h6 className="mb-4">Add New Branch</h6>
          <form onSubmit={handleSubmit}>
            {/* Branch Name */}
            <div className="row mb-3">
              <label
                htmlFor="inputBranchName"
                className="col-sm-2 col-form-label"
              >
                Branch Name
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  id="inputBranchName"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            {/* Manager Name */}
            <div className="row mb-3">
              <label
                htmlFor="inputManagerName"
                className="col-sm-2 col-form-label"
              >
                Manager Name
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  id="inputManagerName"
                  name="manager_name"
                  value={formData.manager_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            {/* Country */}
            <div className="row mb-3">
              <label htmlFor="inputCountry" className="col-sm-2 col-form-label">
                Country
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  id="inputCountry"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            {/* City */}
            <div className="row mb-3">
              <label htmlFor="inputCity" className="col-sm-2 col-form-label">
                Town/City
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  id="inputCity"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            {/* Region */}
            <div className="row mb-3">
              <label htmlFor="inputRegion" className="col-sm-2 col-form-label">
                Region/State
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  id="inputRegion"
                  name="region_or_state"
                  value={formData.region_or_state}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="row mb-3">
              <div className="col-sm-2"></div>
              <div className="col-sm-10">
                <button type="submit" className="btn btn-primary">
                  Add New Branch
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* Table of Branches */}
        <div className="table-responsive">
          <table className="table table-dark">
            <thead>
              <tr>
                <th scope="col">Branch ID</th>
                <th scope="col">Branch Name</th>
                <th scope="col">Manager Name</th>
                <th scope="col">Country</th>
                <th scope="col">Town/City</th>
                <th scope="col">Region/State</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {branchs.map((branch) => (
                <tr className="branchss-table-tr" key={branch.branch_id}>
                  <td>{branch.branch_id}</td>
                  <td>{branch.branch_name}</td>
                  <td>{branch.manager_name}</td>
                  <td>{branch.country}</td>
                  <td>{branch.city}</td>
                  <td>{branch.region_or_state}</td>
                  <td>{branch.created_at}</td>
                  <td>
                    <button
                      className="btn btn-md btn-danger"
                      onClick={() => handleDelete(branch.branch_id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Dashboard>
  );
}

export default Branch;
