const api_url = process.env.REACT_APP_API_URL;

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

const getEmployeeStatus = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/status/${EmployeeID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get employee Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting employee Status:", error.message);
    throw error;
  }
};
const getEmployeeByID = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/${EmployeeID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get employee by id: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting employee by id:", error.message);
    throw error;
  }
};

const getAllOnlineEmployees = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(
    `${api_url}/api/employee/online`,
    requestOptions
  );

  return response.json();
};

// Export all the functions
const employeeStatusService = {
  getEmployeeStatus,
  getAllOnlineEmployees,
  getEmployeeByID,
};

export default employeeStatusService;
