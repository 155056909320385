import React from "react";
import { useEmployeeActivity } from "../APIs/EmployeeAPI";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
function StatusPill() {
  const { employeeActivity } = useEmployeeActivity();
  // Get the last row of data
  const lastRow = employeeActivity[employeeActivity.length - 1];
  // Extract status from the last row
  const status = lastRow ? lastRow.status : ""; // Assuming 'status' is the key for status
  // Define color mapping
  const colorMapping = {
    Poor: "#ff0303",
    Fair: "#FFFF00",
    Remarkable: "#1d1dd1",
    Good: "#00FF00",
  };
  // Get the color based on the status from the color mapping
  const StatusColor = colorMapping[status];
  return (
    <div className="stpill">
      <span
        style={{
          backgroundColor: `${StatusColor}`,
          color: `${status === "Fair" ? "black" : "white"}`,
        }}
        className={`badge badge-pill  ms-5`}
      >
        {status === "Good" ? (
          <>
            <ThumbUpAltIcon /> {status}
          </>
        ) : null}
        {status === "Poor" ? (
          <>
            <MoodBadIcon /> {status}
          </>
        ) : null}
        {status === "Fair" ? <> {status}</> : null}
        {status === "Remarkable" ? <> ✔️ {status}</> : null}
      </span>
    </div>
  );
}

export default StatusPill;
