import React, { useState, useEffect } from "react";
import { Navigate } from "react-router";
import getAuth from "../../../util/auth.header";
import Loader from "../Widgets/Loader";
const PrivateAuthRoute = ({ roles, children }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const loggedInEmployee = await getAuth();
      if (loggedInEmployee.employee_token) {
        setIsLogged(true);
        if (
          roles &&
          roles.length > 0 &&
          roles.includes(loggedInEmployee.employee_role)
        ) {
          setIsAuthorized(true);
        }
      }
      setIsChecked(true);
    };
    // Call checkAuth immediately
    checkAuth();
    // Check auth status every second
    const intervalId = setInterval(checkAuth, 1000);
    return () => clearInterval(intervalId);
  }, [roles]);

  if (!isChecked) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!isLogged) {
    // If not logged in or not authorized, redirect to login page
    return <Navigate to="/login" />;
  } else if (isLogged && !isAuthorized) {
    return <Navigate to="/unauthorized" />;
  }

  // If authenticated and authorized, render children
  return children;
};

export default PrivateAuthRoute;
