// Import from the env
const api_url = process.env.REACT_APP_API_URL;

const uploadEmployeeProfilePicture = async (formData, employeeId) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "x-access-token": getAuthToken(),
    },
    body: formData,
  };
  try {
    const response = await fetch(
      `${api_url}/api/employee/profile/update/${employeeId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to upload profile picture");
    }
    return response.json();
  } catch (error) {
    console.error("Error uploading profile picture:", error.message);
    throw error;
  }
};
const getProfilePictureByEmployeeId = async (employeeId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/profile/${employeeId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to fetching profile: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Failed to fetch profile:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export the function
const employeeProfileService = {
  uploadEmployeeProfilePicture,
  getProfilePictureByEmployeeId,
};

export default employeeProfileService;
