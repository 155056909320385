import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FolderIcon from "@mui/icons-material/Folder";
import Weeklyavalable from "./weeklyavalable";

function WeeklyReport({ SalesReport }) {
 
  const [weeklyReports, setWeeklyReports] = useState([]);
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    const filteredData = SalesReport.reduce((acc, report) => {
      const date = new Date(report.date);
      const weekStartDate = new Date(date);
      weekStartDate.setDate(date.getDate() - date.getDay()); // Get start of week
      const weekEndDate = new Date(weekStartDate);
      weekEndDate.setDate(weekStartDate.getDate() + 6); // Get end of week

      const weekKey = `${weekStartDate.toISOString()}_${weekEndDate.toISOString()}`;

      if (!acc[weekKey]) {
        acc[weekKey] = [];
      }

      acc[weekKey].push(report);

      return acc;
    }, {});

    // Convert object to array for easy rendering
    const weeklyReportsArray = Object.entries(filteredData).map(
      ([weekKey, reports]) => ({
        weekKey,
        reports,
      })
    );

    setWeeklyReports(weeklyReportsArray);
  }, [SalesReport]);

  const exportToExcel = (reports, weekKey) => {
    const ws = XLSX.utils.json_to_sheet(reports);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Weekly_Report_${weekKey}.xlsx`);
  };
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <>
      {/* <h1 className="text-bg-danger  rounded-3">Weekly Report</h1> */}
      {/* <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          {weeklyReports.map(({ weekKey, reports }) => (
            <div key={weekKey} className="col-sm-12 mb-4 mt-3">
              <div className="h-100 bg-dark border border-light text-white rounded p-4">
                <div className="d-flex align-items-center border-bottom border-gray p justify-content-between mb-2">
                  <p className="mb-0">
                    Week of{" "}
                    {new Date(weekKey.split("_")[0]).toLocaleDateString()} -{" "}
                    {new Date(weekKey.split("_")[1]).toLocaleDateString()}
                  </p>
                  <button
                    className="btn btn-danger"
                    onClick={() => exportToExcel(reports, weekKey)}
                  >
                    <CloudDownloadIcon /> Download Excel
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="col-sm-12 col-md-6 col-xl-6 mb-4 mt-3">
        <div className="h-100 bg-dark border border-light text-white  rounded p-4">
          <div className="d-flex align-items-center border-bottom border-gray p justify-content-between pb-4">
            <p className="mb-0">Weekly Reports </p>

            <Link className="text-danger " onClick={toggleShowAll}>
              {" "}
              {showAll ? "Show Less" : "Show All"}
            </Link>
          </div>
          <Weeklyavalable Report={SalesReport} />
          <table className="table table-dark table-borderless">
            <tbody>
              {weeklyReports
                .slice(0, showAll ? weeklyReports.length : 4)
                .map(({ weekKey, reports }) => (
                  <tr key={weekKey}>
                    <td>
                      <div className="d-flex align-items-center">
                        <FolderIcon />
                        <p className="mb-0">
                          Week of{" "}
                          {new Date(weekKey.split("_")[0]).toLocaleDateString()}{" "}
                          -{" "}
                          {new Date(weekKey.split("_")[1]).toLocaleDateString()}
                        </p>
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => exportToExcel(reports, weekKey)}
                      >
                        <CloudDownloadIcon /> Download Excel
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default WeeklyReport;
