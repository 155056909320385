import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../Context/AuthContext";
import AddCustomer from "../../../../components/Customer/AddCustomer";
import VetcustomerService from "../../../../../services/vet_customer.service";

function VetAddCustomer() {
  const [serverError, setServerError] = useState("");
  const { employee } = useAuth();
  const EmployeeBranch = employee?.employee_branch;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customer, setCustomer] = useState([]);

  const [formData, setFormData] = useState({
    customer_first_name: "",
    customer_last_name: "",
    customer_sex: "",
    customer_address: "",
    customer_phone: "",
    branch: "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      branch: EmployeeBranch || "",
    }));
  }, [EmployeeBranch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const newAgroCustomer = VetcustomerService.createVetCustomer(formData);
    newAgroCustomer
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setServerError(data.error);
        } else {
          setServerError("");
          setTimeout(() => {
            window.location.href = "/dashboard/showroom/vet/customer";
          }, 1000);
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setServerError(resMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setServerError(true);
        const response = await VetcustomerService.getAllCustomers();

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.length !== 0) {
            setCustomer(data.data);
          }
        } else {
          setServerError(false);
          console.error(`Error fetching customers: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setServerError(false);
      }
    };
    fetchData();
  }, []);
  const addressOptions = customer.map((customer) => ({
    value: customer.customer_address,
    label: customer.customer_address,
  }));
  return (
    <AddCustomer
      serverError={serverError}
      setFormData={setFormData}
      formData={formData}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      customerData={customer}
      addressOptions={addressOptions}
    />
  );
}

export default VetAddCustomer;
