const api_url = process.env.REACT_APP_API_URL;

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Function to add help request
const addHelpRequest = async (helpData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(helpData),
  };

  try {
    const response = await fetch(`${api_url}/api/help`, requestOptions);
    if (!response.ok) {
      throw new Error(`Failed to add help request: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error adding help request:", error.message);
    throw error;
  }
};

// Function to get help by employee ID
const getHelpByEmployeeId = async (employeeId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/help/${employeeId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get help by employee ID: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting help by employee ID:", error.message);
    throw error;
  }
};

// Function to get all help requests
const getAllHelpRequests = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(`${api_url}/api/help`, requestOptions);
    if (!response.ok) {
      throw new Error(`Failed to get all help requests: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting all help requests:", error.message);
    throw error;
  }
};

// Function to delete help request
const deleteHelpRequest = async (helpId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/help/${helpId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to delete help request: ${response.status}`);
    }
    return response; // No need to return JSON for delete request
  } catch (error) {
    console.error("Error deleting help request:", error.message);
    throw error;
  }
};

// Export all the functions
const helpService = {
  addHelpRequest,
  getHelpByEmployeeId,
  getAllHelpRequests,
  deleteHelpRequest,
};

export default helpService;
