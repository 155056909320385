import { useState, useEffect } from "react";
import agroShowroomService from "../../../services/agro_showroom_product.service";
import agroAvailableStoreService from "../../../services/agro_store_available_products.service";
import vetShowroomService from "../../../services/vet_showroom_product.service";
import vetSoldProductsService from "../../../services/vet_sold_product.service";
import agroSoldProductsService from "../../../services/agro_sold_product.service";
import AgroChemicalSalesReportService from "../../../services/agro_chemical_sales_report.service";
import VetReportService from "../../../services/vet_sales_report.service";
import agroShowroomShowroomCloneService from "../../../services/agro_showroom_clone_product.service";
import vetShowroomShowroomCloneService from "../../../services/vet_showroom_clone_product.service";
import agroOutedProductsService from "../../../services/agro_store_outed_products.service";
import vetPettyCash from "../../../services/vet_petty_cash.service";
import agroPettyCash from "../../../services/agro_petty_cash.service";
import agroStoreCloneService from "../../../services/agro_store_clone_products.service";
import vetStoreCloneService from "../../../services/vet_store_clone_products.service";
import agroStoreTruthTrackService from "../../../services/agro_store_truth-track.service";
import vetStoreTruthTrackService from "../../../services/vet_store_truth-track.service";
import branchService from "../../../services/branch.service";
import agroShowroomTruthTrackService from "../../../services/agro_showroom_truth-track.service";
import vetShowroomTruthTrackService from "../../../services/vet_showroom_truth-track.service";
import vetStoreService from "../../../services/vet_store_available_products.service";
import vetStoreOutedProductsService from "../../../services/vet_store_outed_products.service";

export const useAgroShowroomProducts = () => {
  const [agroproducts, setAgroShowroomProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroShowroomProducts = async () => {
    try {
      const response = await agroShowroomService.getAllAgroShowroomProducts();
      if (response.status === "success") {
        setAgroShowroomProducts(response.data);
      } else {
        setError(`Error fetching agro showroom products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching agro showroom products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroShowroomProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroShowroomProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { agroproducts, isLoading, APIError, handleFetchData };
};

export const useAgroAvailableStoreProducts = () => {
  const [agroStoreAvailableProduct, setAgroAvailableStoreProducts] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroAvailableStoreProducts = async () => {
    try {
      const response =
        await agroAvailableStoreService.getAllAgroStoreProducts();
      if (response.status === "success") {
        setAgroAvailableStoreProducts(response.data);
      } else {
        setError(
          `Error fetching agro available store products: ${response.status}`
        );
      }
    } catch (error) {
      setError("Error fetching agro available store products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAgroAvailableStoreProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroAvailableStoreProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return {
    agroStoreAvailableProduct,
    isLoading,
    APIError,
    handleFetchData,
  };
};

export const useVetShowroomProducts = () => {
  const [vetproducts, setVetShowroomProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetShowroomProducts = async () => {
    try {
      const response = await vetShowroomService.getAllVetShowroomProducts();
      if (response.status === "success") {
        setVetShowroomProducts(response.data);
      } else {
        setError(`Error fetching vet showroom products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching vet showroom products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchVetShowroomProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetShowroomProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { vetproducts, isLoading, APIError, handleFetchData };
};

export const useVetSoldProducts = () => {
  const [vetsoldProducts, setVetSoldProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetSoldProducts = async () => {
    try {
      const response = await vetSoldProductsService.getAllSoldVetProducts();
      if (response.status === "success") {
        setVetSoldProducts(response.data);
      } else {
        setError(`Error fetching vet sold products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching vet sold products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVetSoldProducts();

    return () => {
      // Cleanup function
    };
  }, []);

  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetSoldProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { vetsoldProducts, isLoading, APIError, handleFetchData };
};

export const useAgroSoldProducts = () => {
  const [agrosoldProducts, setAgroSoldProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroSoldProducts = async () => {
    try {
      const response = await agroSoldProductsService.getAllSoldAgroProducts();
      if (response.status === "success") {
        setAgroSoldProducts(response.data);
      } else {
        setError(`Error fetching agro sold products: ${response.status}`);
      }
    } catch (error) {
      setError(`Error fetching agro sold products: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroSoldProducts();

    // Cleanup function if needed
    return () => {
      // Cleanup logic if any
    };
  }, []); // Empty dependency array ensures this runs once on component mount

  const aghandleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroSoldProducts();
    } catch (error) {
      setError(`Error fetching agro sold products: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return { agrosoldProducts, isLoading, APIError, aghandleFetchData };
};

export const useAgroChemicalSalesReport = () => {
  const [agroSalesReport, setAgroChemicalSalesReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchAgroChemicalSalesReport = async () => {
      try {
        const response =
          await AgroChemicalSalesReportService.getAgroChemicalSalesReport();
        if (response.status === "success") {
          setAgroChemicalSalesReport(response.data);
        } else {
          setError(
            `Error fetching agro chemical sales report: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching agro chemical sales report:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroChemicalSalesReport();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroSalesReport, isLoading, APIError };
};

export const useVetSalesReport = () => {
  const [vetSalesReport, setVetSalesReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchVetSalesReport = async () => {
      try {
        const response = await VetReportService.getVetSalesReport();
        if (response.status === "success") {
          setVetSalesReport(response.data);
        } else {
          setError(`Error fetching vet sales report: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching vet sales report:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetSalesReport();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetSalesReport, isLoading, APIError };
};

export const useAgroShowroomCloneProducts = () => {
  const [agroShowroomCloneProducts, setAgroShowroomCloneProducts] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroShowroomCloneProducts = async () => {
    try {
      const response =
        await agroShowroomShowroomCloneService.getAllAgroShowroomCloneProducts();
      if (response.status === "success") {
        setAgroShowroomCloneProducts(response.data);
      } else {
        setError(
          `Error fetching agro showroom clone products: ${response.status}`
        );
      }
    } catch (error) {
      setError("Error fetching agro showroom clone products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroShowroomCloneProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroShowroomCloneProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { agroShowroomCloneProducts, isLoading, APIError, handleFetchData };
};

export const useVetShowroomCloneProducts = () => {
  const [vetShowroomCloneProducts, setVetShowroomCloneProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetShowroomCloneProducts = async () => {
    try {
      const response =
        await vetShowroomShowroomCloneService.getAllVetShowroomCloneProducts();
      if (response.status === "success") {
        setVetShowroomCloneProducts(response.data);
      } else {
        setError(
          `Error fetching vet showroom clone products: ${response.status}`
        );
      }
    } catch (error) {
      setError("Error fetching vet showroom clone products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchVetShowroomCloneProducts();
    return () => {
      // Cleanup function
    };
  }, []);

  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetShowroomCloneProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { vetShowroomCloneProducts, isLoading, APIError, handleFetchData };
};

export const useAgroOutedProducts = () => {
  const [agrooutedProducts, setAgroOutedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroOutedProducts = async () => {
    try {
      const response = await agroOutedProductsService.getAllOutedAgroProducts();
      if (response.status === "success") {
        setAgroOutedProducts(response.data);
      } else {
        setError(`Error fetching agro outed products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching agro outed products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroOutedProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroOutedProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { agrooutedProducts, isLoading, APIError, handleFetchData };
};

export const useVetOutedProducts = () => {
  const [vetoutedProducts, setVetOutedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetOutedProducts = async () => {
    try {
      const response =
        await vetStoreOutedProductsService.getAllOutedVetProducts();
      if (response.status === "success") {
        setVetOutedProducts(response.data);
      } else {
        setError(`Error fetching vet outed products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching vet outed products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVetOutedProducts();

    return () => {
      // Clean Up Function
    };
  }, []);

  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetOutedProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { vetoutedProducts, isLoading, APIError, handleFetchData };
};

export const useAgroPettyCash = () => {
  const [agroPettyCashData, setAgroPettyCashData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchAgroPettyCashData = async () => {
      try {
        const response = await agroPettyCash.getAllBalances();
        if (response.status === "success") {
          setAgroPettyCashData(response.data);
        } else {
          setError(`Error fetching agro petty cash data: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching agro petty cash data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroPettyCashData();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroPettyCashData, isLoading, APIError };
};

export const useVetPettyCashBalance = () => {
  const [vetPettyCashBalance, setVetPettyCashBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchVetPettyCashbalances = async () => {
      try {
        const response = await vetPettyCash.getAllBalances();
        if (response.status === "success") {
          setVetPettyCashBalance(response.data);
        } else {
          setError(
            `Error fetching vet petty cash balances: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching vet petty cash balances:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetPettyCashbalances();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetPettyCashBalance, isLoading, APIError };
};
export const useAgroPettyCashBalance = () => {
  const [agroPettyCashBalance, setAgroPettyCashBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchVetPettyCashbalances = async () => {
      try {
        const response = await agroPettyCash.getAllBalances();
        if (response.status === "success") {
          setAgroPettyCashBalance(response.data);
        } else {
          setError(
            `Error fetching agro petty cash balances: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching agro petty cash balances:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetPettyCashbalances();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroPettyCashBalance, isLoading, APIError };
};
export const useVetPettyCash = () => {
  const [vetPettyCashData, setVetPettyCashData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchVetPettyCashData = async () => {
      try {
        const response = await vetPettyCash.getAllBalances();
        if (response.status === "success") {
          setVetPettyCashData(response.data);
        } else {
          setError(`Error fetching vet petty cash data: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching vet petty cash data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetPettyCashData();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetPettyCashData, isLoading, APIError };
};

export const useAgroStoreCloneProducts = () => {
  const [agroStoreCloneProducts, setAgroStoreCloneProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchAgroStoreCloneProducts = async () => {
    try {
      const response =
        await agroStoreCloneService.getAllAgroStoreCloneProducts();
      if (response.status === "success") {
        setAgroStoreCloneProducts(response.data);
      } else {
        setError(
          `Error fetching agro store clone products: ${response.status}`
        );
      }
    } catch (error) {
      setError("Error fetching agro store clone products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroStoreCloneProducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroStoreCloneProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { agroStoreCloneProducts, isLoading, APIError, handleFetchData };
};

export const useVetStoreCloneProducts = () => {
  const [vetStoreCloneProducts, setVetStoreCloneProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetStoreCloneProducts = async () => {
    try {
      const response = await vetStoreCloneService.getAllVetStoreCloneProducts();
      if (response.status === "success") {
        setVetStoreCloneProducts(response.data);
      } else {
        setError(`Error fetching vet store clone products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching vet store clone products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVetStoreCloneProducts();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetStoreCloneProducts, isLoading, APIError };
};

export const useAgroStoreTruthTrack = () => {
  const [agroStoreTruthTrack, setAgroStoreTruthTrack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  useEffect(() => {
    const fetchAgroStoreTruthTrack = async () => {
      try {
        const response = await agroStoreTruthTrackService.getTrackedTruthData(
          branch
        );
        if (response.status === "success") {
          setAgroStoreTruthTrack(response.data);
        } else {
          setError(`Error fetching agro store truth track: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching agro store truth track:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroStoreTruthTrack();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroStoreTruthTrack, isLoading, APIError };
};

export const useVetStoreTruthTrack = () => {
  const [vetStoreTruthTrack, setVetStoreTruthTrack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  useEffect(() => {
    const fetchVetStoreTruthTrack = async () => {
      try {
        const response = await vetStoreTruthTrackService.getTrackedTruthData(
          branch
        );
        if (response.status === "success") {
          setVetStoreTruthTrack(response.data);
        } else {
          setError(`Error fetching vet store truth track: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching vet store truth track:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetStoreTruthTrack();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetStoreTruthTrack, isLoading, APIError };
};

export const useBranchs = () => {
  const [branchs, setBranchs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  useEffect(() => {
    const fetchBranchs = async () => {
      try {
        const response = await branchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          setError(`Error fetching branchs: ${response.status}`);
        }
      } catch (error) {
        setError("Error fetching branchs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranchs();

    return () => {
      // Cleanup function
    };
  }, []);

  return { branchs, isLoading, APIError };
};

export const useAgroShowroomTruthTrack = () => {
  const [agroShowroomTruthTrack, setAgroShowroomTruthTrack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  useEffect(() => {
    const fetchAgroShowroomTruthTrack = async () => {
      try {
        const response =
          await agroShowroomTruthTrackService.getTrackedTruthData(branch);
        if (response.status === "success") {
          setAgroShowroomTruthTrack(response.data);
        } else {
          setError(
            `Error fetching agro showroom truth track: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching agro showroom truth track:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroShowroomTruthTrack();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroShowroomTruthTrack, isLoading, APIError };
};

export const useVetShowroomTruthTrack = () => {
  const [vetShowroomTruthTrack, setVetShowroomTruthTrack] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");
  useEffect(() => {
    const fetchVetShowroomTruthTrack = async () => {
      try {
        const response = await vetShowroomTruthTrackService.getTrackedTruthData(
          branch
        );
        if (response.status === "success") {
          setVetShowroomTruthTrack(response.data);
        } else {
          setError(
            `Error fetching vet showroom truth track: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching vet showroom truth track:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetShowroomTruthTrack();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetShowroomTruthTrack, isLoading, APIError };
};

export const useVetStoreAvailableProducts = () => {
  const [vetStoreAvailableProduct, setVetStoreAvailableProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);

  const fetchVetStoreAvailableProducts = async () => {
    try {
      const response = await vetStoreService.getAllVetStoreProducts();
      if (response.status === "success") {
        setVetStoreAvailableProducts(response.data);
      } else {
        setError(
          `Error fetching vet store available products: ${response.status}`
        );
      }
    } catch (error) {
      setError("Error fetching vet store available products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVetStoreAvailableProducts();

    return () => {
      // Cleanup function
    };
  }, []);

  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetStoreAvailableProducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { vetStoreAvailableProduct, isLoading, APIError, handleFetchData };
};

export const useVetStoreInitialBalance = () => {
  const [vetStoreInitialBalance, setvetStoreInitialBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");
  useEffect(() => {
    const fetchVetStoreInitialBalance = async () => {
      try {
        const response = await vetStoreTruthTrackService.getInitialBalance(
          branch
        );
        if (response.status === "success") {
          setvetStoreInitialBalance(response.data);
        } else {
          setError(
            `Error fetching vet store initial balance: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching vet store initial balance:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetStoreInitialBalance();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetStoreInitialBalance, isLoading, APIError };
};

export const useVetShowromInitialBalance = () => {
  const [vetShowroomInitialBalance, setvetShowroomInitialBalance] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");
  useEffect(() => {
    const fetchVetshowromInitialBalance = async () => {
      try {
        const response = await vetShowroomTruthTrackService.getInitialBalance(
          branch
        );
        if (response.status === "success") {
          setvetShowroomInitialBalance(response.data);
        } else {
          setError(
            `Error fetching vet showrom initial balance: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching vet showrom initial balance:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetshowromInitialBalance();

    return () => {
      // Cleanup function
    };
  }, []);

  return { vetShowroomInitialBalance, isLoading, APIError };
};

export const useAgroStoreInitialBalance = () => {
  const [agroStoreInitialBalance, setagroStoreInitialBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  useEffect(() => {
    const fetchAgroStoreInitialBalance = async () => {
      try {
        const response = await agroStoreTruthTrackService.getInitialBalance(
          branch
        );
        if (response.status === "success") {
          setagroStoreInitialBalance(response.data);
        } else {
          setError(
            `Error fetching agro store initial balance: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching agro store initial balance:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroStoreInitialBalance();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroStoreInitialBalance, isLoading, APIError };
};

export const useAgroShowromInitialBalance = () => {
  const [agroShowroomInitialBalance, setAgroShowroomInitialBalance] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  useEffect(() => {
    const fetchAgroShowromInitialBalance = async () => {
      try {
        const response = await agroShowroomTruthTrackService.getInitialBalance(
          branch
        );
        if (response.status === "success") {
          setAgroShowroomInitialBalance(response.data);
        } else {
          setError(
            `Error fetching agro showroom initial balance: ${response.status}`
          );
        }
      } catch (error) {
        setError("Error fetching agro showroom initial balance:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgroShowromInitialBalance();

    return () => {
      // Cleanup function
    };
  }, []);

  return { agroShowroomInitialBalance, isLoading, APIError };
};

export const useAgroPendingproducts = () => {
  const [agropendingproducts, setAgroPendingproducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  const fetchAgroPendingproducts = async () => {
    try {
      const response = await agroShowroomService.getPendingProducts(branch);
      if (response.status === "success") {
        setAgroPendingproducts(response.data);
      } else {
        setError(`Error fetching agro pending products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching agro pending products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgroPendingproducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchAgroPendingproducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { agropendingproducts, isLoading, APIError, handleFetchData };
};

export const useVetPendingproducts = () => {
  const [vetpendingproducts, setVetPendingproducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setError] = useState(null);
  const branch = localStorage.getItem("employeeBranch");

  const fetchVetPendingproducts = async () => {
    try {
      const response = await vetShowroomService.getPendingProducts(branch);
      if (response.status === "success") {
        setVetPendingproducts(response.data);
      } else {
        setError(`Error fetching vet pending products: ${response.status}`);
      }
    } catch (error) {
      setError("Error fetching vet pending products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVetPendingproducts();

    return () => {
      // Cleanup function
    };
  }, []);
  const handleFetchData = async () => {
    setIsLoading(true);
    try {
      await fetchVetPendingproducts();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return { vetpendingproducts, isLoading, APIError, handleFetchData };
};
