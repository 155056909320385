import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import Dashboard from "../Dashboard/dashboard";
import CustomerStatsCard from "./CustomerStatsCard";
import EditCustomerForm from "./EditCustomer";
import AgroCustomersPieChart from "../Charts/PieCharts/AgroCustomersPieChart";
import AgroCustomersBarChart from "../Charts/BarCharts/AgroCustomersBarChart";

function CustomerList({
  customer,
  handleDelete,
  successMessage,
  Error,
  isLoading,
  handleEditCustomer,
  service,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [editingCustomer, setEditingCustomer] = useState(null);
  const handleEdit = (customer) => {
    setEditingCustomer(customer);
  };
  const handleCancelEdit = () => {
    setEditingCustomer(null);
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredCustomers = customer
    ? customer.filter((customer) => {
        const fieldsToSearch = [
          "customer_first_name",
          "customer_last_name",
          "customer_phone",
          "customer_sex",
          "customer_address",
        ];
        return fieldsToSearch.some((field) =>
          String(customer[field])
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];
  return (
    <Dashboard>
      {editingCustomer ? (
        <EditCustomerForm
          customerId={editingCustomer.customer_id}
          onCancel={handleCancelEdit}
          editingCustomer={editingCustomer}
          handleEditCustomer={handleEditCustomer}
          Error={Error}
        />
      ) : (
        <>
          {" "}
          <CustomerStatsCard data={customer} />
          <div className="container-fluid  pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-black text-center rounded pie-mf">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6 className="mb-0 text-info">Male & Female Demography</h6>
                    <Link className="text-danger">Show All</Link>
                  </div>
                  <AgroCustomersPieChart
                    className="mb-5"
                    agroCustomer={customer}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bg-dd text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6 className="mb-0 text-info">Adderss & Location</h6>
                    <Link className="text-danger">Show All</Link>
                  </div>
                  <AgroCustomersBarChart agroCustomer={customer} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1 mb-5 mt-1">
            <div className="col-12">
              <div className="bg-active-customer rounded  p-4">
                <div className="tab-header ">
                  <div className="form-floating mb-5 ">
                    <input
                      type="search"
                      className="form-control bg-primary"
                      id="floatingInput"
                      placeholder="Find Customers "
                      value={searchInput}
                      onChange={handleSearch}
                    />
                    <label htmlFor="floatingInput">
                      <SearchIcon className="me-2" />
                      Find Customers
                    </label>
                  </div>{" "}
                  <Link
                    to={
                      service === "Vet"
                        ? "/dashboard/vet/add-customer"
                        : "/dashboard/agro/add-customer"
                    }
                  >
                    <PersonAddIcon className="add-newcoustomericon" />
                  </Link>
                </div>
                {successMessage && (
                  <div className="alert alert-success text-dark " role="alert">
                    {successMessage}
                  </div>
                )}{" "}
                {Error && (
                  <div className="alert alert-danger" role="alert">
                    {Error}
                  </div>
                )}{" "}
                <div className="table-responsive">
                  <table className="table table-borderless table-hover table-striped table-dark ">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Sex</th>
                        <th scope="col">Address</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Transaction</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="12" className="text-center">
                            <span className="loader"></span>
                          </td>
                        </tr>
                      ) : (
                        filteredCustomers.map((customer) => (
                          <tr scope="row" key={customer.customer_id}>
                            <td>{customer.customer_id}</td>
                            <td>{customer.customer_first_name}</td>
                            <td>{customer.customer_last_name}</td>
                            <td>{customer.customer_sex}</td>
                            <td>{customer.customer_address}</td>
                            <td>{customer.customer_phone}</td>
                            <td>
                              <span className="created_at">
                                {formatDistanceToNow(
                                  parseISO(customer.created_at),
                                  { addSuffix: true }
                                )}
                              </span>
                            </td>
                            <td>
                              <Link
                                to={
                                  service === "Vet"
                                    ? `/vet/customer/transaction-profile/${customer.post_id}`
                                    : `/agro/customer/transaction-profile/${customer.post_id}`
                                }
                                className="btn btn-sm btn-outline-info"
                              >
                                Transaction Profile
                              </Link>
                            </td>
                            <td>
                              <Link
                                onClick={() => handleEdit(customer)}
                                className="btn btn-sm btn-outline-success"
                              >
                                Edit
                              </Link>{" "}
                              <Link
                                onClick={() =>
                                  handleDelete(customer.customer_id)
                                }
                                className="btn btn-sm btn-outline-danger"
                              >
                                Remove
                              </Link>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default CustomerList;
