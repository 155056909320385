export const SIunits = [
  { value: "KG", label: "Kilogram" },
  { value: "GM", label: "Gram" },
  { value: "SACH", label: "Sachet" },
  { value: "LIT", label: "Liter" },
  { value: "ML", label: "Milliliter" },
  { value: "J", label: "Jerrican" },
  { value: "M", label: "Meter" },
  { value: "PC", label: "Piece" },
  { value: "MG", label: "Milligram" },
  { value: "CM", label: "Centimeter" },
  { value: "GAL", label: "Gallon" },
  { value: "HR", label: "Hour" },
  { value: "BTL", label: "Bottle" },
  { value: "PK", label: "Pack" },
  { value: "BOX", label: "Box" },
  { value: "CAN", label: "Can" },
  { value: "CRT", label: "Carton" },
  { value: "PAIR", label: "Pair" },
  { value: "SET", label: "Set" },
  { value: "AMP", label: "Ampoule" },
  { value: "VIAL", label: "Vial" },
  { value: "SYR", label: "Syringe" },
  { value: "CTG", label: "Cotton Gauze" },
  { value: "TB", label: "Tablet" },
  { value: "BOL", label: "Bolus" },
  { value: "QTL", label: "Quintal" },
  { value: "CAP", label: "Capsule" },
  { value: "OINT", label: "Ointment" },
  { value: "DRL", label: "Dropper Bottle" },
  { value: "INH", label: "Inhaler" },
  { value: "LIQ", label: "Liquid" },
  { value: "GRN", label: "Granule" },
  { value: "PDR", label: "Powder" },
  { value: "SPR", label: "Spray" },
  { value: "DUST", label: "Dust" },
  { value: "SOL", label: "Solution" },
  { value: "EMUL", label: "Emulsion" },
  { value: "FERT", label: "Fertilizer" },
  { value: "NUM", label: "Number" },
];
// Total = 34 SI UNIT OF PRODUCTS!

export const veterinaryDrugTypes = [
  {
    value: "Animal-Feed",
    label: "Animal-Feed",
  },
  { value: "ANTIBIOTIC", label: "Antibiotic" },
  { value: "ANTISEPTIC", label: "Antiseptic" },
  { value: "ANALGESIC", label: "Analgesic" },
  { value: "ANTIPARASITIC", label: "Antiparasitic" },
  { value: "ANTIINFLAMMATORY", label: "Anti-inflammatory" },
  { value: "ANTIFUNGAL", label: "Antifungal" },
  { value: "ANTIVIRAL", label: "Antiviral" },
  { value: "ANTIPAIN", label: "Antipain" },
  { value: "POULTRY_EQUIPMENT", label: "Poultry Equipment" },
  { value: "ANTIPROTOZOAL", label: "Antiprotozoal" },
  { value: "VACCINE", label: "Vaccine" },
  { value: "DIURETIC", label: "Diuretic" },
  { value: "ANTIHISTAMINE", label: "Antihistamine" },
  { value: "DEWORMER", label: "Dewormer" },
  { value: "HORMONE", label: "Hormone" },
  { value: "VITAMIN", label: "Vitamin" },
  { value: "NSAID", label: "Non-Steroidal Anti-Inflammatory Drug" },
  { value: "TRANQUILIZER", label: "Tranquilizer" },
  { value: "ANTIMYCOTIC", label: "Antimycotic" },
  { value: "IMMUNOSUPPRESSANT", label: "Immunosuppressant" },
  { value: "PROBIOTIC", label: "Probiotic" },
  { value: "ANTICOAGULANT", label: "Anticoagulant" },
  { value: "ANTIPYRETIC", label: "Antipyretic" },
  { value: "ANTIOXIDANT", label: "Antioxidant" },
  { value: "ANESTHETIC", label: "Anesthetic" },
  { value: "EXPECTORANT", label: "Expectorant" },
  { value: "ANTIDIARRHEAL", label: "Antidiarrheal" },
  { value: "ANTIPRURITIC", label: "Antipruritic" },
  { value: "ANTIFLATULENT", label: "Antiflatulent" },
  { value: "ANTIEPILEPTIC", label: "Antiepileptic" },
  { value: "REPRODUCTIVE", label: "Reproductive Hormone" },
  { value: "NUTRITIONAL_SUPPLEMENT", label: "Nutritional Supplement" },
  { value: "ANTICOUGH", label: "Anticough" },
  { value: "ANTIHYPERTENSIVE", label: "Antihypertensive" },
  { value: "ANTIGEN", label: "Antigen" },
  { value: "ANTIHISTORAMINIC", label: "Antihistaminic" },
  { value: "ANTIMICROBIAL", label: "Antimicrobial" },
  // Add more drug types as needed
];
export const animalFeed = [
  {
    value: "Animal-Feed",
    label: "Animal-Feed",
  },
];
export const agrochemicalDrugTypes = [
  { label: "ALL" },
  { value: "INSECTICIDE", label: "Insecticide" },
  { value: "HERBICIDE", label: "Herbicide" },
  { value: "FUNGICIDE", label: "Fungicide" },
  { value: "MITICIDE", label: "Miticide" },
  { value: "NEMATICIDE", label: "Nematicide" },
  { value: "BACTERICIDE", label: "Bactericide" },
  { value: "ALGAECIDE", label: "Algaecide" },
  { value: "RODENTICIDE", label: "Rodenticide" },
  { value: "FERTILIZER", label: "Fertilizer" },
  { value: "GROWTH_REGULATOR", label: "Growth Regulator" },
  { value: "FARM_TOOL", label: "Farm Tool" },
  { value: "VEGETABLE_SEED", label: "Vegetable Seed" },
  { value: "PLANT_ACTIVATOR", label: "Plant Activator" },
  { value: "PLANT_STIMULANT", label: "Plant Stimulant" },
  { value: "PH_INCREASE", label: "pH Increase Agent" },
  { value: "PH_DECREASE", label: "pH Decrease Agent" },
  { value: "ADJUVANT", label: "Adjuvant" },
  { value: "SURFACTANT", label: "Surfactant" },
  { value: "PEST_REPELLENT", label: "Pest Repellent" },
  { value: "SOIL_AMENDMENT", label: "Soil Amendment" },
  { value: "ROOTING_AGENT", label: "Rooting Agent" },
  { value: "ANTIFOAM", label: "Antifoam" },
  { value: "PLANT_NUTRIENT", label: "Plant Nutrient" },
  { value: "WEED_KILLER", label: "Weed Killer" },
  { value: "PLANT_PROTECTOR", label: "Plant Protector" },
  { value: "ORGANIC_FERTILIZER", label: "Organic Fertilizer" },
  { value: "PESTICIDAL_OIL", label: "Pestidal Oil" },
  { value: "WEED_PREVENTER", label: "Weed Preventer" },
  { value: "SOIL_DISINFECTANT", label: "Soil Disinfectant" },
  { value: "PLANT_BIOSTIMULANT", label: "Plant Biostimulant" },
  { value: "MICRONUTRIENT", label: "Micronutrient" },
  { value: "FOLIAR_FEED", label: "Foliar Feed" },
  { value: "PLANT_CONDITIONER", label: "Plant Conditioner" },
  { value: "PLANT_DEFENSIVE", label: "Plant Defensive" },
  { value: "IRRIGATION_AID", label: "Irrigation Aid" },
  { value: "STICKER_SPREADER", label: "Sticker Spreader" },
  { value: "HYDROGEL", label: "Hydrogel" },
  { value: "SLOW_RELEASE_FERTILIZER", label: "Slow-Release Fertilizer" },
  { value: "WETTING_AGENT", label: "Wetting Agent" },
  { value: "STABILIZER", label: "Stabilizer" },
  { value: "DROUGHT_RESISTANT_AGENT", label: "Drought-Resistant Agent" },
  { value: "PLANT_ENHANCER", label: "Plant Enhancer" },
  { value: "CROP_CONDITIONER", label: "Crop Conditioner" },
  { value: "BIOPROTECTANT", label: "Bioprotectant" },
  { value: "ANTI_EVICTION_AGENT", label: "Anti-eviction Agent" },
  { value: "PEST_REPELLENT_SPRAY", label: "Pest Repellent Spray" },
  { value: "PLANT_ROOT_INVIGORATOR", label: "Plant Root Invigorator" },
  { value: "AERIAL_HERBICIDE", label: "Aerial Herbicide" },
  { value: "AQUATIC_HERBICIDE", label: "Aquatic Herbicide" },
  { value: "PLANT_VITALIZER", label: "Plant Vitalizer" },
  { value: "PHOSPHORUS_FERTILIZER", label: "Phosphorus Fertilizer" },
  { value: "NITROGEN_FERTILIZER", label: "Nitrogen Fertilizer" },
  { value: "POTASSIUM_FERTILIZER", label: "Potassium Fertilizer" },
  { value: "CALCIUM_FERTILIZER", label: "Calcium Fertilizer" },
  { value: "MAGNESIUM_FERTILIZER", label: "Magnesium Fertilizer" },
  { value: "ZINC_FERTILIZER", label: "Zinc Fertilizer" },
  { value: "COPPER_FERTILIZER", label: "Copper Fertilizer" },
  { value: "BORON_FERTILIZER", label: "Boron Fertilizer" },
  { value: "IRON_FERTILIZER", label: "Iron Fertilizer" },
  { value: "MANGANESE_FERTILIZER", label: "Manganese Fertilizer" },
  { value: "MOLYBDENUM_FERTILIZER", label: "Molybdenum Fertilizer" },
  { value: "SULFUR_FERTILIZER", label: "Sulfur Fertilizer" },
  { value: "FERTIGATION_FERTILIZER", label: "Fertigation Fertilizer" },
  { value: "MICROBIAL_FERTILIZER", label: "Microbial Fertilizer" },
  { value: "GROWTH_HORMONE", label: "Growth Hormone" },
  { value: "PLANT_STRENGTHENER", label: "Plant Strengthener" },
  { value: "HYDROPONIC_NUTRIENT", label: "Hydroponic Nutrient" },
  { value: "SLOW_RELEASE_HERBICIDE", label: "Slow-Release Herbicide" },
  { value: "CONTACT_HERBICIDE", label: "Contact Herbicide" },
  { value: "PRE-EMERGENCE_HERBICIDE", label: "Pre-emergence Herbicide" },
  { value: "SELECTIVE_HERBICIDE", label: "Selective Herbicide" },
  { value: "NON-SELECTIVE_HERBICIDE", label: "Non-Selective Herbicide" },
  { value: "SYSTEMIC_HERBICIDE", label: "Systemic Herbicide" },
  { value: "RESIDUAL_HERBICIDE", label: "Residual Herbicide" },
  { value: "SOIL_FUMIGANT", label: "Soil Fumigant" },
  { value: "AEROSOL_INSECTICIDE", label: "Aerosol Insecticide" },
  { value: "GRANULAR_INSECTICIDE", label: "Granular Insecticide" },
  { value: "BIOLOGICAL_INSECTICIDE", label: "Biological Insecticide" },
  { value: "CHEMICAL_INSECTICIDE", label: "Chemical Insecticide" },
  { value: "SYSTEMIC_INSECTICIDE", label: "Systemic Insecticide" },
  { value: "BACTERICIDAL_FUNGICIDE", label: "Bactericidal Fungicide" },
  { value: "STERILANT_FUNGICIDE", label: "Sterilant Fungicide" },
  { value: "RESISTANT_FUNGICIDE", label: "Resistant Fungicide" },
  { value: "PROTECTIVE_FUNGICIDE", label: "Protective Fungicide" },
  { value: "SOIL_APPLICATION_FUNGICIDE", label: "Soil Application Fungicide" },
  { value: "AERIAL_FUNGICIDE", label: "Aerial Fungicide" },
  { value: "CONTACT_FUNGICIDE", label: "Contact Fungicide" },
  { value: "POST-HARVEST_FUNGICIDE", label: "Post-Harvest Fungicide" },
  { value: "FUMIGANT_FUNGICIDE", label: "Fumigant Fungicide" },
  { value: "PHYTOTOXIC_FUNGICIDE", label: "Phytotoxic Fungicide" },
];

export const AnimalFeed = [
  {
    value: "Alema CHICKEN STARTER crumble",
    label: "Alema CHICKEN STARTER crumble",
  },
  {
    value: "Alema BROILER GROWER pellet",
    label: "Alema BROILER GROWER pellet",
  },
  {
    value: "Alema BROILER FINISHER Pellet",
    label: "Alema BROILER FINISHER Pellet",
  },
  {
    value: "Alema SASSO FINISHER pellet",
    label: "Alema SASSO FINISHER pellet",
  },
  { value: "Alema SASSO GROWER pellet", label: "Alema SASSO GROWER pellet" },
  { value: "Alema REARING", label: "Alema REARING" },
  { value: "Alema PULLET", label: "Alema PULLET" },
  {
    value: "Alema LAYER SUPER PHASE One",
    label: "Alema LAYER SUPER PHASE One",
  },
  {
    value: "Alema LAYER SUPER PHASE Two",
    label: "Alema LAYER SUPER PHASE Two",
  },
  { value: "Alema CALF REARING pellet", label: "Alema CALF REARING pellet" },
  { value: "Alema BEEF FATTENING BASIC", label: "Alema BEEF FATTENING BASIC" },
  { value: "Alema BEEF FATTENING SUPER", label: "Alema BEEF FATTENING SUPER" },
  {
    value: "Alema DAIRY EXCELLENT pellet",
    label: "Alema DAIRY EXCELLENT pellet",
  },
  { value: "Alema DAIRY EXCELLENT mash", label: "Alema DAIRY EXCELLENT mash" },
  { value: "Alema DAIRY SUPER pellet", label: "Alema DAIRY SUPER pellet" },
  { value: "Alema DAIRY SUPER mash", label: "Alema DAIRY SUPER mash" },
  { value: "Alema DAIRY BASIC", label: "Alema DAIRY BASIC" },
  {
    value: "Alema SHEEP AND GOAT pellet",
    label: "Alema SHEEP AND GOAT pellet",
  },
  {
    value: "Mule CHICKEN STARTER crumble",
    label: "Mule CHICKEN STARTER crumble",
  },
  { value: "Mule BROILER GROWER pellet", label: "Mule BROILER GROWER pellet" },
  {
    value: "Mule BROILER FINISHER Pellet",
    label: "Mule BROILER FINISHER Pellet",
  },
  { value: "Mule SASSO FINISHER pellet", label: "Mule SASSO FINISHER pellet" },
  { value: "Mule SASSO GROWER pellet", label: "Mule SASSO GROWER pellet" },
  { value: "Mule REARING", label: "Mule REARING" },
  { value: "Mule PULLET", label: "Mule PULLET" },
  { value: "Mule LAYER SUPER PHASE One", label: "Mule LAYER SUPER PHASE One" },
  { value: "Mule LAYER SUPER PHASE Two", label: "Mule LAYER SUPER PHASE Two" },
  { value: "Mule CALF REARING pellet", label: "Mule CALF REARING pellet" },
  { value: "Mule BEEF FATTENING BASIC", label: "Mule BEEF FATTENING BASIC" },
  { value: "Mule BEEF FATTENING SUPER", label: "Mule BEEF FATTENING SUPER" },
  {
    value: "Mule DAIRY EXCELLENT pellet",
    label: "Mule DAIRY EXCELLENT pellet",
  },
  { value: "Mule DAIRY EXCELLENT mash", label: "Mule DAIRY EXCELLENT mash" },
  { value: "Mule DAIRY SUPER pellet", label: "Mule DAIRY SUPER pellet" },
  { value: "Mule DAIRY SUPER mash", label: "Mule DAIRY SUPER mash" },
  { value: "Mule DAIRY BASIC", label: "Mule DAIRY BASIC" },
  { value: "Mule SHEEP AND GOAT pellet", label: "Mule SHEEP AND GOAT pellet" },
  {
    value: "Albar CHICKEN STARTER crumble",
    label: "Albar CHICKEN STARTER crumble",
  },
  {
    value: "Albar BROILER GROWER pellet",
    label: "Albar BROILER GROWER pellet",
  },
  {
    value: "Albar BROILER FINISHER Pellet",
    label: "Albar BROILER FINISHER Pellet",
  },
  {
    value: "Albar SASSO FINISHER pellet",
    label: "Albar SASSO FINISHER pellet",
  },
  { value: "Albar SASSO GROWER pellet", label: "Albar SASSO GROWER pellet" },
  { value: "Albar REARING", label: "Albar REARING" },
  { value: "Albar PULLET", label: "Albar PULLET" },
  {
    value: "Albar LAYER SUPER PHASE One",
    label: "Albar LAYER SUPER PHASE One",
  },
  {
    value: "Albar LAYER SUPER PHASE Two",
    label: "Albar LAYER SUPER PHASE Two",
  },
  { value: "Albar CALF REARING pellet", label: "Albar CALF REARING pellet" },
  { value: "Albar BEEF FATTENING BASIC", label: "Albar BEEF FATTENING BASIC" },
  { value: "Albar BEEF FATTENING SUPER", label: "Albar BEEF FATTENING SUPER" },
  {
    value: "Albar DAIRY EXCELLENT pellet",
    label: "Albar DAIRY EXCELLENT pellet",
  },
  { value: "Albar DAIRY EXCELLENT mash", label: "Albar DAIRY EXCELLENT mash" },
  { value: "Albar DAIRY SUPER pellet", label: "Albar DAIRY SUPER pellet" },
  { value: "Albar DAIRY SUPER mash", label: "Albar DAIRY SUPER mash" },
  { value: "Albar DAIRY BASIC", label: "Albar DAIRY BASIC" },
  {
    value: "Albar SHEEP AND GOAT pellet",
    label: "Albar SHEEP AND GOAT pellet",
  },
];
