import React, { useState, useEffect } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";


const ContributionGraph = ({ data, width, height }) => {
  const [lastStatusColor, setLastStatusColor] = useState(null);

  useEffect(() => {
    // Extracting the last contribution status color
    if (data.length > 0) {
      const lastContribution = data[data.length - 1];
      const colorMapping = {
        Poor: "#ff0303",
        Fair: "#FFFF00",
        Remarkable: "1660FF",
        Good: "#00FF00",
      };
      setLastStatusColor(colorMapping[lastContribution.status] || null);
    }
  }, [data]);

  // Extracting the required data from the provided JSON
  const contributions = data.map((item) => {
    const date = new Date(item.created_at.split("T")[0]);
    date.setDate(date.getDate() - 1); // Subtract one day
    return {
      date: date,
      count: parseInt(item.Total),
      status: item.status || null, // Extracting status
    };
  });

  // Set the start date to the beginning of the current year
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  
  // Set the end date to exactly one year after the start date
  const endDate = new Date(startDate.getFullYear() + 1, 0, 1);

  return (
    <div style={{ width: width, height: height }}>
      <CalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        values={contributions}
        showWeekdayLabels={true}
        titleForValue={(value) => {
          if (!value) {
            return `No activity`;
          }
          if (!value || !value.status) {
            return null;
          }
          return `${value.date.toISOString().slice(0, 10)} - : ${
            value.count ? value.count : 0
          }% Which Is - ${value.status} Contribution`;
        }}
        classForValue={(value) => {
          if (value && value.status) {
            return `color-${value.status.toLowerCase()}`;
          }
          return "color-default";
        }}
      />
      <style>{`
        .color-poor {
          fill: #ff0303;
          rx: 2px;
        }
        .color-fair {
          fill: #ffff00;
          rx: 2px;
        }
        .color-good {
          fill: #00ff00;
          rx: 2px;
        }
        .color-remarkable {
          fill: #1d1dd1;
          rx: 2px;
        }
        .color-default {
          fill: #161b22;
          outline: 1px solid rgb(27 31 35 / 6%);
          rx: 2px;
          outline-offset: -3px;
        }
      `}</style>
    </div>
  );
};

export default ContributionGraph;
