const api_url = process.env.REACT_APP_API_URL;

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Function to add response
const addResponse = async (responseDetails) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(responseDetails),
  };

  try {
    const response = await fetch(
      `${api_url}/api/response/help`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to add response: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error adding response:", error.message);
    throw error;
  }
};

// Function to get responses by help ID
const getResponsesByHelpId = async (helpId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/response/help/${helpId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get responses by help ID: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting responses by help ID:", error.message);
    throw error;
  }
};

// Function to delete response
const deleteResponse = async (responseId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/response/help/${responseId}`,
      requestOptions
    );
    return response;
  } catch (error) {
    console.error("Error deleting response:", error.message);
    throw error;
  }
};

// Export all the functions
const HelpresponseService = {
  addResponse,
  getResponsesByHelpId,
  deleteResponse,
};

export default HelpresponseService;
