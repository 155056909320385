import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchIcon from "@mui/icons-material/Search";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
function OrderList({
  Orders,
  showDates,
  DELETEOrder,
  SuccessMessage,
  total,
  totalPending,
  totalDelivered,
  setEditingOrder,
  searchInput,
  handleSearch,
  showFullNames,
  isLoading,
}) {
  // console.log(Orders);
  const handleEdit = (order) => {
    setEditingOrder(order);
  };
  const OrdersData = Orders
    ? Orders.filter((Orders) => {
        const fieldsToSearch = [
          "customer_id",
          "order_id",
          "product_quantity",
          "product_id",
          "status",
          "branch",
          "ordered_date",
        ];
        return fieldsToSearch.some((field) =>
          String(Orders[field])
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];
  return (
    <div>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4 text-white">
          <div className="col-sm-4 col-xl-4">
            <div className="ovr-8 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <ShoppingCartOutlinedIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total Orders</p>
                <h6 className="mb-0">{total}</h6>
              </div>
            </div>
          </div>{" "}
          <div className="col-sm-4 col-xl-4">
            <div className="ovr-1 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <AccessTimeIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total Pedding</p>
                <h6 className="mb-0">{totalPending}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xl-4">
            <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <LocalShippingIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total Delivered</p>
                <h6 className="mb-0">{totalDelivered}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4 px-4 mb-5 ">
        <div className="bg-dark text-center rounded p-4 border ">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="form-floating">
              <input
                type="search"
                className="form-control bg- "
                id="floatingInput"
                placeholder="Find Orders..."
                value={searchInput}
                onChange={handleSearch}
              />
              <label htmlFor="floatingInput">
                <SearchIcon className="me-1" />
                Find Orders...
              </label>
            </div>{" "}
            <Link className="xpm">Show All</Link>
          </div>
          {SuccessMessage && (
            <div className="alert alert-success text-dark " role="alert">
              {SuccessMessage}
            </div>
          )}
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover table-dark table-striped">
              <thead>
                <tr className="text-white">
                  <th scope="col">Order ID</th>
                  <th scope="col">Customer Name </th>
                  <th scope="col">Profile</th>
                  {/* <th scope="col">Prodct Name</th> */}
                  {/* <th scope="col">Prodct Unit</th> */}
                  <th scope="col">Order QTY</th>
                  <th scope="col">Status</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Ordered Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {!OrdersData.length && isLoading ? (
                  <tr>
                    <td colSpan="19" className="text-center">
                      <span className="loader"></span>
                    </td>
                  </tr>
                ) : (
                  OrdersData.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order.order_id}</td>
                      <td className="justify-content-between ">
                        {order.customer.customer_first_name}
                      </td>
                      <td>
                        <Link
                          className="custom-pill-pink text-decoration-none"
                          to={`/agro/customer/transaction-profile/${order.order.customer_post_id}`}
                        >
                          View
                        </Link>
                      </td>
                      {/* <td className={`${showFullNames ? "" : "clip-text"}`}>
                        {order.order.product_name}
                      </td> */}
                      {/* <td>{order.order.product_unit}</td> */}
                      <td>{order.order.product_quantity}</td>
                      <td>
                        {order.order.status === "pending" ? (
                          <span className="badge badge-pill bg-warning text-black">
                            {order.order.status}
                          </span>
                        ) : (
                          <span className="badge badge-pill bg-success text-white">
                            {order.order.status}
                          </span>
                        )}
                      </td>
                      <td>{order.order.branch}</td>

                      {showDates ? (
                        <td>
                          <span className="sold_date-format">
                            {format(
                              parseISO(order.order.ordered_date),
                              "MM/dd/yyyy HH:mm:ss"
                            )}
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span className="custom-pill-yellow">
                            {formatDistanceToNow(
                              parseISO(order.order.ordered_date),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        </td>
                      )}
                      <td>
                        <Link
                          className="me-1"
                          onClick={() => handleEdit(order.order)}
                        >
                          <span className="badge bg-success">Edit</span>
                        </Link>
                        <Link onClick={() => DELETEOrder(order.order.order_id)}>
                          <span className="badge bg-danger">Remove</span>
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderList;
