import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js/auto";

function ProductTypePieChart({ products }) {
  const chartRef = useRef(null);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    // Process the product data to get the count of each product type
    const productTypeData = products.reduce((acc, product) => {
      acc[product.product_type || product.agro_chemical_type] =
        (acc[product.product_type || product.agro_chemical_type] || 0) + 1;
      return acc;
    }, {});

    // Extract labels and data for Chart.js
    const labels = Object.keys(productTypeData);
    const data = Object.values(productTypeData);

    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const colors = [
      "#FF6384", // Red
      "#36A2EB", // Blue
      "#FFCE56", // Yellow
      "#4BC0C0", // Green
      "#9966FF", // Purple
      "#FF8A80", // Light Red
      "#81D4FA", // Light Blue
      "#FFD54F", // Light Yellow
      "#4DB6AC", // Light Green
      "#B39DDB", // Light Purple
      "#FFA726", // Orange
      "#7986CB", // Indigo
      "#FFCA28", // Amber
      "#66BB6A", // Light Green
      "#EF5350", // Pink
      "#EC407A", // Fuchsia
      "#FFB74D", // Orange
      "#26A69A", // Cyan
      "#7E57C2", // Purple
      "#9CCC65", // Lime
      "#5C6BC0", // Indigo
      "#FFEE58", // Yellow
      "#29B6F6", // Sky Blue
      "#FF7043", // Deep Orange
      "#9E9E9E", // Grey
    ];
    // Render the Pie Chart
    const ctx = document.getElementById("ProductTypePieChart").getContext("2d");

    chartRef.current = new Chart(ctx, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colors,
          },
        ],
      },
      options: {
        animation: {
          duration: 1, // Set animation duration to 0 to disable animations
        },
      },
    });
  }, [products]);

  return <canvas id="ProductTypePieChart"></canvas>;
}

export default ProductTypePieChart;
