import React, { useEffect, useState } from "react";
import vetStoreOutedProductService from "../../../../../services/vet_store_outed_products.service";
import OutedProductInvoices from "../../../../components/xlsx/OutedProductInvoices";
const getDataFromLocalStorage = () => {
  const data = localStorage.getItem("hash_ops_data");
  return data ? JSON.parse(data) : [];
};
function VetOutedProductInvoice() {
  const data = getDataFromLocalStorage();
  const Products = data.products || [];
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response =
          await vetStoreOutedProductService.getOutedProductsByIds(Products);
        if (response.status === "success") {
          setProducts(response.data);
          // console.log("Server Response:", response);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <OutedProductInvoices Products={products} isLoading={isLoading} />
    </div>
  );
}

export default VetOutedProductInvoice;
