// EditEmployeeForm.jsx
import React, { useState, useEffect } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import { Link, useNavigate } from "react-router-dom";
import employeeService from "../../../../services/employee.service";
import { useAuth } from "../../../../Context/AuthContext";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BranchService from "../../../../services/branch.service";

function EditEmployeeForm({ employeeId, onCancel, editingEmployee }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [branchs, setBranchs] = useState([]);

  const [employeeData, setEmployeeData] = useState({
    employee_email: "",
    employee_first_name: "",
    employee_last_name: "",
    employee_phone: "",
    employee_branch: "",
    employee_salary: "",
    field_of_study: "",
    employee_privilege: "",
    active_employee: 1,
    company_role_id: "",
    ...editingEmployee, // Use editingEmployee data if available
  });

  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmployeeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await employeeService.editEmployee(
        employeeId,
        employeeData
      );

      if (response.status === "success") {
        setTimeout(() => {
          window.location.href = "/admin/employees";
        }, 2000);
      }
    } catch (error) {
      console.error("Error editing employee:", error);
      setServerError("Error editing employee. Please try again.");
    }
  };

  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-info text-start div-center">
      <div className="bg-dark rounded p-4 p-sm-5 my-4 mx-3">
        <div className="d-flex align-items-center justify-content-center ">
          <h3 className="text-white text-bg-gradient ">
            <PersonAddAltIcon className="matrialuiicon" /> Edit Employee Form
          </h3>
        </div>
        {serverError && (
          <div className="validation-error" role="alert">
            {serverError}
          </div>
        )}
        <button
          type="button" // Add this line to specify the button type
          className="btn btn-secondary w-100"
          onClick={onCancel}
        >
          Cancel
        </button>
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3 m-1">
            <input
              type="text"
              className="form-control"
              id="floatingText"
              name="employee_first_name"
              value={employeeData.employee_first_name}
              onChange={handleInputChange}
              placeholder="jhon"
              required
            />
            <label htmlFor="floatingText">Employee first name</label>
          </div>
          <div className="form-floating mb-3 m-1">
            <input
              type="text"
              className="form-control"
              id="floatingText"
              name="employee_last_name"
              value={employeeData.employee_last_name}
              onChange={handleInputChange}
              placeholder="doe"
              required
            />
            <label htmlFor="floatingText">Employee last name</label>
          </div>
          <div className="form-floating mb-3 m-1">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              name="employee_email"
              value={employeeData.employee_email}
              onChange={handleInputChange}
              placeholder="name@example.com"
              required
            />
            <label htmlFor="floatingInput">Employee email</label>
          </div>
          <div className="form-floating mb-3 m-1">
            <input
              type="text"
              className="form-control"
              name="employee_phone"
              value={employeeData.employee_phone}
              onChange={handleInputChange}
              placeholder="Employee phone (555-555-5555)"
              required
            />
            <label htmlFor="floatingInput">Employee phone</label>
          </div>
          {/* <div className="form-floating mb-4 m-1">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              name="employee_password"
              value={employeeData.employee_password}
              onChange={handleInputChange}
              placeholder="Employee Password"
              required
            />
            <label htmlFor="floatingPassword">Employee Password</label>
          </div> */}
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-custom"
              id="floatingSelectB"
              name="employee_branch"
              value={employeeData.employee_branch}
              onChange={handleInputChange}
              required
            >
              <option selected hidden>
                Select Employee Branch
              </option>
              {branchs.map((branchs) => (
                <option key={branchs.branch_id} value={branchs.city}>
                  {branchs.city}
                </option>
              ))}
            </select>
            <label htmlFor="floatinginput">Employee Branch</label>
          </div>
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-custom"
              id="floatingSelectRole"
              name="company_role_id"
              value={employeeData.company_role_id}
              onChange={handleInputChange}
              required
            >
              <option selected hidden>
                Select Employee Role
              </option>
              <option value="1">Employee-Agronomist</option>
              <option value="2">Employee-Veterinarian</option>
              <option value="3">Store Manager</option>
              <option value="4">Manager</option>
              <option value="5">Admin</option>
            </select>
            <label htmlFor="floatingSelect">Employee Role</label>
          </div>
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-custom"
              id="floatingSelectF"
              name="field_of_study"
              value={employeeData.field_of_study}
              onChange={handleInputChange}
              required
            >
              <option value="Agronomist">Agronomy</option>
              <option value="Veterinarian">Veterinary Science</option>
              <option value="Agribusiness Manager">
                Agribusiness Management
              </option>
              <option value="Environmental Manager">
                Environmental Management
              </option>
              <option value="Agricultural Engineer">
                Agricultural Engineering
              </option>
              <option value="Environmental Engineer">
                Environmental Engineering
              </option>
              <option value="Irrigation Engineer">
                Irrigation Engineering
              </option>
              <option value="Agri-Tech Specialist">
                Agricultural Information Technology
              </option>
              <option value="Agricultural Marketing Specialist">
                Agricultural Marketing
              </option>
              <option value="Supply Chain Manager">
                Logistics and Supply Chain Management
              </option>
              <option value="HR Manager in Agriculture">
                Human Resource Management in Agriculture
              </option>
              <option value="Agricultural Assistant">
                High School Graduate
              </option>
            </select>
            <label htmlFor="floatinginput">Employee Field Of Study</label>
          </div>
          <div className="form-floating mb-3">
            <select
              className="form-select form-select-custom"
              id="floatingSelectP"
              name="employee_privilege"
              value={employeeData.employee_privilege}
              onChange={handleInputChange}
              required
            >
              <option selected hidden>
                Select Employee Privileges
              </option>
              <option value="INSERT">INSERT</option>
              <option value="INSERT & UPDATE">INSERT & UPDATE</option>
              <option value="INSERT & DELETE">INSERT & DELETE</option>
              <option value="INSERT DELETE & UPDATE">
                INSERT DELETE & UPDATE
              </option>
              <option value="VIEW">VIEW For Manager</option>
            </select>
            <label htmlFor="floatingSelect">Employee Privilege</label>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">ETB</span>
            <input
              type="number"
              className="form-control p-3"
              id="floatingInput"
              name="employee_salary"
              value={employeeData.employee_salary}
              onChange={handleInputChange}
              placeholder="Employee Salary per/month"
              required
            />
            <span className="input-group-text">.00</span>
          </div>

          <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
            Edit employee
          </button>
        </form>
      </div>
    </div>
  );
}
export default EditEmployeeForm;
