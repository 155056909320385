import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AddCardIcon from "@mui/icons-material/AddCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import AddCredit from "./AddCredit";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CustomerProfile from "./CustomerProfile";
import TransactionTable from "./TransactionTable";
import CreditTable from "../Credits/CreditTable";
import OrederTable from "../Orders/OrederTable";
import Dashboard from "../Dashboard/dashboard";
import OrderForm from "../Orders/OrderForm";
function TransactionProfile({
  customer,
  soldproduct,
  orders,
  credits,
  products,
  APIError,
  error,
  SellOrder,
  success,
  addCredit,
  handleOrder,
  type,
}) {
  const [IsAddingCredit, setIsAddingCredit] = useState(false);
  const [IsAddingOrder, setIsAddingOrder] = useState(false);
  const handelOnCancel = () => {
    setIsAddingCredit(false);
  };
  const handelOnCancelOrder = () => {
    setIsAddingOrder(false);
  };
  const calculateTotalRevenue = () => {
    if (!soldproduct) return 0;

    return soldproduct.reduce(
      (totalRevenue, product) =>
        totalRevenue + parseFloat(product.product_total_price),
      0
    );
  };
  const calculateTodayRevenue = () => {
    if (!soldproduct) return 0;

    // Get today's date in the format YYYY-MM-DD
    const todayDate = new Date().toISOString().split("T")[0];

    // Filter sold products for today
    const todaySoldProducts = soldproduct.filter(
      (product) => product.sold_date.split("T")[0] === todayDate
    );

    // Calculate total revenue for today
    return todaySoldProducts.reduce(
      (todayRevenue, product) =>
        todayRevenue + parseFloat(product.product_total_price),
      0
    );
  };
  const calculateCurrentMonthRevenue = () => {
    if (!soldproduct) return 0;
    // Get current month and year
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
    // Filter sold products for the current month
    const currentMonthSoldProducts = soldproduct.filter((product) => {
      const productDate = new Date(product.sold_date);
      return (
        productDate.getFullYear() === currentYear &&
        productDate.getMonth() + 1 === currentMonth
      );
    });
    // Calculate total revenue for the current month
    return currentMonthSoldProducts.reduce(
      (monthRevenue, product) =>
        monthRevenue + parseFloat(product.product_total_price),
      0
    );
  };
  return (
    <>
      {IsAddingOrder ? (
        <OrderForm
          data={products}
          customer={customer}
          APIError={APIError}
          handleOrder={handleOrder}
        />
      ) : (
        <>
          {IsAddingCredit ? (
            <AddCredit
              customer={customer}
              onCancel={handelOnCancel}
              addCredit={addCredit}
              products={products}
              APIError={APIError}
            />
          ) : (
            <Dashboard>
              {customer && (
                <>
                  <div className="container-fluid pt-4 px-4 text-end">
                    <div className="row g-4">
                      <div className="col-sm-6 col-xl-3">
                        <div className=" text-white bgsecondary revenue-div mobile-  rounded d-flex align-items-center justify-content-between p-4">
                          <i className="fa fa-chart-line fa-3x revenue-icon">
                            <MonetizationOnIcon className="salesicon" />
                          </i>
                          <div className="ms-3">
                            <p className="mb-2">Total Revenue </p>
                            <h6 className="mb-0">
                              ETB {calculateTotalRevenue().toFixed(2)}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="text-white bgsecondary rounded mobile- d-flex revenue-div align-items-center justify-content-between p-4">
                          <i className="">
                            <StackedLineChartIcon className="salesicon" />
                          </i>
                          <div className="ms-3">
                            <p className="mb-2">Today Revenue</p>
                            <h6 className="mb-0">
                              ETB {calculateTodayRevenue().toFixed(2)}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="text-white bgsecondary rounded mobile- d-flex revenue-div  align-items-center justify-content-between p-4">
                          <i className="">
                            <LeaderboardIcon className="salesicon" />
                          </i>
                          <div className="ms-3">
                            <p className="mb-2">Monthly Revenue</p>
                            <h6 className="mb-0">
                              ETB {calculateCurrentMonthRevenue().toFixed(2)}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="rounded  p-4">
                          <div className="add-new-button d-flex  mb-4 d-flex align-items-center justify-content-evenly ">
                            <Link
                              className="btn btn-md btn-outline-success"
                              onClick={() => setIsAddingCredit(true)} // Set IsAddingCredit to true when clicked
                            >
                              <AddCardIcon className="text-white" /> <br />{" "}
                              Credit
                            </Link>
                            <Link
                              to={
                                type === "vet"
                                  ? `/dashboard/showroom/vet/sell-product/${customer.customer_id}`
                                  : `/dashboard/showroom/agro/sell-product/${customer.customer_id}`
                              }
                              className="btn btn-md btn-outline-success"
                            >
                              <SellOutlinedIcon className="text-white" />
                              <br /> sell
                            </Link>
                            <Link
                              onClick={() => setIsAddingOrder(true)}
                              className="btn btn-md  btn-outline-success"
                            >
                              <AddShoppingCartIcon className="text-white" />{" "}
                              <br /> Order
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-lg-flex d-sm-block d-md-inline-block mt-3  text-start">
                    <CustomerProfile customer={customer} />
                    <div className="col-9 d-inline-block colsmdiv  justify-content-center">
                      <TransactionTable soldproduct={soldproduct} type={type} />
                      <CreditTable credits={credits} service={type} />
                      <OrederTable
                        orders={orders}
                        SellOrder={SellOrder}
                        Error={error}
                        Success={success}
                        handelOnCancelOrder={handelOnCancelOrder}
                      />
                    </div>
                  </div>
                </>
              )}
            </Dashboard>
          )}
        </>
      )}
    </>
  );
}

export default TransactionProfile;
