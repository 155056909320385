const api_url = process.env.REACT_APP_API_URL;

const sendMessage = async (prompt) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(prompt),
  };
  try {
    const response = await fetch(`${api_url}/api/ai-assistant`, requestOptions);
    if (!response.ok) {
      throw new Error(`Failed to send Message: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error sending request to server:", error.message);
    throw error;
  }
};

const getConversationByEmployeeId = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/get-conversation/${EmployeeID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch prompt: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Failed to fetch prompt:", error.message);
    throw error;
  }
};
const getPromptByConversationID = async (ConversationID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/prompts/${ConversationID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch prompt: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Failed to fetch prompt:", error.message);
    throw error;
  }
};
const getGeneratedTextByConversationID = async (ConversationID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/get-generated-text/${ConversationID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch responce: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Failed to fetch prompt:", error.message);
    throw error;
  }
};
const deleteConversation = async (ConversationId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/conversation/${ConversationId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete conversation: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting conversation:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const AIAssistantService = {
  sendMessage,
  getConversationByEmployeeId,
  getGeneratedTextByConversationID,
  getPromptByConversationID,
  deleteConversation,
};

export default AIAssistantService;
