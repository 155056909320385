import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import Select from "react-select";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SIunits, agrochemicalDrugTypes } from "../UnitsAndTypes/UnitsAndTypes";

function EditCredit({
  CreditId,
  onCancel,
  editingCredit,
  handleEditCredit,
  successMessage,
  serverError,
}) {
  const [updatedCreditData, setCreditData] = useState({
    product_name: "",
    product_type: "",
    product_unit: "",
    product_quantity: "",
    product_unit_price: "",
    ...editingCredit,
  });

  const handleProductTypeChange = (selectedOption) => {
    setCreditData((prevData) => ({
      ...prevData,
      product_type: selectedOption.value,
    }));
  };

  const handleProductUnitChange = (selectedOption) => {
    setCreditData((prevData) => ({
      ...prevData,
      product_unit: selectedOption.value,
    }));
  };

  const handleProductNameChange = (event) => {
    const { value } = event.target;
    setCreditData((prevData) => ({
      ...prevData,
      product_name: value,
    }));
  };

  const handleProductQuantityChange = (event) => {
    const { value } = event.target;
    setCreditData((prevData) => ({
      ...prevData,
      product_quantity: value,
    }));
  };

  const handleProductUnitPriceChange = (event) => {
    const { value } = event.target;
    setCreditData((prevData) => ({
      ...prevData,
      product_unit_price: value,
    }));
  };

  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-info text-start div-center">
      <div className="bg-dark rounded p-4 p-sm-5 my-4 mx-3">
        <div className="d-flex align-items-center justify-content-between  w-100">
          <h3 className="text-white text-bg-gradient">Edit Credit Form</h3>
          <div className=" fs-6   text-danger " onClick={onCancel}>
            <HighlightOffIcon />
          </div>
        </div>
        {serverError && (
          <div className="validation-error" role="alert">
            {serverError}
          </div>
        )}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}

        <form
          onSubmit={(event) =>
            handleEditCredit(event, CreditId, updatedCreditData)
          }
        >
          {/* Product Name */}
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Product Name"
              value={updatedCreditData.product_name}
              onChange={handleProductNameChange}
              required
            />
          </div>
          {/* Product Type */}
          <div className="mb-3">
            <Select
              options={agrochemicalDrugTypes}
              placeholder="Product Type"
              value={agrochemicalDrugTypes.find(
                (type) => type.value === updatedCreditData.product_type
              )}
              onChange={handleProductTypeChange}
              required
            />
          </div>
          {/* Product SI unit */}
          <div className="mb-3">
            <Select
              options={SIunits}
              placeholder="Product SI unit"
              value={SIunits.find(
                (unit) => unit.value === updatedCreditData.product_unit
              )}
              onChange={handleProductUnitChange}
              required
            />
          </div>
          {/* Product Quantity */}
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Product Quantity"
              value={updatedCreditData.product_quantity}
              onChange={handleProductQuantityChange}
              required
            />
          </div>
          {/* Product Unit Price */}
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Product Unit Price"
              value={updatedCreditData.product_unit_price}
              onChange={handleProductUnitPriceChange}
              required
            />
          </div>
          {/* Add other fields for editing credit */}
          <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
            Edit Credit
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditCredit;
