import React, { useState } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import drpd from "../../../../assets/images/drpd.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAllEmployeesActivity } from "../../../components/APIs/EmployeeAPI";
import ContributionGraph from "../../../components/ContributionGraph/ContributionGraph";
function AllEmployeesActivity() {
  const { allEmployeesActivity, isLoading } = useAllEmployeesActivity();
  const [dropdownVisibility, setDropdownVisibility] = useState(
    allEmployeesActivity.map(() => false)
  );
  // Calculate total activities and total employees
  const totalActivities = allEmployeesActivity.reduce(
    (acc, item) => acc + item.activity.length,
    0
  );
  const toggleDropdown = (index) => {
    const newVisibility = [...dropdownVisibility];
    newVisibility[index] = !newVisibility[index];
    setDropdownVisibility(newVisibility);
  };
  const width = "100%";
  const height = 250;
  const totalEmployees = allEmployeesActivity.length;
  return (
    <Dashboard>
      <div>
        <div className="container-fluid pt-4 px-4">
          <div className="row g-4 text-white">
            <div className="col-sm-6 col-xl-6">
              <div className="ovr-8 rounded d-flex align-items-center justify-content-between p-4">
                <i>Activity</i>
                <div className="ms-3 text-end">
                  <p className="mb-2">Total Activitys</p>
                  <h6 className="mb-0">{totalActivities}</h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-6">
              <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
                <i>Employees</i>
                <div className="ms-3 text-end">
                  <p className="mb-2">Total Employees</p>
                  <h6 className="mb-0">{totalEmployees}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid  pt-4 px-4 mb-2">
          <div className="secondarybg text-center rounded p-4">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 text-white text-center">
                All Employees Daily Activity
              </h3>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5">
          {allEmployeesActivity.length === 0 && isLoading ? (
            <div className="nonetable_loader"></div>
          ) : (
            <></>
          )}
        </div>
        {allEmployeesActivity &&
          allEmployeesActivity.map((item, index) => (
            <div
              className="container-fluid pt-4 px-4 mb-3 "
              key={item.activity?.activity_Id}
            >
              <div className="secondarybg licon text-center rounded p-4 ">
                <div className="justify-content-between d-flex align-items-center">
                  <div className="d-flex align-items-center ">
                    <div className="act-defualt-icon-con  me-3">
                      <AccountCircleIcon className="adi" />
                    </div>
                    <div className="act-defualt-name-con text-start">
                      <h5 className="mb-0 text-white">
                        {item.info?.employee_first_name}{" "}
                        {item.info?.employee_last_name}
                      </h5>
                      <p className="mb-0 text-white">Employee</p>
                      <p className="mb-0 text-white">
                        {" "}
                        {item.info?.field_of_study}
                      </p>
                    </div>
                  </div>
                  {item.activity.length > 0 ? (
                    <div className="d-flex align-items-center">
                      <div className="act-defualt-status-con text-start">
                        <h5 className="mb-0 text-white">
                          {item.activity[item.activity.length - 1].status}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="act-defualt-status-con text-start">
                        <h5 className="mb-0 text-white">No</h5>
                      </div>
                    </div>
                  )}

                  <div className="d-flex align-items-center ">
                    <div className="act-defualt-icon-con  me-3">
                      <img
                        src={drpd}
                        width={60}
                        className={
                          dropdownVisibility[index] ? "isDRP" : "noDRP"
                        }
                        onClick={() => toggleDropdown(index)}
                      />
                    </div>
                  </div>
                </div>
                {dropdownVisibility[index] && (
                  <div className=" align-items-center dropdownconteaner ">
                    <div className="act-defualt-icon-con  me-3">
                      <ContributionGraph
                        data={item.activity.map((item) => ({
                          activity_Id: item.activity_Id,
                          employee_name: item.employee_name,
                          Total: item.Total,
                          activity_type: item.activity_type,
                          status: item.status,
                          created_at: item.created_at,
                        }))}
                        width={width}
                        height={height}
                        margin="10px"
                      />
                    </div>

                    <div className="act-defualt-name-con text-start"></div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </Dashboard>
  );
}

export default AllEmployeesActivity;
