import React from "react";
import Contribution from "../../../components/ContributionGraph/Contribution";
import ContributionStatusDoughnutChart from "../../../components/Charts/DoughnutCharts/ContributionStatusDoughnutChart";
import ContributionBarChart from "../../../components/Charts/BarCharts/ContributionBarChart";
import { Link } from "react-router-dom";
import OtherEmployeeContribution from "../../../components/ContributionGraph/OtherEmployeeContribution";

function Activity() {
  return (
    <div>
      <h2 className="mt-4 mb-2">Your contributions in the last year</h2>
      <Contribution />
      <div className="justify-content-end text-end  me-4">
        <p>
          <span className="text-decoration-underline text-success">
            Green - Good
          </span>{" "}
          <span className="text-primary text-decoration-underline">
            Blue - Remarkable
          </span>{" "}
          <span className="text-warning text-decoration-underline">
            Yellow - Fair
          </span>{" "}
          <span className="text-danger text-decoration-underline">
            Red - Poor
          </span>{" "}
        </p>
      </div>
      <hr />
      <div className="container-fluid ">
        <div className="row g-4">
          <div className="col-sm-12 col-xl-6">
            <div className="bg-black text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0">Contribution Statuses</h6>
                <Link className="text-info">Show All</Link>
              </div>
              <ContributionStatusDoughnutChart />
            </div>
          </div>
          <div className="col-sm-12 col-xl-6">
            <div className="bg-black text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0">Contributions in the Current Month</h6>
                <Link className="text-info">Show All</Link>
              </div>
              <ContributionBarChart />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between ">
        <div></div>
      </div>
      <hr />
      <h2 className="mt-4 mb-2">Others Emloyees Contribution</h2>
      <hr className="w-50"/>
      <OtherEmployeeContribution />
    </div>
  );
}

export default Activity;
