import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/aosslogo.png";
import feedbackService from "../../../../services/survey.service";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import SurveyChecker from "./SurveyChecker";
function Survey() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);
  const EmployeeId = localStorage.getItem("EmployeeID");

  const [formData, setFormData] = useState({
    employee_Id: "",
    q1_rating: "",
    q2_achievements: "",
    q3_response_speed: "",
    q4_improvement_areas: "",
    q5_how_satisfied_on_working_environment: "",
    q6_comfortability_with_platforms_and_software: "",
    q7_software_challenges_and_difficulties: "",
    q8_describe_customer_satisfaction: "",
    q9_recurring_issues_from_customers: "",
    q10_improvement_areas_to_customer_satisfaction: "",
    q11_anything_else: "",
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      employee_Id: EmployeeId || "",
    }));
  }, [EmployeeId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Submit survey data to feedbackService
      // console.log(formData);
      await feedbackService.submitSurvey(formData);
      // Update success message state
      setSuccessMessage(true);
      // Clear the form after successful submission
      setFormData({
        employee_Id: "",
        q1_rating: "",
        q2_achievements: "",
        q3_response_speed: "",
        q4_improvement_areas: "",
        q5_how_satisfied_on_working_environment: "",
        q6_comfortability_with_platforms_and_software: "",
        q7_software_challenges_and_difficulties: "",
        q8_describe_customer_satisfaction: "",
        q9_recurring_issues_from_customers: "",
        q10_improvement_areas_to_customer_satisfaction: "",
        q11_anything_else: "",
      });
      setTimeout(() => {
        navigate("/"); // Change "/home" to your actual home route
      }, 10000);
    } catch (error) {
      console.error("Error submitting survey:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    // Clear the success message after 7 seconds
    const timer = setTimeout(() => {
      setSuccessMessage(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, [successMessage]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  useEffect(() => {
    const checkSurveyStatus = async () => {
      try {
        // Fetch survey submission status from feedbackService
        const surveyStatus = await feedbackService.checkSurveyStatus(
          EmployeeId
        );
        setSubmissionStatus(surveyStatus.message);
      } catch (error) {
        console.error("Error checking survey status:", error);
      }
    };
    // Call the function to check survey status when the component mounts
    checkSurveyStatus();
    // No need for dependency array here, we want this effect to run only once when the component mounts
  }, []);
  // "already been submitted"
  if (submissionStatus === "feedback due") {
    return (
      <div className="feedback-msg ">
        <div className="alert alert-success fs-5 al" role="alert">
          <h4 className="">
            Dear Employee, You don't need to submit feedback for this month as
            it has already been submitted. Thank you for your participation.
          </h4>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div>
          {successMessage ? (
            <div className="feedback-msg ">
              <ThumbUpOffAltIcon className="fb-icon" />
              <div className="alert alert-success fs-5 al" role="alert">
                🎉 Success! Your feedback is received and appreciated. Thank you
                for your valuable input! 🚀 You will be automatically redirected
                to the home page in 10 seconds.
              </div>
            </div>
          ) : (
            <div className="mx-0 mx-sm-auto">
              <div className="card">
                <div className="card-header bg-primary d-flex align-items-center">
                  <img src={logo} className="rounded-2" alt="logo" />
                  <h5
                    className="card-title text-white ms-3 mt-2"
                    id="exampleModalLabel"
                  >
                    Give us a feedback!
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <i className="far fa-file-alt fa-4x mb-3 text-primary"></i>
                    <p>
                      <strong>Your opinion matters!</strong>
                    </p>
                    <p>
                      Have some ideas how to improve our product?{" "}
                      <strong>Give us your feedback.</strong>
                    </p>
                  </div>
                  <hr />
                  <form className="px-4" onSubmit={handleSubmit}>
                    <p className="text-start">
                      <strong>
                        On a scale of 1 to 5, with 1 being unsatisfactory and 5
                        being highly satisfactory, how would you rate your
                        overall experience and productivity during this month?
                      </strong>
                    </p>
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <div className="form-check mb-2" key={rating}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="q1_rating"
                          id={`radioOverallRating${rating}`}
                          value={rating}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radioOverallRating${rating}`}
                        >
                          {rating}{" "}
                          {rating === 1
                            ? "😞"
                            : rating === 2
                            ? "😔"
                            : rating === 3
                            ? "😐"
                            : rating === 4
                            ? "🙂"
                            : rating === 5
                            ? "😄"
                            : ""}
                        </label>
                      </div>
                    ))}
                    <p className="text-start">
                      <strong>
                        Can you share any specific achievements or successes
                        you've had recently?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="q2_achievements"
                        name="q2_achievements"
                        rows="4"
                        required
                        value={formData.q2_achievements}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <p className="text-start">
                      <strong>
                        On a scale of 1 to 10, how would you rate the speed of
                        response to help requests from the administrator?
                      </strong>
                    </p>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                      <div className="form-check mb-2" key={rating}>
                        <label
                          className="form-check-label"
                          htmlFor={`radioResponseSpeedRating${rating}`}
                        >
                          {rating}
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="q3_response_speed"
                          id={`radioResponseSpeedRating${rating}`}
                          value={rating}
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                    <p className="text-start">
                      <strong>
                        What aspects of our work do you think we could improve
                        upon?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="q4_improvement_areas"
                        name="q4_improvement_areas"
                        rows="4"
                        required
                        value={formData.q4_improvement_areas}
                        onChange={handleChange}
                      ></textarea>
                    </div>{" "}
                    <p className="text-start">
                      <strong>
                        On a scale of 1 to 10, how satisfied are you with your
                        job and work environment?
                      </strong>
                    </p>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                      <div className="form-check mb-2" key={rating}>
                        <label
                          className="form-check-label"
                          htmlFor={`radioJobSatisfactionRating${rating}`}
                        >
                          {rating}
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="q5_how_satisfied_on_working_environment"
                          id={`radioJobSatisfactionRating${rating}`}
                          value={rating}
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                    <p className="text-start">
                      <strong>
                        How comfortable are you with the software tools and
                        platforms you use for your daily tasks?
                      </strong>
                    </p>
                    {[
                      "Very comfortable",
                      "Somewhat comfortable",
                      "Neutral",
                      "Somewhat uncomfortable",
                      "Very uncomfortable",
                    ].map((option) => (
                      <div className="form-check mb-2" key={option}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="q6_comfortability_with_platforms_and_software"
                          id={`radioSoftwareComfort${option}`}
                          value={option}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radioSoftwareComfort${option}`}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                    <p className="text-start">
                      <strong>
                        Can you identify any challenges or difficulties you
                        encounter while using the software?
                      </strong>
                    </p>
                    {[
                      "Yes, multiple challenges",
                      "Yes, a few challenges",
                      "No, I don't encounter any challenges",
                    ].map((option) => (
                      <div className="form-check mb-2" key={option}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="q7_software_challenges_and_difficulties"
                          id={`radioSoftwareChallenges${option}`}
                          value={option}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radioSoftwareChallenges${option}`}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                    <p className="text-start">
                      <strong>
                        How would you describe the level of customer
                        satisfaction based on the interactions you've had with
                        customers?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="customerSatisfaction"
                        name="q8_describe_customer_satisfaction"
                        rows="4"
                        required
                        value={formData.q8_describe_customer_satisfaction}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <p className="text-start">
                      <strong>
                        Have you encountered any recurring issues or complaints
                        from customers? If so, what are they?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="recurringIssues"
                        name="q9_recurring_issues_from_customers"
                        rows="4"
                        required
                        value={formData.q9_recurring_issues_from_customers}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <p className="text-start">
                      <strong>
                        In your opinion, what areas could we improve upon to
                        enhance customer satisfaction?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="improvementSuggestions"
                        name="q10_improvement_areas_to_customer_satisfaction"
                        rows="4"
                        required
                        value={
                          formData.q10_improvement_areas_to_customer_satisfaction
                        }
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <p className="text-start">
                      <strong>
                        Is there anything else you'd like to share?
                      </strong>
                    </p>
                    <div className="form-outline mb-4">
                      <textarea
                        className="form-control"
                        id="additionalComments"
                        name="q11_anything_else"
                        rows="4"
                        required
                        value={formData.q11_anything_else}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-lg btn-primary">
                      Send Feedback
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Survey;
