import React from "react";
import Male from "../../../assets/images/M.png";
import Female from "../../../assets/images/F.png";
function CustomerProfile({ customer }) {
  return (
    <div className="me-2">
      <div className="profile-dislay m-2">
        <div className="customer_icont">
          {customer.customer_sex === "Female" ? (
            <img src={Female} className="customer_icontrans" />
          ) : (
            <img src={Male} className="customer_icontrans" />
          )}
        </div>
        <h4 className="name_display mb-3">
          {customer.customer_first_name} {customer.customer_last_name}
        </h4>
        <div className=" rounded h-100 p-4 text-white ">
          <h6 className="mb-4">About {customer.customer_first_name}</h6>
          <dl className="row mb-0">
            <dt className="col-sm-4">Sex:</dt>
            <dd className="col-sm-8">{customer.customer_sex}</dd>
            <dt className="col-sm-4">Address:</dt>
            <dd className="col-sm-8">{customer.customer_address}</dd>
            <dt className="col-sm-4">Phone:</dt>
            <dd className="col-sm-8">{customer.customer_phone}</dd>
            <dt className="col-sm-4 text-truncate">Status:</dt>
            <dd className="col-sm-8">
              {" "}
              <span
                className={`badge ${
                  customer.status === "Active" ? "bg-success" : "bg-danger"
                }`}
              >
                {customer.status ? customer.status : "No status"}
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default CustomerProfile;
