import React, { useEffect, useState } from "react";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartIcon from "@mui/icons-material/PieChart";

function ReportOverView({ SalesReport }) {
  const [todayRevenue, setTodayRevenue] = useState(0);
  const [monthsRevenue, setMonthsRevenue] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    calculateTodayRevenue();
    calculateMonthsRevenue();
    calculateTotalQuantity();
    calculateTotalRevenue();
  }, [SalesReport]);

  const calculateTodayRevenue = () => {
    const today = new Date();
    const todayString = today.toISOString().slice(0, 10);

    const todayRevenueTotal = SalesReport.reduce((total, report) => {
      if (report.date === todayString) {
        total += parseFloat(report.by_money);
      }
      return total;
    }, 0);

    setTodayRevenue(todayRevenueTotal.toFixed(2));
  };

  const calculateMonthsRevenue = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().slice(0, 10);
    const todayString = today.toISOString().slice(0, 10);

    const monthsRevenueTotal = SalesReport.reduce((total, report) => {
      if (report.date >= firstDayOfMonthString && report.date <= todayString) {
        total += parseFloat(report.by_money);
      }
      return total;
    }, 0);

    setMonthsRevenue(monthsRevenueTotal.toFixed(2));
  };

  const calculateTotalQuantity = () => {
    const totalQuantityTotal = SalesReport.reduce((total, report) => {
      total += report.by_unit;
      return total;
    }, 0);

    setTotalQuantity(totalQuantityTotal);
  };

  const calculateTotalRevenue = () => {
    const totalRevenueTotal = SalesReport.reduce((total, report) => {
      total += parseFloat(report.by_money);
      return total;
    }, 0);

    setTotalRevenue(totalRevenueTotal.toFixed(2));
  };

  return (
    <div className="row g-4 text-white">
      <div className="col-sm-6 col-xl-4">
        <div className="ovr-4 rounded d-flex align-items-center justify-content-between p-4">
          <i>
            <StackedLineChartIcon className="newsalesicon" />
          </i>
          <div className="ms-3">
            <p className="mb-2">Today Revenue</p>
            <h6 className="mb-0">{todayRevenue} ETB</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-4">
        <div className="ovr-1 rounded d-flex align-items-center justify-content-between p-4">
          <i>
            <MonetizationOnIcon className="newsalesicon" />
          </i>
          <div className="ms-3">
            <p className="mb-2">Month's Revenue</p>
            <h6 className="mb-0">{monthsRevenue} ETB</h6>
          </div>
        </div>
      </div>
     
      <div className="col-sm-6 col-xl-4">
        <div className="ovr-6 rounded d-flex align-items-center justify-content-between p-4">
          <i>
            <PieChartIcon className="newsalesicon" />
          </i>
          <div className="ms-3">
            <p className="mb-2">Total Revenue</p>
            <h6 className="mb-0">{totalRevenue} ETB</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportOverView;
