import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

function AgroCustomersBarChart({
  agroCustomer,
  maxAddressesToShow = 10,
  maxPadding = 0.7,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    // Process the agroCustomer data to get the count of each address
    const addressData = agroCustomer.reduce((acc, customer) => {
      acc[customer.customer_address] =
        (acc[customer.customer_address] || 0) + 1;
      return acc;
    }, {});

    // Sort addresses by count in descending order
    const sortedAddresses = Object.keys(addressData).sort(
      (a, b) => addressData[b] - addressData[a]
    );

    // Take only the top addresses up to maxAddressesToShow
    const topAddresses = sortedAddresses.slice(0, maxAddressesToShow);

    // If there are more addresses, group the rest into "Other" category
    if (sortedAddresses.length > maxAddressesToShow) {
      const otherCount = sortedAddresses.length - maxAddressesToShow;
      addressData["Other"] = sortedAddresses
        .slice(maxAddressesToShow)
        .reduce((acc, address) => acc + addressData[address], 0);
    }

    // Extract labels and data for Chart.js
    const labels = Object.keys(addressData);
    const data = Object.values(addressData);

    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Generate an array of random background colors
    const backgroundColors = Array.from({ length: labels.length }, () =>
      getRandomColor()
    );

    // Calculate the maximum count of addresses
    const maxAddressCount = Math.max(...data) + maxPadding;

    // Render the Bar Chart
    const ctx = document.getElementById("addressBarChart").getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Customer Count",
            data: data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Address", // X-axis label
            },
          },
          y: {
            stacked: true,
            max: maxAddressCount, // Set maximum value with padding
            title: {
              display: true,
              text: "Customer Count", // Y-axis label
            },
          },
        },
      },
    });
  }, [agroCustomer, maxAddressesToShow, maxPadding]);

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return <canvas id="addressBarChart"></canvas>;
}

export default AgroCustomersBarChart;
