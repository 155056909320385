import React, { useState, useEffect } from "react";
import { useVetStoreAvailableProducts } from "../../../../components/APIs/FetchAPIs";
import vetStoreAvailableProductsService from "../../../../../services/vet_store_available_products.service";
import StoreAvailableProducts from "../../../../components/Products/StoreAvailableProducts";

function VetstoreAvailableProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { vetStoreAvailableProduct, isLoading, APIError,handleFetchData } =
    useVetStoreAvailableProducts();
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response =
          await vetStoreAvailableProductsService.deleteVetStoreProduct(
            ProductId
          );

        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();

    try {
      const response =
        await vetStoreAvailableProductsService.updateVetStoreProduct(
          ProductId,
          updatedProductData
        );

      if (response.status === "success") {
        setSuccessMessage("Product updated successfully.");

        setTimeout(() => {
          window.location.href = "/dashboard/store/vet/available-products";
        }, 2000);
      } else {
        setError("Failed to update product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div>
      <StoreAvailableProducts
        products={vetStoreAvailableProduct}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        handleFetchData={handleFetchData}
        service={"vet"}
      />
    </div>
  );
}

export default VetstoreAvailableProducts;
