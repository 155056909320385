import React, { useEffect, useState } from "react";
import { useAgroStoreCloneProducts } from "../../../../components/APIs/FetchAPIs";
import agroStoreCloneService from "../../../../../services/agro_store_clone_products.service";
import ShowroomProducts from "../../../../components/Products/ShowroomProducts";
import BranchService from "../../../../../services/branch.service";

function AgroStoreCloneProducts() {
  const [branchs, setBranchs] = useState([]);

  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { agroStoreCloneProducts, isLoading, APIError } =
    useAgroStoreCloneProducts();
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this Clone product?")) {
      try {
        const response =
          await agroStoreCloneService.deleteAgroStoreCloneProduct(ProductId);

        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Clone Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();
    try {
      const response = await agroStoreCloneService.updateAgroStoreCloneProduct(
        ProductId,
        updatedProductData
      );
      if (response.status === "success") {
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => {
          window.location.href = "/dashboard/store/agro/clone-products";
        }, 2000);
      } else {
        setError("Failed to update clone product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing clone product:", error);
      setError("Error editing  clone product. Please try again.");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <ShowroomProducts
        products={agroStoreCloneProducts}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        type={"clone"}
        branchs={branchs}
        service={"store"}
        servicetype={"agstore"}
      />
    </div>
  );
}

export default AgroStoreCloneProducts;
