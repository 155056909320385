import React from "react";
import ProductTypePieChart from "../Charts/PieCharts/ProductTypePieChart";
import AgroTransProfileBarChart from "../Charts/BarCharts/AgroTransProfileBarChart";
import { Link } from "react-router-dom";

function TransactionTable({ soldproduct, type }) {
  return (
    <div>
      {soldproduct && (
        <>
          <div className="container-fluid ">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-dd text-center text-white rounded  p-4">
                  <div className="d-flex mb-5 align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Products</h6>
                    <Link className="text-info">Show All</Link>
                  </div>
                  <AgroTransProfileBarChart soldProducts={soldproduct} />
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bg-black text-center text-white rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Prodcut Type</h6>
                    <Link className="text-info">Show All</Link>
                  </div>
                  <ProductTypePieChart products={soldproduct} />
                </div>
              </div>
            </div>
          </div>
          <div className=" m-2">
            <div className="transaction rounded p-4">
              <h6 className="mb-4 text-center">Transaction History</h6>
              <div className="table-responsive">
                <table className="table table-hover table-striped table-dark">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit</th>
                      <th scope="col">QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soldproduct.map((soldproduct, index) => (
                      <tr key={index}>
                        <th scope="row">{soldproduct.product_id}</th>
                        <td>{soldproduct.product_name}</td>
                        <td>{soldproduct.product_type}</td>
                        <td>{soldproduct.product_unit}</td>
                        <td>{soldproduct.product_quantity}</td>
                        <td>{soldproduct.product_unit_price}</td>
                        <td>{soldproduct.product_total_price}</td>
                        <td>{soldproduct.branch}</td>
                        <td>
                          <Link
                            to={
                              type === "vet"
                                ? "/dashboard/showroom/vet/sold-products"
                                : "/dashboard/showroom/agro/sold-products"
                            }
                            className="btn btn-sm btn-outline-info "
                          >
                            Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TransactionTable;
