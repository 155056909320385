// Import from the env
const api_url = process.env.REACT_APP_API_URL;

const getVetSalesReport = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/sales/reports`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get all vet sales report: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all vet sales reports:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const VetSalesReportService = {
  getVetSalesReport,
};

export default VetSalesReportService;
