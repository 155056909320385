import React from "react";
import ati from "../../../assets/Partners/ati.png";
import ak from "../../../assets/Partners/ak.jpg";
import moa from "../../../assets/Partners/moa.png";
import snv from "../../../assets/Partners/snv.png";
import nagii from "../../../assets/Partners/nagii.jpg";
import petal from "../../../assets/Partners/petal.png";
import eiar from "../../../assets/Partners/eiar.jpg";
import es from "../../../assets/Partners/es.jpg";
import eabc from "../../../assets/Partners/eabc.png";
import phk from "../../../assets/Partners/phk.jpg";
import chemtex from "../../../assets/Partners/chemtex.jpg";
import nvi from "../../../assets/Partners/nvi.jpg";
import EMD from "../../../assets/Partners/EMD.jpg";
import AB from "../../../assets/Partners/AB.svg";
import CBE from "../../../assets/Partners/CBE.webp";

function Partners() {
  return (
    <div id="partners" className="projectsec">
      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h1 className="mb-4 d5 text-center Titel_con">
                <span className="tspan">Our</span> Partners
              </h1>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-info-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-4">
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text- bg-white p-logos">
                <img src={ati} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card ">
              <div className="text- bg-white   p-logos">
                <img src={ak} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text- bg-white   p-logos">
                <img src={moa} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={snv} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={nagii} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={petal} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={eiar} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={es} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={eabc} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={phk} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={chemtex} alt="" />
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={nvi} alt="" />
              </div>
            </div>{" "}
            <div className="col-6 col-md-4 col-xl-4 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={EMD} alt="" />
              </div>
            </div>{" "}
            <div className="col-6 col-md-4 col-xl-4 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={AB} alt="" />
              </div>
            </div>{" "}
            <div className="col-6 col-md-4 col-xl-4 text-center logo-card">
              <div className="text-secondary bg-white  p-logos">
                <img src={CBE} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Partners;
