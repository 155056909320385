// frontendShowroomService.js
const api_url = process.env.REACT_APP_API_URL;

const getAllAgroShowroomCloneProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/clone/products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro showroom clone products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all agro showroom clone products:",
      error.message
    );
    throw error;
  }
};
const updateAgroShowroomCloneProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/clone/product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update agro showroom clone product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating agro showroom clone product:", error.message);
    throw error;
  }
};
const deleteAgroShowroomCloneProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/clone/product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete showroom clone product: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error deleting showroom clone product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const agroShowroomShowroomCloneService = {
  getAllAgroShowroomCloneProducts,
  updateAgroShowroomCloneProduct,
  deleteAgroShowroomCloneProduct,
};

export default agroShowroomShowroomCloneService;
