import React, { useState, useEffect } from "react";
import { Chart } from "chart.js/auto";

const ReportDoughnutChart = ({ data }) => {
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const processData = () => {
      const categoryCounts = {};
      // Loop through the data and count categories for the last 6 months with the date 23
      data.forEach((item) => {
        const date = new Date(item.date);
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Check if the date is 23 and it's within the last 6 months
        const isDateValid =
          day === 23 &&
          new Date(year, month + 1) >=
            new Date(new Date().setMonth(new Date().getMonth() - 6));

        if (isDateValid) {
          if (categoryCounts[item.categories]) {
            categoryCounts[item.categories]++;
          } else {
            categoryCounts[item.categories] = 1;
          }
        }
      });

      return categoryCounts;
    };

    const chartData = processData();

    if (!chartInstance) {
      const ctx = document
        .getElementById("reportdoughnutChart")
        .getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: Object.keys(chartData),
          datasets: [
            {
              label: "Category Counts",
              data: Object.values(chartData),
              backgroundColor: [
                "#ff6384",
                "#36a2eb",
                "#ffcd56",
                "#ff9f40",
                "#4bc0c0",
                "#F4A900",
                "#9966ff",
              ],
            },
          ],
        },
      });
      setChartInstance(newChartInstance);
    } else {
      chartInstance.data.labels = Object.keys(chartData);
      chartInstance.data.datasets[0].data = Object.values(chartData);
      chartInstance.update();
    }
  }, [data, chartInstance]);

  return <canvas id="reportdoughnutChart"></canvas>;
};
export default ReportDoughnutChart;
