import { useState, useEffect } from "react";
import employeeProfileService from "../../../services/employee_profile.service";
import employeeService from "../../../services/employee.service";
import AgroEmployeeActivityService from "../../../services/employee_activity.service";
import AgroEmployeeReportService from "../../../services/agro_employee_report.service";
import vetEmployeeReportService from "../../../services/vet_employee_report.service";

export const useEmployeeProfile = () => {
  const [employeeProfile, setEmployeeProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  const employeeId = localStorage.getItem("EmployeeID");

  useEffect(() => {
    const fetchEmployeeProfileData = async () => {
      try {
        const response =
          await employeeProfileService.getProfilePictureByEmployeeId(
            employeeId
          );
        if (response.ok) {
          setEmployeeProfile(await response.blob());
        } else {
          setAPIError(`Error fetching employee profile: ${response.status}`);
        }
      } catch (error) {
        setAPIError(`Error fetching employee profile: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeProfileData();
    // Set up interval for fetching data every 5 seconds

    return () => {
      // Cleanup function
    };
  }, [employeeId]);

  return { employeeProfile, isLoading, APIError };
};

export const useEmployeeActivity = () => {
  const [employeeActivity, setEmployeeActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  const employeeId = localStorage.getItem("EmployeeID");
  useEffect(() => {
    const fetchEmployeeActivityData = async () => {
      try {
        const response =
          await AgroEmployeeActivityService.getActivityByEmployeeID(employeeId);
        if (response.status === "success") {
          setEmployeeActivity(response.data);
        } else {
          setAPIError(`Error fetching employee activity: ${response.status}`);
        }
      } catch (error) {
        setAPIError(`Error fetching employee activity: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeActivityData();

    return () => {
      // Cleanup function
    };
  }, [employeeId]);

  return { employeeActivity, isLoading, APIError };
};
export const useOthersEmployeesActivity = () => {
  const [otheremployeeActivity, setOtherEmployeesActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  const employeeId = localStorage.getItem("EmployeeID");

  useEffect(() => {
    const fetchOthersEmployeesActivityData = async () => {
      try {
        const response =
          await AgroEmployeeActivityService.getOthersActivityByEmployeeID(
            employeeId
          );
        if (response.status === 200) {
          setOtherEmployeesActivity(await response.json());
        } else {
          setAPIError(
            `Error fetching other employees activity: ${response.status}`
          );
        }
      } catch (error) {
        setAPIError(
          `Error fetching other employees activity: ${error.message}`
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchOthersEmployeesActivityData();

    return () => {
      // Cleanup function
    };
  }, [employeeId]);

  return { otheremployeeActivity, isLoading, APIError };
};
export const useAllEmployeesActivity = () => {
  const [allEmployeesActivity, setAllEmployeesActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);

  useEffect(() => {
    const fetchAllEmployeesActivityData = async () => {
      try {
        const response = await AgroEmployeeActivityService.getAllActivitys();
        if (response.status === "success") {
          setAllEmployeesActivity(response.data);
        } else {
          setAPIError(
            `Error fetching all employees activity: ${response.status}`
          );
        }
      } catch (error) {
        setAPIError(`Error fetching all employees activity: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllEmployeesActivityData();
    return () => {
      // Cleanup function
    };
  }, []);

  return { allEmployeesActivity, isLoading, APIError };
};
export const useAllEmployeesReport = () => {
  const [allEmployeesReport, setAllEmployeesReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);

  useEffect(() => {
    const fetchAllEmployeesReportData = async () => {
      try {
        const response = await employeeService.getAllEmployeeReports();
        if (response.status === "success") {
          setAllEmployeesReport(response.data);
        } else {
          setAPIError(
            `Error fetching all employees report: ${response.status}`
          );
        }
      } catch (error) {
        setAPIError(`Error fetching all employees report: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllEmployeesReportData();

    return () => {
      // Cleanup function
    };
  }, []);

  return { allEmployeesReport, setAllEmployeesReport, isLoading, APIError };
};
export const useReminders = () => {
  const [reminders, setReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  const employeeId = localStorage.getItem("EmployeeID");

  useEffect(() => {
    const fetchRemindersData = async () => {
      try {
        const response =
          await AgroEmployeeReportService.getRemindersByEmployeeID(employeeId);
        if (response.status === "success") {
          setReminders(response.data);
        } else {
          setAPIError(`Error fetching reminders: ${response.status}`);
        }
      } catch (error) {
        setAPIError(`Error fetching reminders: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRemindersData();
    // Set up interval for fetching data every 5 seconds
    return () => {
      // Cleanup function
    };
  }, [employeeId]);

  return { reminders, setReminders, isLoading, APIError };
};
export const useVetReminders = () => {
  const [vetreminders, setVetReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [APIError, setAPIError] = useState(null);
  const employeeId = localStorage.getItem("EmployeeID");
  useEffect(() => {
    const fetchVetRemindersData = async () => {
      try {
        const response =
          await vetEmployeeReportService.getRemindersByEmployeeID(employeeId);
        if (response.status === "success") {
          setVetReminders(response.data);
        } else {
          setAPIError(`Error fetching vet reminders: ${response.status}`);
        }
      } catch (error) {
        setAPIError(`Error fetching vet reminders: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVetRemindersData();

    return () => {
      // Cleanup function
    };
  }, [employeeId]);

  return { vetreminders, setVetReminders, isLoading, APIError };
};
