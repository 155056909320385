import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  label: {
    fontFamily: "Courier-Bold",
  },
});

const InvoiceNo = ({ Info }) => (
  <Fragment>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Date: {Info.currentDate}</Text>
    </View>
  </Fragment>
);

export default InvoiceNo;
