import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import feedbackService from "../../../../services/survey.service";
import SurveyNoticeMessage from "../../../components/Messages/SurveyNoticeMessage";

function SurveyChecker() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Introduce isLoading state
  const EmployeeId = localStorage.getItem("EmployeeID");
  const [submissionStatus, setSubmissionStatus] = useState(null);

  useEffect(() => {
    const checkSurveyStatus = async () => {
      try {
        // Fetch survey submission status from feedbackService
        const surveyStatus = await feedbackService.checkSurveyStatus(
          EmployeeId
        );
        setSubmissionStatus(surveyStatus.message);
        setIsLoading(false); // Set isLoading to false once data is loaded
        if (surveyStatus.message === "You have to submit now") {
          // Redirect to survey page if the survey for the current month is not submitted
          navigate("/dashboard/employee/survey");
        }
      } catch (error) {
        console.error("Error checking survey status:", error);
        setIsLoading(false); // Set isLoading to false in case of error
        // Handle error if needed
      }
    };

    // Call the function to check survey status when the component mounts
    checkSurveyStatus();

    // No need for dependency array here, we want this effect to run only once when the component mounts
  }, []);

  // Render the SurveyNoticeMessage only when isLoading is false
  return (
    <>
      {!isLoading &&
        submissionStatus !== "You have to submit now" &&
        submissionStatus !== "feedback due" &&
        submissionStatus !== "feedback already submitted" &&
        submissionStatus !== "false" && (
          <div className="mt-3">
            <SurveyNoticeMessage submissionStatus={submissionStatus} />
          </div>
        )}
    </>
  );
}

export default SurveyChecker;
