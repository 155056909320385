import { useState, useEffect } from "react";

const Typewriter = ({ text, delay, infinite }) => {
  const formatResponse = (message) => {
    // Check if message is undefined or null
    if (message === undefined || message === null) {
      return ""; // Return empty string
    }

    message = message.replace("undefined", "");

    // Apply other formatting if needed
    return message
      ? message
          .replace(/^\* (.*)$/gm, "<li>$1</li>")
          .replace(/\*\*(.*?)\*\*/g, "<h5 class='mt-4'>$1</h5>")
          .replace(/<\/li>\s*<li>/g, "</li>")
          .replace(/\*(.*?)\*/g, "<li>$1</li>")
          .replace(/^\*(.*?)$/gm, "<li>$1</li>")
      : "";
  };

  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex <= text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      setCurrentIndex(0);
      setCurrentText("");
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return (
    <span dangerouslySetInnerHTML={{ __html: formatResponse(currentText) }} />
  );
};

export default Typewriter;
