import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useEmployeeActivity } from "../../APIs/EmployeeAPI";

const ContributionBarChart = ({ width, height }) => {
  const chartRef = useRef(null);
  const { employeeActivity } = useEmployeeActivity();

  useEffect(() => {
    if (!chartRef.current || !employeeActivity) return;

    // Filter employeeActivity for the current month
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
    const currentYear = currentDate.getFullYear();
    const currentMonthActivity = employeeActivity.filter((item) => {
      const itemDate = new Date(item.created_at);
      return (
        itemDate.getMonth() + 1 === currentMonth &&
        itemDate.getFullYear() === currentYear
      );
    });

    const colorMapping = {
      Poor: "#ff0303",
      Fair: "#FFFF00",
      Remarkable: "#1d1dd1",
      Good: "#00FF00",
    };

    const statusLengths = currentMonthActivity.reduce((acc, curr) => {
      acc[curr.status] = (acc[curr.status] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(statusLengths);
    const lengths = Object.values(statusLengths);

    // Find maximum length
    const maxLength = Math.max(...lengths);

    // Add padding of 5 to the maximum length
    const paddedMax = maxLength + 1;

    const chartConfig = {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Length",
            data: lengths,
            backgroundColor: labels.map((status) => colorMapping[status]),
            borderWidth: 2, // Remove square background
            borderColor: "gray",
            borderRadius: "50", // Add border radius
          },
        ],
      },
      options: {
        indexAxis: "y", // Display bars vertically
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Length",
            },
            max: paddedMax, // Set maximum value with padding
          },
          y: {
            title: {
              display: true,
              text: "Status",
            },
          },
        },
      },
    };

    const myChart = new Chart(chartRef.current, chartConfig);

    // Clean up
    return () => {
      myChart.destroy();
    };
  }, [employeeActivity]);

  return (
    <div>
      <canvas ref={chartRef} id="ContributionBarChart"></canvas>
    </div>
  );
};

export default ContributionBarChart;
