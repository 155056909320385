import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => emailjs.init("VSsb0lEbzoN-5N2vf"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_q2kkbq1";
    const templateId = "template_rfacvgq";
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        name: name,
        email: email,
        message: message,
      });
      alert("Email successfully sent. Check your inbox.");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section id="contact" className="mainconatct-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center mb-5">
            <h1 className="mb-4 d5 text-center Titel_con">
              <span className="tspan">Contact</span> us
            </h1>
            <hr className="w-50 mx-auto mb-xl-9 border-info-subtle" />
          </div>
        </div>
        <div className="row justify-content-center mainconatct-container">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-4">Get in touch</h3>
                    <div id="form-message-warning" className="mb-4"></div>
                    <form
                      id="contactForm"
                      name="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="contact-input-labels"
                              htmlFor="name"
                            >
                              Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="contact-input-labels"
                              htmlFor="email"
                            >
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <label className="contact-input-labels" htmlFor="#">
                              Message
                            </label>
                            <textarea
                              name="message"
                              className="form-control"
                              id="message"
                              cols="30"
                              rows="5"
                              placeholder="Message"
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-md btn-outline-danger"
                              disabled={loading}
                            >
                              {loading ? "Sending..." : "Send Message"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className=" col-lg-4 col-md-5 d-flex align-items-stretch">
                  <div className="info-wrap w-100 p-md-5 p-4">
                    <h3>Let's get in touch</h3>
                    <p className="mb-4">
                      We're open for any suggestion.
                    </p>
                    <div className="dbox w-100 d-flex align-items-start">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Address:</span> On the way to industrial
                          college, 150m form the main road
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Phone:</span>{" "}
                          <Link
                            className="contact-links"
                            to="tel://+251911636597"
                          >
                            +251 911636597
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Email:</span>
                          <Link
                            className="contact-links"
                            to="mailto:info@aosscenter.com"
                          >
                            info@aosscenter.com
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-globe"></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Website</span>{" "}
                          <Link className="contact-links">
                            www.aosscenter.com
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
