import React, { useState, useEffect } from "react";

const DailyQuote = () => {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await fetch("https://type.fit/api/quotes");
        const data = await response.json();
        const randomIndex = Math.floor(Math.random() * data.length);
        const newQuote = data[randomIndex].text;
        const authorString = data[randomIndex].author;
        // Extract name before the comma
        const newAuthor = authorString.split(",")[0].trim();
        const storedData = JSON.parse(localStorage.getItem("dailyQuoteData"));

        if (storedData && storedData.date === new Date().toLocaleDateString()) {
          // Use the stored quote if it's from today
          setQuote(storedData.quote);
          setAuthor(storedData.author);
          return;
        }

        // Otherwise, fetch a new quote
        setQuote(newQuote);
        setAuthor(newAuthor);

        const newData = {
          quote: newQuote,
          author: newAuthor,
          date: new Date().toLocaleDateString(),
        };
        localStorage.setItem("dailyQuoteData", JSON.stringify(newData));
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };

    fetchQuote();
  }, []);

  return (
    quote && (
      <div className="justify-content-center main-qoute-con dsmnone">
        <cite className="qoute-con q">
          <q className="the-q">{quote}</q>
          <p className="qoute-author">- {author}</p>
        </cite>
      </div>
    )
  );
};
export default DailyQuote;
