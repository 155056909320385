// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to sell an agricultural product
const OutAgroProduct = async (OutProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(OutProductData),
  };
  try {
    const response = await fetch(
      `${api_url}/api/store/agro/out-product`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};
const getOutedProductsByIds = async (ProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify({ ids: ProductData }),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/agro/outed-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro store outed products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all agro store outed products:",
      error.message
    );
    throw error;
  }
};
// Function to get all outed agricultural products
const getAllOutedAgroProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/agro/outed-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all outed agro products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all outed agro products:", error.message);
    throw error;
  }
};


// Function to update outed agricultural product
const updateOutedAgroProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/agro/outed-product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update outed agro product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating outed agro product:", error.message);
    throw error;
  }
};

const deleteOutedAgroProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/agro/outed-product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(
        `Failed to delete outed agro product: ${response.status}`
      );
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting outed agro product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const agroStoreOutedProductsService = {
  OutAgroProduct,
  getAllOutedAgroProducts,

  updateOutedAgroProduct,
  deleteOutedAgroProduct,
  getOutedProductsByIds,
};

export default agroStoreOutedProductsService;
