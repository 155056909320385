import React, { useEffect, useState } from "react";
import VetcustomerService from "../../../../../services/vet_customer.service";
import CustomerList from "../../../../components/Customer/CustomerList";

function VetCustomerList() {
  const [agrocustomer, setAgroCustomer] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await VetcustomerService.getAllCustomers();

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.length !== 0) {
            setAgroCustomer(data.data);
          }
        } else {
          setIsLoading(false);
          console.error(`Error fetching customers: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data (whether successful or not)
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (customerId) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        const response = await VetcustomerService.deleteCustomer(customerId);

        if (response.ok) {
          setAgroCustomer((prevCustomers) =>
            prevCustomers.filter(
              (customer) => customer.customer_id !== customerId
            )
          );
          setSuccessMessage("Customer deleted successfully!");
        } else {
          setError(`Error deleting customer: ${response.status}`);
        }
      } catch (error) {
        setError("Error deleting customer:", error);
      }
    }
  };
  const handleEditCustomer = async (event, CustomerId, updatedData) => {
    event.preventDefault();

    try {
      const response = await VetcustomerService.editCustomer(
        CustomerId,
        updatedData
      );
      if (response.status === "success") {
        setTimeout(() => {
          window.location.href = "/dashboard/showroom/vet/customer";
        }, 2000);
      }
    } catch (error) {
      console.error("Error editing customer:", error);
      setError("Error editing customer. Please try again.");
    }
  };
  return (
    <CustomerList
      customer={agrocustomer}
      successMessage={successMessage}
      handleDelete={handleDelete}
      Error={Error}
      isLoading={isLoading}
      handleEditCustomer={handleEditCustomer}
      service={"Vet"}
    />
  );
}

export default VetCustomerList;
