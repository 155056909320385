//  Func to calculate today revenue
export const calculateTodayRevenue = (items, dateKey, priceKey) => {
  if (!items || !Array.isArray(items)) return 0;

  // Get today's date in the format YYYY-MM-DD
  const todayDate = new Date().toISOString().split("T")[0];

  // Filter items for today
  const todayItems = items.filter(
    (item) => item[dateKey].split("T")[0] === todayDate
  );

  // Calculate total revenue for today
  return todayItems.reduce(
    (todayRevenue, item) => todayRevenue + parseFloat(item[priceKey]),
    0
  );
};

// To calculate Total revenue of data
export const calculateTotalRevenue = (items, priceKey) => {
  if (!items || !Array.isArray(items)) return 0;

  // Calculate total revenue for all items
  return items.reduce(
    (totalRevenue, item) => totalRevenue + parseFloat(item[priceKey]),
    0
  );
};

// calculate current month revenue
export const calculateCurrentMonthRevenue = (items, dateKey, priceKey) => {
  if (!items || !Array.isArray(items)) return 0;

  // Get the current month and year
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // Months are zero-indexed
  const currentYear = today.getFullYear();

  // Filter items for the current month
  const currentMonthItems = items.filter((item) => {
    const itemDate = new Date(item[dateKey]);
    return (
      itemDate.getMonth() + 1 === currentMonth &&
      itemDate.getFullYear() === currentYear
    );
  });

  // Calculate total revenue for the current month
  return currentMonthItems.reduce(
    (currentMonthRevenue, item) =>
      currentMonthRevenue + parseFloat(item[priceKey]),
    0
  );
};

// calculate all sold products qty
export const calculateTotalProductQuantity = (items, quantityKey) => {
  if (!items || !Array.isArray(items)) return 0;

  // Calculate total quantity for all items
  return items.reduce(
    (totalQuantity, item) =>
      totalQuantity + parseInt(item[quantityKey] || 0, 10),
    0
  );
};
