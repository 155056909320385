import React, { useEffect, useState } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import ProductFormFields from "../../../../components/Forms/AddProductFormFields";
import agroShowroomService from "../../../../../services/agro_showroom_product.service";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";
import { useAuth } from "../../../../../Context/AuthContext";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function AddProduct() {
  const { agroproducts, APIError } = useAgroShowroomProducts();
  const { employee } = useAuth();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [priceChangeConfirmed, setPriceChangeConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const EmployeeBranch = employee?.employee_branch;
  const [agroShowroomProductData, setAgroShowroomProductData] = useState({
    product_name: "",
    product_type: "",
    product_unit: "",
    product_quantity: "",
    product_unit_price: "",
    usage: "",
    branch: "",
    expiration_date: "",
  });
  const [isProductCreated, setIsProductCreated] = useState(false);
  useEffect(() => {
    setAgroShowroomProductData((prevData) => ({
      ...prevData,
      branch: EmployeeBranch || "",
    }));
  }, [EmployeeBranch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Validate inputs
    if (
      !agroShowroomProductData.product_name ||
      !agroShowroomProductData.product_type ||
      !agroShowroomProductData.product_unit ||
      !agroShowroomProductData.product_quantity ||
      !agroShowroomProductData.product_unit_price ||
      !agroShowroomProductData.expiration_date
    ) {
      setError("Please fill out all fields.");
      setLoading(false);
      return;
    }
    // Validate product_quantity
    if (isNaN(Number(agroShowroomProductData.product_quantity))) {
      setError("Product Quantity must be a valid number.");
      setLoading(false);
      return;
    }

    // Validate expiration date
    const currentDate = new Date();
    const selectedDate = new Date(agroShowroomProductData.expiration_date);
    if (selectedDate < currentDate) {
      setError(
        "Expiration date must be greater than or equal to the current date."
      );
      setLoading(false);
      return;
    }

    // Check if the price change has been confirmed
    if (!priceChangeConfirmed) {
      // Validate product_unit_price only if the price change hasn't been confirmed
      const selectedProduct = agroproducts.find((product) => {
        return (
          product.product_name === agroShowroomProductData.product_name &&
          product.branch === agroShowroomProductData.branch &&
          product.product_unit === agroShowroomProductData.product_unit
        );
      });

      if (selectedProduct) {
        const enteredPrice = parseFloat(
          agroShowroomProductData.product_unit_price
        );
        const existingPrice = parseFloat(selectedProduct.product_unit_price);

        if (enteredPrice !== existingPrice) {
          const priceDifference = enteredPrice - existingPrice;
          const message = `Warning: A price change has occurred!
        \nProduct Name: ${selectedProduct.product_name}
        \nCurrent Product Unit Price: Birr ${existingPrice}
        \nEntered Product Unit Price: Birr ${enteredPrice}
        \nPrice Difference: Birr ${priceDifference}
        \nBefore clicking 'Confirm Price Change,' please ensure that you decide to sell the product at the new price of Birr ${enteredPrice} or update the price in the showroom.`;
          setConfirmationMessage(message); // Set confirmation message
          setNewPrice(enteredPrice); // Set new price
          setShowConfirmationModal(true); // Show confirmation modal
          setLoading(false);
          return;
        }
      }
    }

    // If no validation errors, proceed to submit the product
    try {
      const response = await agroShowroomService.addAgroShowroomProduct(
        agroShowroomProductData
      );

      if (response.status === "true") {
        // Provide user feedback about success
        setLoading(false);
        setSuccessMessage("Product Added successfully.");
        // Redirect after a delay
        setTimeout(() => {
          window.location.href = "/dashboard/showroom/agro/product";
        }, 4000);
      } else {
        // Handle other response statuses
        setError("Failed to add product. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding product:", error);
      setError("Error adding product. Please try again.");
    }
  };

  const handleConfirmPriceChange = () => {
    // Update the product unit price with the new price
    setAgroShowroomProductData({
      ...agroShowroomProductData,
      product_unit_price: newPrice,
    });

    // Close the confirmation modal
    setShowConfirmationModal(false);
    setPriceChangeConfirmed(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgroShowroomProductData({ ...agroShowroomProductData, [name]: value });
  };

  const handleDateChange = (date) => {
    setAgroShowroomProductData((prevData) => ({
      ...prevData,
      expiration_date: date instanceof Date ? date : new Date(date),
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setAgroShowroomProductData({
      ...agroShowroomProductData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleCreateOption = (inputValue, name) => {
    setAgroShowroomProductData({
      ...agroShowroomProductData,
      [name]: inputValue,
    });
  };

  const handleCancelModal = () => {
    setShowConfirmationModal(false);
  };

  const productOptions = agroproducts.map((agroproduct) => ({
    value: agroproduct.product_name,
    label: agroproduct.product_name,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 55,
      minHeight: 55,
    }),
  };

  return (
    <Dashboard>
      <div className=" container-fluid col-sm-12 col-xl-6  ">
        <div className="bg-dark rounded h-100 p-4">
          <h4 className="mb-4 text-info ">Add Product</h4>
          {APIError && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <ErrorIcon className="bi flex-shrink-0 me-2" />
              <div>{APIError}</div>
            </div>
          )}
          {successMessage && (
            <div
              className="alert alert-success d-flex align-items-center "
              role="alert"
            >
              <CheckCircleIcon className="bi flex-shrink-0 me-2" />
              <div>{successMessage}</div>
            </div>
          )}
          {Error && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <ErrorIcon className="bi flex-shrink-0 me-2" />
              <div>{Error}</div>
            </div>
          )}
          <ConfirmationModal
            show={showConfirmationModal}
            message={confirmationMessage}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmPriceChange}
          />
          <form onSubmit={handleSubmit} className="text-start">
            <ProductFormFields
              productOptions={productOptions}
              productData={agroShowroomProductData}
              handleDateChange={handleDateChange}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleCreateOption={handleCreateOption}
              customStyles={customStyles}
              isProductCreated={isProductCreated}
              setIsProductCreated={setIsProductCreated}
            />
            {loading ? (
              <button
                disabled
                className="btn btn-lg pm w-100 mt-2 p-3 text-center"
              >
                <div className="btn-loader"></div>
              </button>
            ) : (
              <button type="submit" className="btn btn-lg pm w-100 mt-2 p-3">
                {priceChangeConfirmed
                  ? "I agreed to the price change"
                  : "Add Product"}
              </button>
            )}
          </form>
        </div>
      </div>
    </Dashboard>
  );
}

export default AddProduct;
