// frontendShowroomService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to add an agricultural showroom product
const addAgroShowroomProduct = async (agroShowroomProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(agroShowroomProductData),
  };
  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/add-product`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to add agro showroom product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error adding agro showroom product:", error.message);
    throw error;
  }
};

// Function to get all agricultural showroom products
const getAllAgroShowroomProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro showroom products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all agro showroom products:", error.message);
    throw error;
  }
};



const getPendingProducts = async (branch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/pending-products/${branch}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro pending products products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all agro pending products products:",
      error.message
    );
    throw error;
  }
};

// Function to update sold agricultural product
const updateAgroShowroomProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update agro showroom product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating agro showroom product:", error.message);
    throw error;
  }
};
const deleteAgroShowroomProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete showroom product: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting showroom product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const agroShowroomShowroomService = {
  addAgroShowroomProduct,
  getAllAgroShowroomProducts,
  updateAgroShowroomProduct,
  deleteAgroShowroomProduct,
  getPendingProducts,
};

export default agroShowroomShowroomService;
