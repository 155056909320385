import React, { useEffect, useState } from "react";
import ScannerService from "../../../services/barcode-scanne.service";
import { Link } from "react-router-dom";

const ProductBarcodes = () => {
  const [products, setProducts] = useState({});
  const [filteredProducts, setFilteredProducts] = useState({});
  const [filters, setFilters] = useState({
    branch: "",
    productType: "",
    productName: "",
  });
  const [barcodes, setBarcodes] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await ScannerService.productBarcode();
        if (response.ok) {
          const data = await response.json();
          setProducts(data.data);
          setFilteredProducts(data.data);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = {};

      Object.keys(products).forEach((branch) => {
        filtered[branch] = {};

        Object.keys(products[branch]).forEach((productType) => {
          filtered[branch][productType] = products[branch][productType].filter(
            (product) => {
              const branchFilterMatch =
                filters.branch === "" ||
                branch.toLowerCase().includes(filters.branch.toLowerCase());

              const productTypeFilterMatch =
                filters.productType === "" ||
                productType
                  .toLowerCase()
                  .includes(filters.productType.toLowerCase());

              const productNameFilterMatch =
                filters.productName === "" ||
                product.product_name
                  .toLowerCase()
                  .includes(filters.productName.toLowerCase());

              return (
                branchFilterMatch &&
                productTypeFilterMatch &&
                productNameFilterMatch
              );
            }
          );
        });
      });

      setFilteredProducts(filtered);
    };

    applyFilters();
  }, [filters, products]);

  useEffect(() => {
    const fetchBarcodes = async () => {
      const newBarcodes = {};
      for (const branch of Object.keys(filteredProducts)) {
        newBarcodes[branch] = {};
        for (const productType of Object.keys(filteredProducts[branch])) {
          newBarcodes[branch][productType] = {};
          for (const product of filteredProducts[branch][productType]) {
            const barcodeUrl = await getBarcodeSrc(product.product_id);
            newBarcodes[branch][productType][product.product_id] = barcodeUrl;
          }
        }
      }
      setBarcodes(newBarcodes);
    };

    fetchBarcodes();
  }, [filteredProducts]);

  const getBarcodeSrc = async (productId) => {
    try {
      const response = await ScannerService.genAndGetBarcode(productId);
      if (response.ok) {
        const data = await response.blob();
        return URL.createObjectURL(data);
      } else {
        console.error(`Failed to get barcode for product ${productId}`);
        return null;
      }
    } catch (error) {
      console.error(`Error getting barcode for product ${productId}:`, error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "8%",
      }}
    >
      <div style={{ marginBottom: "20px", marginTop: "1px" }}>
        <Link to="/dashboard/employee/personal/account">Go to Dashboard</Link>

        <input
          type="text"
          placeholder="Filter by branch"
          name="branch"
          value={filters.branch}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Filter by product type"
          name="productType"
          value={filters.productType}
          onChange={handleInputChange}
        />
        <input
          type="text"
          placeholder="Filter by product name"
          name="productName"
          value={filters.productName}
          onChange={handleInputChange}
        />
      </div>

      {Object.keys(filteredProducts).map((branch) => (
        <div key={branch} style={{ marginBottom: "20px" }}>
          <h6>Branch: {branch}</h6>
          {Object.keys(filteredProducts[branch]).map((productType) => (
            <div key={productType} style={{ marginBottom: "90px" }}>
              <h6>{productType}s</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {filteredProducts[branch][productType].map((product) => (
                  <div
                    key={product.product_id}
                    style={{ marginRight: "20px", marginBottom: "20px" }}
                  >
                    <h5 className="">{product.product_name}</h5>
                    <img
                      src={
                        barcodes[branch]?.[productType]?.[product.product_id] ||
                        ""
                      }
                      alt={`Barcode for ${product.product_name}`}
                      style={{ width: "330px", height: "auto" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ProductBarcodes;
