import React from "react";
import Center1 from "../../../assets/images/center12.png";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
function DarkVariantExample() {
  return (
    <div
      id="Home"
      className="black-blur"
      style={{ display: "block", width: "100%" }}
    >
      <Carousel data-bs-theme="light">
        <Carousel.Item interval={2900}>
          <img className="d-block w-100 fimg" src={Center1} />
          <Carousel.Caption>
            <h1 className="heroh1s text-center d-sm-none d-md-none d-lg-block">
              Buee Agricultural One Stop Shop Center
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <h1 className="heroh1sMobile d-lg-none  text-center">
        Buee Agricultural One Stop Shop Center
      </h1>
    </div>
  );
}

export default DarkVariantExample;
