import React, { useEffect, useState } from "react";
import EmployeeReminder from "../../../../components/Employee/EmployeeReminder";
import AgroEmployeeReportService from "../../../../../services/agro_employee_report.service";
import { useReminders } from "../../../../components/APIs/EmployeeAPI";

function Reminder() {
  const [successMessage, setSuccessMessage] = useState("");
  const { reminders, setReminders, isLoading } = useReminders();
  const DELETEreminder = async (reportId) => {
    try {
      const response = await AgroEmployeeReportService.DELETEreminder(reportId);

      if (response.status === 200) {
        const responseData = await response.json(); // Parse response JSON
        setReminders((prevData) =>
          prevData.filter((item) => item.report_id !== reportId)
        );
        setSuccessMessage(responseData.message); // Display server message

        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        throw new Error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };

  return (
    <div>
      <EmployeeReminder
        data={reminders}
        DELETEreminder={DELETEreminder}
        successMessage={successMessage}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Reminder;
