import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../../Context/AuthContext";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import employeeService from "../../../services/employee.service";
function OutProducts({
  products,
  APIError,
  handleOutProducts,
  branchs,
  service,
  NavTo,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [thirdSegment, setthirdSegment] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [serverError, setServerError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { employee } = useAuth();
  const [isBranchSelected, setIsBranchSelected] = useState(false);
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  useEffect(() => {
    const segments = window.location.pathname.split("/");
    if (segments.length >= 4) {
      const thirdSegment = segments[3];
      setthirdSegment(thirdSegment); // This will log 'agro' for the example URL "/dashboard/store/agro/out-products"
    }
  }, []);
  const handleSelectProduct = (productId) => {
    // Add the selected product to the list of selected products
    const selectedProduct = products.find(
      (product) => product.product_id === productId
    );
    setSelectedProducts([...selectedProducts, selectedProduct]);
  };

  const handleSellProducts = async () => {
    if (!isBranchSelected) {
      setServerError("Please select a branch.");
      return;
    }
    // Get the branch selected for the first product
    const firstProductBranch = selectedProducts[0].ToBranch;
    // Check if the branch selected for each product matches the branch selected for the first product
    const areBranchesEqual = selectedProducts.every(
      (product) => product.ToBranch === firstProductBranch
    );

    // If branches are not equal, display error and return
    if (!areBranchesEqual) {
      setServerError("You can only out products to one branch at a time.");
      return;
    }
    try {
      // Get the current date and time
      const currentDate = new Date();
      // Map selected products to send only the specified properties
      const productsToSend = selectedProducts.map((product) => ({
        product_id: product.product_id,
        product_name: product.product_name,
        product_type: product.product_type,
        product_unit: product.product_unit,
        product_quantity: product.OutedQuantity,
        product_unit_price: product.product_unit_price,
        branch: product.ToBranch,
        from_branch: employee?.employee_branch,
      }));

      const response = await handleOutProducts(productsToSend);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setTimeout(() => {
          localStorage.setItem(
            "hash_ops_data",
            JSON.stringify({
              products: response.data,
              Moreinfo: {
                currentDate: currentDate.toLocaleDateString(),
                currentTime: currentDate.toLocaleTimeString(),
                Service: thirdSegment,
                EmployeeName: `${employee?.employee_first_name} ${employee?.employee_last_name}`,
                DeliverBranch: employee?.employee_branch,
                ToBranch: selectedProducts[0].ToBranch,
              },
            })
          );

          window.location.href = NavTo;
        }, 3000);
      } else {
        setServerError(response.error);
      }
    } catch (error) {
      setServerError(`Error: ${error}`);
    }
  };

  const handleRemoveProduct = (indexToRemove) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleCancelSearch = () => {
    setSearchInput("");
  };
  const filteredProducts = products
    ? products
        .filter((product) =>
          [
            "product_id",
            "product_name",
            "product_type",
            "product_unit",
            "product_unit_price",
          ].some((field) =>
            String(product[field])
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
        )
        .slice(0, 5)
    : [];
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  return (
    <Dashboard>
      <div>
        {serverError && <div className="alert alert-danger">{serverError}</div>}
        {APIError && <div className="alert alert-danger">{APIError}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className="tab-header  text-start">
          <div className="form-floating w-50 mt-3 mb-5">
            <input
              type="search"
              className="form-control bg- "
              id="floatingInput"
              placeholder="Search Products..."
              value={searchInput}
              onChange={handleSearch}
            />
            <label htmlFor="floatingInput">
              <SearchIcon className="me-1" />
              Search Products...
            </label>
          </div>
        </div>

        <div className="col-sm-12 col-xl-12">
          {searchInput && filteredProducts.length > 0 ? (
            <>
              <div className="bgcustom rounded h-100 p-4">
                <div className="d-flex justify-content-between align-items-center ">
                  <h4 className="mb-4 text-start text-white">
                    Available Products
                  </h4>
                  <Link
                    onClick={handleCancelSearch}
                    className="mb-4 badge rounded-pill bg-warning text-black   text-decoration-none "
                  >
                    Cancel
                  </Link>
                </div>

                <table className="table table-borderless table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit </th>
                      <th scope="col">Product Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.map((product) => (
                      <tr key={product.product_id}>
                        <th scope="row">{product.product_id}</th>
                        <td>{product.product_name}</td>
                        <td>{product.product_type}</td>
                        <td>{product.product_unit}</td>
                        <td>{product.product_quantity}</td>
                        <td>{product.product_unit_price}</td>
                        <td>
                          <button
                            className={` fnbtn ${
                              selectedProducts.some(
                                (selectedProduct) =>
                                  selectedProduct.product_id ===
                                  product.product_id
                              )
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectProduct(product.product_id)
                            }
                            disabled={
                              selectedProducts.some(
                                (selectedProduct) =>
                                  selectedProduct.product_id ===
                                  product.product_id
                              )
                                ? "disabled"
                                : ""
                            }
                          >
                            {selectedProducts.some(
                              (selectedProduct) =>
                                selectedProduct.product_id ===
                                product.product_id
                            ) ? (
                              <DoneAllIcon className="fns-selected" />
                            ) : (
                              <TaskAltIcon className="fns" />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="col-sm-12 col-xl-12 text-white">
              {searchInput && (
                <div className="alert custom-alert-warning">
                  <h6>Your search returned no matches.</h6>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="col-sm-12 col-xl-12">
          <div
            className={`${
              selectedProducts.length > 0 ? "bsecondary" : "bsecondaryns"
            } rounded h-100 p-4 mt-3 mb-5`}
          >
            <div className="d-flex justify-content-between">
              <h4 className="mb-4 text-start text-white">
                {" "}
                {selectedProducts.length > 0
                  ? "Selected Product(s)"
                  : "Please Select Product"}
              </h4>
              <button
                type="button"
                onClick={handleSellProducts}
                className="btn btn-lg btn-success mb-2"
                disabled={
                  !selectedProducts.every((product) => product.OutedQuantity) ||
                  !selectedProducts.length > 0
                }
              >
                Out Product(s)
              </button>
            </div>
            <div className="outps">
              {selectedProducts.map((selectedProduct, index) => (
                <table
                  key={index}
                  className="tbl-out table table-borderless table-dark text-start border-1"
                >
                  <thead>
                    <tr>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Enter Product QTY</th>
                      <th>
                        <Link
                          onClick={() => handleRemoveProduct(index)}
                          className="badge bg-danger text-decoration-none"
                        >
                          Remove item {index + 1}
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={selectedProduct.product_id}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={selectedProduct.product_name}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={selectedProduct.product_type}
                          disabled
                        />
                      </td>
                      <td>
                        <div className="input-group mb-3  text-black text-start">
                          <Select
                            options={branchs.map((branch) => ({
                              label: branch.city,
                              value: branch.city,
                            }))}
                            placeholder="Select Branch"
                            styles={customStyles}
                            value={
                              selectedProduct.ToBranch
                                ? {
                                    label: selectedProduct.ToBranch,
                                    value: selectedProduct.ToBranch,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              const updatedProducts = [...selectedProducts];
                              updatedProducts[index].ToBranch =
                                selectedOption.value;
                              setSelectedProducts(updatedProducts);
                              setIsBranchSelected(true);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-group mb-3 w-75">
                          <span className="input-group-text">QTY</span>
                          <input
                            type="text"
                            className="form-control w-50"
                            value={selectedProduct.OutedQuantity}
                            onChange={(e) => {
                              const updatedProducts = [...selectedProducts];
                              updatedProducts[index].OutedQuantity =
                                e.target.value;
                              setSelectedProducts(updatedProducts);
                            }}
                            required
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default OutProducts;
