import React, { useEffect, useState } from "react";
import {
  useAgroSoldProducts,
  useAgroChemicalSalesReport,
} from "../../../../components/APIs/FetchAPIs";
import agrocustomerService from "../../../../../services/agro_customer.service";
import Report from "../../../../components/Reports/Report";

function AgroReport() {
  const { agrosoldProducts } = useAgroSoldProducts();
  const { agroSalesReport } = useAgroChemicalSalesReport();
  const [agroCustomer, setAgroCustomer] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await agrocustomerService.getAllCustomers();

        if (response.ok) {
          const data = await response.json();
          setAgroCustomer(data.data);
        } else {
          console.error(`Error fetching customers: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Report
      Customers={agroCustomer}
      SalesReport={agroSalesReport}
      soldProducts={agrosoldProducts}
    />
  );
}

export default AgroReport;
