import React, { useEffect, useState } from "react";
import Weeklyavalable from "../Reports/weeklyavalable";
import {
  useVetSalesReport,
  useAgroChemicalSalesReport,
} from "../APIs/FetchAPIs";
import { useAuth } from "../../../Context/AuthContext";
import VetSalesReportService from "../../../services/vet_sales_report.service";
import AgroChemicalSalesReportService from "../../../services/agro_chemical_sales_report.service";
function WeeklyAvalableWidget() {
  const [vetSalesReport, setVetSalesReport] = useState([]);
  const [agroSalesReport, setAgroChemicalSalesReport] = useState([]);
  const { employee } = useAuth();
  const Veterinariancriteria = ["Veterinarian"];
  const Agronomistcriteria = [
    "Agronomist",
    "Environmental Manager",
    "Agricultural Engineer",
    "Environmental Engineer",
    "Irrigation Engineer",
    "Agri-Tech Specialist",
    "Agribusiness Manager",
    "Agricultural Marketing Specialist",
    "Agricultural Assistant",
    "HR Manager in Agriculture",
  ];
  const isVeterinarian =
    employee?.field_of_study &&
    Veterinariancriteria.includes(employee.field_of_study);
  const isAgronomist =
    employee?.field_of_study &&
    Agronomistcriteria.includes(employee.field_of_study);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        let response;
        if ([1].includes(employee.employee_role)) {
          response =
            await AgroChemicalSalesReportService.getAgroChemicalSalesReport();
        } else if ([2].includes(employee.employee_role)) {
          response = await VetSalesReportService.getVetSalesReport();
        }

        if (response) {
          if (response.status === "success") {
            if ([1].includes(employee.employee_role)) {
              setAgroChemicalSalesReport(response.data);
            } else if ([2].includes(employee.employee_role)) {
              setVetSalesReport(response.data);
            }
          } else {
            console.error(`Error fetching report: ${response.status}`);
          }
        } else {
          console.error("Empty response received from the server");
        }
      } catch (error) {
        console.error("Error fetching report:", error.message);
      }
    };

    fetchReport();

    const intervalId = setInterval(fetchReport, 120000);

    return () => clearInterval(intervalId);
  }, [employee.employee_role]);
  return (
    <div>
      <Weeklyavalable
        Report={
          isVeterinarian ? vetSalesReport : isAgronomist ? agroSalesReport : []
        }
      />
    </div>
  );
}

export default WeeklyAvalableWidget;
