import React, { useState, useEffect } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import { Link } from "react-router-dom";
import employeeService from "../../../../services/employee.service";
// import { useAuth } from "../../../../Context/AuthContext";
import GroupIcon from "@mui/icons-material/Group";
import VerifiedIcon from "@mui/icons-material/Verified";
import BlockIcon from "@mui/icons-material/Block";
import EditEmployeeForm from "./EditEmployee";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Employees() {
  const [successMessage, setSuccessMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const handleEdit = (employee) => {
    setEditingEmployee(employee);
  };

  const handleCancelEdit = () => {
    setEditingEmployee(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await employeeService.getAllEmployees();

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.length !== 0) {
            setEmployees(data.data);
          }
        } else {
          console.error(`Error fetching employees: ${response.status}`);
          setApiError(true);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleDelete = async (employeeId) => {
    if (window.confirm("Are you sure you want to delete this employee?")) {
      try {
        const response = await employeeService.deleteEmployee(employeeId);

        if (response.ok) {
          // Update the state by filtering out the deleted employee
          setEmployees((prevEmployees) =>
            prevEmployees.filter(
              (employee) => employee.employee_id !== employeeId
            )
          );
          setSuccessMessage("Employee deleted successfully");
        } else {
          console.error(`Error deleting employee: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting employee:", error);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const totalEmployees = employees.length;
  const activeEmployees = employees.filter(
    (employee) => employee.active_employee
  ).length;
  const inactiveEmployees = totalEmployees - activeEmployees;
  const filteredEmployees = employees
    ? employees.filter((employee) => {
        const fieldsToSearch = [
          "employee_first_name",
          "employee_last_name",
          "employee_email",
          "employee_phone",
          "employee_branch",
          "field_of_study",
          "employee_privilege",
          "company_role_name",
          "employee_salary",
        ];

        return fieldsToSearch.some((field) =>
          String(employee[field])
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];
  return (
    <Dashboard>
      {editingEmployee ? (
        // Render the EditEmployeeForm if in edit mode
        <EditEmployeeForm
          employeeId={editingEmployee.employee_id}
          onCancel={handleCancelEdit}
          editingEmployee={editingEmployee}
        />
      ) : (
        <>
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}{" "}
          <div className="container-fluid pt-4 px-4 text-end mb-5 mt-2">
            <div className="row g-4">
              <div className="col-sm-6 col-xl-4">
                <div className=" text-white ovr-7  rounded d-flex align-items-center justify-content-between p-4">
                  <i className="employee-icon">
                    <GroupIcon className="employee-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Employees</p>
                    <h6 className="mb-0">{totalEmployees}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="text-white rounded d-flex ovr-4 align-items-center justify-content-between p-4">
                  <i className=" employee-icon">
                    <VerifiedIcon className="employee-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Active Employees</p>
                    <h6 className="mb-0">{activeEmployees}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="text-white rounded d-flex ovr-10 align-items-center justify-content-between p-4">
                  <i className="employee-icon">
                    <BlockIcon className="employee-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Inactive Employees</p>
                    <h6 className="mb-0">{inactiveEmployees}</h6>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div className="table-responsive employees-tab mb-5 ">
            <div className="d-flex align-items-center mb-3">
              <div>
                <Link
                  className="btn btn-md btn-success mb-4 m-2"
                  to="/admin/add-employee"
                >
                  Add new employee
                </Link>
              </div>
              <div className=" mb-3 text-white">
                <input
                  type="search"
                  className="form-control w-100 bg-white"
                  placeholder="Search employee"
                  value={searchInput}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <table className="table table-striped table-dark">
              <thead className="thead-info mb-3">
                <tr className="text-white border-1 border-info  ">
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Active</th>
                  <th scope="col">FirstN</th>
                  <th scope="col">LastN</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Profetion</th>
                  <th scope="col">Privilege</th>
                  <th scope="col">Role</th>
                  <th scope="col">Salary</th>
                  <th scope="col">Last Seen</th>
                  <th scope="col">Added Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="align-items-center alert-warning">
                {!filteredEmployees.length && isLoading ? (
                  <tr>
                    <td colSpan="19" className="text-center">
                      <span className="loader"></span>
                    </td>
                  </tr>
                ) : (
                  filteredEmployees.map((employee) => {
                    const byteArray = new Uint8Array(
                      employee.employee_profile
                        ? employee.employee_profile.data
                        : null
                    );
                    const byteString = String.fromCharCode.apply(
                      null,
                      byteArray
                    );
                    const base64String = btoa(byteString);
                    return (
                      <tr
                        className="employees-table-tr align-middle p-0"
                        key={employee.employee_id}
                      >
                        <td className="text-center">
                          {" "}
                          {/* Center align */}
                          {employee.employee_profile ? (
                            <img
                              src={`data:image/jpeg;base64,${base64String}`}
                              alt="Employee"
                              style={{
                                alignItems: "center",
                                maxWidth: "65px",
                                maxHeight: "65px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <div className="position-relative">
                              <AccountCircleIcon className="matrialuiicon_profile text-warning rounded-circle " />
                            </div>
                          )}
                        </td>
                        <td className="text-center">{employee.employee_id}</td>
                        <td className="text-center">
                          <div
                            className={`btn btn-sm ${
                              employee.status !== "online" && "btn-danger"
                            } btn-success`}
                          >
                            {employee.status === "online"
                              ? "Online"
                              : "Offline"}
                          </div>
                        </td>
                        <td className="text-center">
                          {employee.employee_first_name}
                        </td>
                        <td className="text-center">
                          {employee.employee_last_name}
                        </td>
                        <td className="text-center">
                          {employee.employee_email}
                        </td>
                        <td className="text-center">
                          {employee.employee_phone}
                        </td>
                        <td className="text-center">
                          {employee.employee_branch}
                        </td>
                        <td className="text-center">
                          {employee.field_of_study}
                        </td>
                        <td className="text-center">
                          {employee.employee_privilege}
                        </td>
                        <td className="text-center">
                          {employee.company_role_name}
                        </td>
                        <td className="text-center">
                          {employee.employee_salary}
                        </td>
                        <td className="text-center">
                          <span className="last_seen">
                            {formatDistanceToNow(parseISO(employee.last_seen), {
                              addSuffix: true,
                            })}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="exp-date-format">
                            {formatDistanceToNow(
                              parseISO(employee.added_date),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        </td>
                        <td className="text-center">
                          <Link
                            className="text-white text-decoration-none me-1 p-1 bg-danger"
                            onClick={() => handleDelete(employee.employee_id)}
                          >
                            Remove
                          </Link>
                          <Link
                            className="text-white bg-success text-decoration-none p-1"
                            onClick={() => handleEdit(employee)}
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default Employees;
