// frontendStoreService.js
const api_url = process.env.REACT_APP_API_URL;

const getAllVetStoreCloneProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/clone/products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all vet store clone products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all vet store clone products:",
      error.message
    );
    throw error;
  }
};
const updateVetStoreCloneProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/clone/product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update vet store clone product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating vet store clone product:", error.message);
    throw error;
  }
};
const deleteVetStoreCloneProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/clone/product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(
        `Failed to delete store clone product: ${response.status}`
      );
    }

    return response;
  } catch (error) {
    console.error("Error deleting store clone product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetStoreCloneService = {
  getAllVetStoreCloneProducts,
  updateVetStoreCloneProduct,
  deleteVetStoreCloneProduct,
};

export default vetStoreCloneService;
