import React, { useEffect, useState } from "react";
import EmployeeReminder from "../../../../components/Employee/EmployeeReminder";
import VetEmployeeReportService from "../../../../../services/vet_employee_report.service";
import { useVetReminders } from "../../../../components/APIs/EmployeeAPI";

function VetReminder() {
  const [successMessage, setSuccessMessage] = useState("");
  const { vetreminders, setVetReminders, isLoading } = useVetReminders();
  const DELETEreminder = async (reportId) => {
    try {
      const response = await VetEmployeeReportService.DELETEreminder(reportId);

      if (response.status === 200) {
        const responseData = await response.json();
        setVetReminders((prevData) =>
          prevData.filter((item) => item.report_id !== reportId)
        );
        setSuccessMessage(responseData.message);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        throw new Error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };

  return (
    <div>
      <EmployeeReminder
        data={vetreminders}
        DELETEreminder={DELETEreminder}
        successMessage={successMessage}
        isLoading={isLoading}
      />
    </div>
  );
}

export default VetReminder;
