import React, { useEffect, useState } from "react";
import feedbackService from "../../../../services/survey.service";
import Feedback from "../../../components/Feedbacks/Feedback";
function EmployeeFeedback() {
  const [surveys, setSurveys] = useState([]);
  const [totalsurvey, setTostalsurvey] = useState(0);
  const EmployeeID = localStorage.getItem("EmployeeID");
  const [loadingSurveys, setLoadingSurveys] = useState(false);
  
  useEffect(() => {
    const getSurveys = async () => {
      setLoadingSurveys(true); // Set loadingSurveys to true before fetching data
      try {
        const response = await feedbackService.getSurveysByEmployeeID(
          EmployeeID
        );
        if (response) {
          if (response.status === "success") {
            setSurveys(response.data);
            setTostalsurvey(response.data.length);
          } else {
            console.error(`Error fetching Surveys: ${response.status}`);
          }
        } else {
          console.error("Empty response received from the server");
        }
      } catch (error) {
        console.error("Error fetching Surveys:", error.message);
      } finally {
        setLoadingSurveys(false); // Set loadingSurveys to false after fetching data
      }
    };

    getSurveys();
  }, []);

  return (
    <Feedback
      surveys={surveys}
      totalsurvey={totalsurvey}
      isLoading={loadingSurveys}
    />
  );
}

export default EmployeeFeedback;
