import React, { useEffect, useState } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Dashboard from "../../Dashboard/dashboard";
import { useAuth } from "../../../../Context/AuthContext";
import employeeService from "../../../../services/employee.service";
import BranchService from "../../../../services/branch.service";

function AddEmployeeForm() {
  const [employee_email, setEmail] = useState("");
  const [employee_first_name, setFirstName] = useState("");
  const [employee_last_name, setLastName] = useState("");
  const [employee_phone, setPhoneNumber] = useState("");
  const [employee_branch, setBranch] = useState("");
  const [employee_salary, setSalary] = useState("");
  const [field_of_study, setFieldOfStudy] = useState("");
  const [employee_password, setPassword] = useState("");
  const [employee_privilege, setPrivilege] = useState("");
  const [active_employee, setActive_employee] = useState(1);
  const [company_role_id, setCompany_role_id] = useState("");
  // Errors
  const [emailError, setEmailError] = useState("");
  const [firstNameRequired, setFirstNameRequired] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState("");
  const [branchs, setBranchs] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  // Create a variable to hold the user's token
  let loggedInEmployeeToken = "";
  // Destructure the auth hook and get the token
  const { employee } = useAuth();
  if (employee && employee.employee_token) {
    loggedInEmployeeToken = employee.employee_token;
  }
  const handleSubmit = (e) => {
    // Prevent the default behavior of the form
    e.preventDefault();
    // Handle client side validations
    let valid = true; // Flag
    // First name is required
    if (!employee_first_name) {
      setFirstNameRequired("First name is required");
      valid = false;
    } else {
      setFirstNameRequired("");
    }
    // Email is required
    if (!employee_email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!employee_email.includes("@")) {
      setEmailError("Invalid email format");
    } else {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(employee_email)) {
        setEmailError("Invalid email format");
        valid = false;
      } else {
        setEmailError("");
      }
    }
    // Password has to be at least 6 characters long
    if (!employee_password || employee_password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }
    // If the form is not valid, do not submit
    if (!valid) {
      return;
    }
    const formData = {
      employee_email,
      employee_first_name,
      employee_last_name,
      employee_phone,
      employee_branch,
      field_of_study,
      employee_salary,
      employee_password,
      employee_privilege: selectedPrivileges.join(", "),
      active_employee,
      company_role_id,
    };
    // Pass the form data to the service
    const newEmployee = employeeService.createEmployee(
      formData,
      loggedInEmployeeToken
    );
    newEmployee
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // If Error is returned from the API server, set the error message
        if (data.error) {
          setServerError(data.error);
        } else {
          // Handle successful response
          setSuccess(true);
          setServerError("");
          // Redirect to the employees page after 2 seconds
          // For now, just redirect to the home page
          setTimeout(() => {
            // window.location.href = '/admin/employees';
            window.location.href = "/admin/employees";
          }, 2000);
        }
      })
      // Handle Catch
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setServerError(resMessage);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchData();
  }, []);
  const handleCheckboxChange = (e, privilege) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedPrivileges([...selectedPrivileges, privilege]);
    } else {
      setSelectedPrivileges(selectedPrivileges.filter((p) => p !== privilege));
    }
  };

  return (
    <Dashboard>
      <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-info text-start div-center">
        <div className="bg-dark rounded p-4 p-sm-5 my-4 mx-3">
          <div className="d-flex align-items-center justify-content-center ">
            <h3 className="text-white text-bg-gradient ">
              <PersonAddAltIcon className="matrialuiicon" /> Add New Employee
              Form
            </h3>
          </div>
          {serverError && (
            <div className="validation-error" role="alert">
              {serverError}
            </div>
          )}
          {emailError && (
            <div className="validation-error" role="alert">
              {emailError}
            </div>
          )}
          {firstNameRequired && (
            <div className="validation-error" role="alert">
              {firstNameRequired}
            </div>
          )}
          {passwordError && (
            <div className="validation-error" role="alert">
              {passwordError}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3 m-1">
              <input
                type="text"
                className="form-control"
                id="floatingText"
                name="employee_first_name"
                value={employee_first_name}
                onChange={(event) => setFirstName(event.target.value)}
                placeholder="jhon"
              />
              <label htmlFor="floatingText">Employee first name</label>
            </div>
            <div className="form-floating mb-3 m-1">
              <input
                type="text"
                className="form-control"
                id="floatingText"
                name="employee_last_name"
                value={employee_last_name}
                onChange={(event) => setLastName(event.target.value)}
                placeholder="doe"
              />
              <label htmlFor="floatingText">Employee last name</label>
            </div>
            <div className="form-floating mb-3 m-1">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                name="employee_email"
                value={employee_email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Employee email</label>
            </div>
            <div className="form-floating mb-3 m-1">
              <input
                type="text"
                className="form-control"
                name="employee_phone"
                value={employee_phone}
                onChange={(event) => setPhoneNumber(event.target.value)}
                placeholder="Employee phone (555-555-5555)"
                required
              />
              <label htmlFor="floatingInput">Employee phone</label>
            </div>
            <div className="form-floating mb-4 m-1">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                name="employee_password"
                value={employee_password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Employee Password"
              />
              <label htmlFor="floatingPassword">Employee Password</label>
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-select form-select-custom"
                id="floatingSelect"
                name="employee_branch"
                value={employee_branch}
                onChange={(event) => setBranch(event.target.value)}
                required
              >
                <option selected hidden>
                  Select Employee Branch
                </option>
                {branchs.map((branchs) => (
                  <option key={branchs.branch_id} value={branchs.city}>
                    {branchs.city}
                  </option>
                ))}
              </select>
              <label htmlFor="floatinginput">Employee Branch</label>
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-select form-select-custom"
                id="floatingSelect"
                name="employee_role"
                value={company_role_id}
                onChange={(event) => setCompany_role_id(event.target.value)}
                required
              >
                <option selected hidden>
                  Select Employee Role
                </option>
                <option value="1">Employee-Agronomist</option>
                <option value="2">Employee-Veterinarian</option>
                <option value="3">Store Manager</option>
                <option value="4">Manager</option>
                <option value="5">Admin</option>
              </select>
              <label htmlFor="floatingSelect">Employee Role</label>
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-select form-select-custom"
                id="floatingSelect"
                name="employee_branch"
                value={field_of_study}
                onChange={(event) => setFieldOfStudy(event.target.value)}
                required
              >
                <option value="Agronomist">Agronomy</option>
                <option value="Veterinarian">Veterinary Science</option>
                <option value="Agribusiness Manager">
                  Agribusiness Management
                </option>
                <option value="Environmental Manager">
                  Environmental Management
                </option>
                <option value="Agricultural Engineer">
                  Agricultural Engineering
                </option>
                <option value="Environmental Engineer">
                  Environmental Engineering
                </option>
                <option value="Irrigation Engineer">
                  Irrigation Engineering
                </option>
                <option value="Agri-Tech Specialist">
                  Agricultural Information Technology
                </option>
                <option value="Agricultural Marketing Specialist">
                  Agricultural Marketing
                </option>
                <option value="Supply Chain Manager">
                  Logistics and Supply Chain Management
                </option>
                <option value="HR Manager in Agriculture">
                  Human Resource Management in Agriculture
                </option>
                <option value="Agricultural Assistant">
                  High School Graduate
                </option>
              </select>
              <label htmlFor="floatinginput">Employee Field Of Study</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="INSERT"
                id="insertCheckbox"
                checked={selectedPrivileges.includes("INSERT")}
                onChange={(e) => handleCheckboxChange(e, "INSERT")}
              />
              <label className="form-check-label" htmlFor="insertCheckbox">
                INSERT
              </label>
            </div>{" "}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="UPDATE"
                id="updateCheckbox"
                checked={selectedPrivileges.includes("UPDATE")}
                onChange={(e) => handleCheckboxChange(e, "UPDATE")}
              />
              <label className="form-check-label" htmlFor="updateCheckbox">
                UPDATE
              </label>
            </div>{" "}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="DELETE"
                id="deleteCheckbox"
                checked={selectedPrivileges.includes("DELETE")}
                onChange={(e) => handleCheckboxChange(e, "DELETE")}
              />
              <label className="form-check-label" htmlFor="deleteCheckbox">
                DELETE
              </label>
            </div>{" "}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="VIEW"
                id="viewCheckbox"
                checked={selectedPrivileges.includes("VIEW")}
                onChange={(e) => handleCheckboxChange(e, "VIEW")}
              />
              <label className="form-check-label" htmlFor="viewCheckbox">
                VIEW
              </label>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">ETB</span>
              <input
                type="number"
                className="form-control p-3"
                id="floatingInput"
                name="employee_salery"
                value={employee_salary}
                onChange={(event) => setSalary(event.target.value)}
                placeholder="Employee Salary per/month"
                required
              />
              <span className="input-group-text">.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  required
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  agree
                </label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
              Add employee
            </button>
          </form>
        </div>
      </div>
    </Dashboard>
  );
}
export default AddEmployeeForm;
