import React from "react";
import { Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
function OrederTable({ orders, SellOrder, Success, Error }) {
  const handleSell = (
    product_id,
    order_product_quantity,
    product_name,
    product_type,
    product_unit,
    product_unit_price,
    branch,
    customer_id,
    order_id
  ) => {
    const formData = {
      product_id: product_id,
      order_product_quantity: order_product_quantity,
      product_name: product_name,
      product_type: product_type,
      product_unit: product_unit,
      product_unit_price: product_unit_price,
      branch: branch,
      customer_id: customer_id,
      order_id: order_id,
      status: "Delivered",
    };
    SellOrder(formData);
  };

  return (
    <div>
      {orders && (
        <>
          <div className="m-2  ">
            {Error && <div className="alert alert-danger">{Error}</div>}
            {Success && <div className="alert alert-success">{Success}</div>}
            <div className="transaction rounded p-4">
              <h6 className="mb-4 text-center">Order History</h6>
              <div className="table-responsive">
                <table className="table table-hover table-striped table-dark  ">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit</th>
                      <th scope="col">Avalable QTY</th>
                      <th scope="col">Orderd QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Status</th>
                      <th scope="col">More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((Order, index) => (
                      <tr key={index}>
                        <th scope="row">{Order.order_id}</th>
                        <td>{Order.product_name}</td>
                        <td>{Order.product_type}</td>
                        <td>{Order.product_unit}</td>

                        <td>
                          <span className="custom-pill-green">
                            <td>{Order.product_table_product_quantity}</td>
                          </span>
                        </td>
                        <td>
                          <span className="custom-pill-pink">
                            <td>{Order.order_product_quantity}</td>
                          </span>
                        </td>
                        <td>{Order.product_unit_price}</td>

                        <td>{Order.branch}</td>
                        <td>
                          {Order.status === "pending" ? (
                            <span className="badge badge-pill bg-warning text-black">
                              {Order.status}
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-success text-white">
                              {Order.status}
                            </span>
                          )}
                        </td>

                        <td className="d-flex justify-content-center">
                          {Order.status !== "pending" ? (
                            <Link>
                              <VerifiedIcon className="text-custom-success" />
                            </Link>
                          ) : (
                            <>
                              <Link
                                to="/dashboard/agro/customer/orders"
                                className="btn btn-sm btn-outline-info me-2"
                              >
                                Detail
                              </Link>
                              {parseFloat(
                                Order.product_table_product_quantity
                              ) >= parseFloat(Order.order_product_quantity) ? (
                                <Link
                                  onClick={() =>
                                    handleSell(
                                      Order.product_id,
                                      Order.order_product_quantity,
                                      Order.product_name,
                                      Order.product_type,
                                      Order.product_unit,
                                      Order.product_unit_price,
                                      Order.branch,
                                      Order.customer_id,
                                      Order.order_id
                                    )
                                  }
                                  className="btn btn-sm btn-success"
                                >
                                  Sell
                                </Link>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrederTable;
