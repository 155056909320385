// frontendShowroomService.js
const api_url = process.env.REACT_APP_API_URL;

const AddInitialBalancs = async (balanceId, BalanceData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(BalanceData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/truth-track/add-initial-balance/${balanceId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to Add Petty Cash balance: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error Adding Petty Cash balance:", error.message);
    throw error;
  }
};
const getTrackedTruthData = async (branch) => {
 
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/truth-track/${branch}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get truth-track: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting truth-track:", error.message);
    throw error;
  }
};
const getInitialBalance = async (branch) => {


  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/truth-track/initial-balance/${branch}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get all intial balance: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all intial balance:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetStoreTruthTrackService = {
  AddInitialBalancs,
  getTrackedTruthData,
  getInitialBalance,
};

export default vetStoreTruthTrackService;
