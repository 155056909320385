import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

function ReportBarchart({ weeklyData, maxPadding = 30 }) {
  const chartRef = useRef(null);

  useEffect(() => {
    // Check if weeklyData is defined and is an array
    if (!weeklyData || !Array.isArray(weeklyData)) {
      return;
    }

    // Extracting data for the last week
    const lastWeekData = weeklyData.slice(-7); // Assuming weeklyData is sorted by date and the last 7 entries represent the last week

    // Extracting agro_chemical_type and by_money data
    const labels = lastWeekData.map((item) => item.agro_chemical_type);
    const data = lastWeekData.map((item) => item.by_money);
    // Generate random colors for each bar
    const colors = generateRandomColors(lastWeekData.length);
    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    // Calculate the maximum value in data with padding
    const maxValue = Math.max(...data) + maxPadding;

    // Render the Bar Chart
    const ctx = document
      .getElementById("LastWeekAgroChemicalBarChart")
      .getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "By Money",
            data: data,
            backgroundColor: colors,
            borderColor: "rgba(75, 192, 192, 8)",
            borderWidth: 1,
            borderRadius: "5", // Add border radius
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: maxValue,
          },
        },
        animation: {
          duration: 0, // Set the animation duration to 0 for no animation
        },
      },
    });
  }, [weeklyData, maxPadding]);
  // Function to generate random colors
  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
      colors.push(color);
    }
    return colors;
  };
  return (
    <div>
      {/* Bar Chart canvas */}
      <canvas id="LastWeekAgroChemicalBarChart"></canvas>
    </div>
  );
}

export default ReportBarchart;
