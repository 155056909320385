import React, { useState, useEffect } from "react";
import vetStoreOutedProductService from "../../../../../services/vet_store_outed_products.service";
import { useVetStoreAvailableProducts } from "../../../../components/APIs/FetchAPIs";
import OutProducts from "../../../../components/Products/OutProducts";
import BranchService from "../../../../../services/branch.service";

function VetOutProduct() {
  const { vetStoreAvailableProduct, APIError } = useVetStoreAvailableProducts();
  const [branchs, setBranchs] = useState([]);
  const NavTo = "/dashboard/store/vet/outed-products/goods-note";
  const handleOutProducts = async (OutProductData) => {
    try {
      const response = await vetStoreOutedProductService.OutVetProduct(
        OutProductData
      );
      if (response.status === "true") {
        return {
          success: true,
          message: response.message,
          data: response.data,
        };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      throw new Error(error.message || error.toString());
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <OutProducts
      products={vetStoreAvailableProduct}
      APIError={APIError}
      handleOutProducts={handleOutProducts}
      branchs={branchs}
      service={"vt"}
      NavTo={NavTo}
    />
  );
}

export default VetOutProduct;
