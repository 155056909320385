import React from "react";

const Music = () => {
  return (
    <>
      <h1 className="m-3">
        Work Music Playlist <span className="text-info">🎧</span> ◁ || ▷
      </h1>
      <div>
        <iframe
          className=" "
          src="https://open.spotify.com/embed/playlist/6X185BlQApNN7mjiFFhPdi?utm_source=generator"
          width="100%"
          height="700"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default Music;
