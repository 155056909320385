import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

function WeeklySalesLineChart({ soldProducts, type }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (soldProducts && soldProducts.length > 0) {
      // Initialize an object to store weekly sales totals
      const weeklySalesTotals = {};

      // Aggregate sales data by week for the last 8 weeks
      const currentDate = new Date();
      for (let i = 7; i >= 0; i--) {
        const weekStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - i * 7
        );
        const weekEndDate = new Date(
          weekStartDate.getFullYear(),
          weekStartDate.getMonth(),
          weekStartDate.getDate() + 6
        );
        const weekKey = getWeekKey(weekStartDate); // Get the week key for aggregation

        // Initialize weekly sales total to 0
        weeklySalesTotals[weekKey] = 0;

        // Aggregate sales data for the week
        soldProducts.forEach((item) => {
          const itemDate = new Date(
            type === "store" ? item.outed_date : item.sold_date
          );
          if (itemDate >= weekStartDate && itemDate <= weekEndDate) {
            weeklySalesTotals[weekKey] += parseFloat(item.product_total_price);
          }
        });
      }

      // Extract weeks and corresponding total prices
      const labels = Object.keys(weeklySalesTotals);
      const data = Object.values(weeklySalesTotals);

      // Destroy the previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Render the Line Chart
      const ctx = document
        .getElementById("weeklySalesLineChart")
        .getContext("2d");
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Weekly Sales",
              data: data,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 2,
              pointBackgroundColor: data.map((total) => getRandomColor()), // Generate random colors for each point
              pointBorderColor: "rgba(255, 99, 132, 1)",
              pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
              pointHoverBorderColor: "#ffffff",
              pointRadius: 4, // Increase point size
              fill: {
                target: "origin",
                above: "rgba(255, 99, 132, 0.2)", // Background color beneath the line
              },
            },
          ],
        },
        options: {
          indexAxis: "x", // Display weeks on the x-axis
          scales: {
            y: {
              beginAtZero: true,
              max: Math.max(...data) * 1.1, // Set maximum scale for y-axis with 10% padding
            },
          },
        },
        plugins: {
          tooltip: {
            backgroundColor: "rgba(255, 99, 132, 0.8)",
          },
        },
      });
    }
  }, [soldProducts]);

  // Function to get the week key
  const getWeekKey = (date) => {
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay()
    );
    // Truncate time part
    weekStart.setHours(0, 0, 0, 0);
    return weekStart.toISOString().split("T")[0]; // Use ISO string as the key (without time)
  };
  // Function to generate random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  return <canvas id="weeklySalesLineChart"></canvas>;
}

export default WeeklySalesLineChart;
