import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../../Context/AuthContext";

function OrderForm({ customer, data, APIError, handleOrder }) {
  const [searchinput, setSearchInput] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [saleQuantity, setSaleQuantity] = useState("");
  const [serverError, setServerError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { employee } = useAuth();
  const customerId = customer.customer_id;
  const post_id = customer.post_id;
  const productId = selectedProductId.product_id;
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSelectProduct = (productId) => {
    setSelectedProductId(productId);
  };
  const handleSellProduct = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    // if (!saleQuantity || isNaN(saleQuantity) || parseFloat(saleQuantity) <= 0) {
    //   setServerError("Please enter a valid product quantity.");
    //   setIsButtonDisabled(false);
    //   return;
    // }
    const selectedProduct = data.find(
      (data) => data.product_id === selectedProductId
    );

    if (!selectedProduct) {
      setServerError("Please select a product.");
      return;
    }

    if (!selectedProductId) {
      setServerError("Please select a product.");
      setIsButtonDisabled(false);
      return;
    }
    const formData = {
      customer_id: customerId,
      customer_post_id: post_id,
      product_quantity: parseFloat(saleQuantity),
      product_id: selectedProduct.product_id,
      branch: employee?.employee_branch,
    };
    try {
      const response = await handleOrder(formData);
      if (response.success) {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setServerError(response.error);
      }
    } catch (error) {
      setServerError(error.message || error.toString());
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const filteredproduct = data
    ? data
        .filter((data) => {
          const fieldsToSearch = [
            "product_id",
            "product_name",
            "product_type",
            "product_unit",
            "product_unit_price",
          ];
          return fieldsToSearch.some((field) =>
            String(data[field])
              .toLowerCase()
              .includes(searchinput.toLowerCase())
          );
        })
        .slice(0, 5)
    : [];

  return (
    <Dashboard>
      <div>
        {serverError && <div className="alert alert-danger">{serverError}</div>}
        {APIError && <div className="alert alert-danger">{APIError}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className=" tab-header text-white text-start  ">
          <div className="text-start  w-50 mt-4 text-white">
            <label>
              <SearchIcon className="me-2" />
              Search Product
            </label>
            <input
              className="form-control form-control-lg mb-3 w-50 p-3 mt-1"
              type="search"
              placeholder="Search Product"
              value={searchinput}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="col-sm-12 col-xl-12">
          {searchinput && filteredproduct.length > 0 ? (
            <>
              <div className="bg-secondary rounded h-100 p-4 ">
                <h4 className="mb-4 text-start text-white">Products</h4>
                <table className="table table-borderless table-dark ">
                  <thead>
                    <tr>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit </th>
                      <th scope="col">Product Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action {productId}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredproduct.map((data) => (
                      <tr key={data.product_id}>
                        <th scope="row">{data.product_id}</th>
                        <td>{data.product_name}</td>
                        <td>{data.product_type}</td>
                        <td>{data.product_unit}</td>
                        <td>{data.product_quantity}</td>
                        <td>{data.product_unit_price}</td>
                        <td>
                          <button
                            className={`btn btn-md btn-primary ${
                              selectedProductId === data.product_id
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => handleSelectProduct(data.product_id)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="col-sm-12 col-xl-12 text-white">
              {searchinput && (
                <div className="alert alert-warning ">
                  <h6>Your search returned no matches. </h6>
                </div>
              )}
            </div>
          )}
          <div className="module-border-wrap  rounded h-100 p-4 mt-3 mb-5 ">
            <div className="d-flex justify-content-between ">
              <h4 className="mb-4 text-start text-white">
                {selectedProductId
                  ? "Selected Product"
                  : "Please Select Product"}
              </h4>
              <button
                type="button"
                onClick={handleSellProduct}
                className={`btn btn-lg btn-success mb-2${
                  isButtonDisabled ? "disabledButton" : ""
                }`}
                disabled={!selectedProductId}
              >
                {isButtonDisabled ? "Adding Order.." : " Add Order"}
              </button>
            </div>
            <table className="table table-borderless table-dark text-start">
              <thead>
                <tr>
                  {selectedProductId && (
                    <>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit</th>
                      <th scope="col">Enter Product QTY</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {selectedProductId && (
                    <>
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={selectedProductId}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={
                            data.find(
                              (data) => data.product_id === selectedProductId
                            )?.product_name || ""
                          }
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control w-50 disabledInput"
                          value={
                            data.find(
                              (data) => data.product_id === selectedProductId
                            )?.product_type || ""
                          }
                          disabled
                        />
                      </td>
                      <td>
                        <div className="input-group mb-3 w-75 ">
                          <span className="input-group-text">ETB</span>
                          <input
                            type="text"
                            className="form-control w-50 disabledInput"
                            value={
                              data.find(
                                (data) => data.product_id === selectedProductId
                              )?.product_unit_price || ""
                            }
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-group mb-3 w-75">
                          <span className="input-group-text">QTY</span>
                          <input
                            type="text"
                            className="form-control w-50"
                            value={saleQuantity}
                            onChange={(e) => setSaleQuantity(e.target.value)}
                            required
                          />
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default OrderForm;
