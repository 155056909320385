const api_url = process.env.REACT_APP_API_URL;

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Function to add Survey
const submitSurvey = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/survey`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to add feedback: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error adding feedback.:", error.message);
    throw error;
  }
};

// Function to get all Surveys
const getSurveys = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/admin/surveys`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get all help requests: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all help requests:", error.message);
    throw error;
  }
};

const getSurveysByEmployeeID = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/surveys/${EmployeeID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get all help requests: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting all help requests:", error.message);
    throw error;
  }
};
// Function to check Survey Status
const checkSurveyStatus = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/survey/status/${EmployeeID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting status:", error.message);
    throw error;
  }
};
// Export all the functions
const feedbackService = {
  submitSurvey,
  getSurveys,
  getSurveysByEmployeeID,
  checkSurveyStatus,
};

export default feedbackService;
