import React, { useEffect, useState } from "react";
import {
  useVetSoldProducts,
  useVetSalesReport,
} from "../../../../components/APIs/FetchAPIs";
import vetcustomerService from "../../../../../services/vet_customer.service";
import Report from "../../../../components/Reports/Report";

function VetReport() {
  const { vetSalesReport } = useVetSalesReport();
  const { vetsoldProducts } = useVetSoldProducts();
  const [vetCustomer, setVetCustomer] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await vetcustomerService.getAllCustomers();

        if (response.ok) {
          const data = await response.json();
          setVetCustomer(data.data);
        } else {
          console.error(`Error fetching customers: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Report
      Customers={vetCustomer}
      SalesReport={vetSalesReport}
      soldProducts={vetsoldProducts}
    />
  );
}

export default VetReport;
