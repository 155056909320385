import React, { createContext, useState, useRef } from "react";
import BarcodeReader from "react-barcode-reader";

import errBeep from "../assets/Sounds/error.wav";
import ScannerService from "../services/barcode-scanne.service";
import {
  useAgroSoldProducts,
  useVetSoldProducts,
} from "../markup/components/APIs/FetchAPIs";
const BarcodeScannerContext = createContext();

const BarcodeScannerProvider = ({ children }) => {
  const [barcode, setBarcode] = useState("");
  const [error, setError] = useState("");
  const [scanSuccess, setScanSuccess] = useState([]);
  const errorBeepRef = useRef(null);
  const { agrosoldProducts, aghandleFetchData } = useAgroSoldProducts();
  const { vetsoldProducts, handleFetchData } = useVetSoldProducts();
  const handleScan = (data) => {
    if (data) {
      setBarcode(data);
      sendBarcodeToBackend(data);
    }
  };
  const handleError = (err) => {
    setError(err);
  };
  const sendBarcodeToBackend = async (barcode) => {
    try {
      const response = await ScannerService.Scan(barcode);
      if (response.status !== "true") {
        setScanSuccess(false);
        errorBeepRef.current.play();
      }
      if (response.data.service === "AGCHSR") {
        await aghandleFetchData();
      } else {
        await handleFetchData();
      }

      const message = `${response.message}`;
      setScanSuccess((prevMessages) => [...prevMessages, message]);

      setTimeout(() => {
        setScanSuccess((prevMessages) =>
          prevMessages.filter((msg) => msg !== message)
        );
      }, 9000); // Remove the message after 9 seconds
    } catch (err) {
      errorBeepRef.current.play();
      console.error(err);
    }
  };

  return (
    <BarcodeScannerContext.Provider
      value={{ barcode, error, agrosoldProducts, vetsoldProducts, scanSuccess }}
    >
      {children}
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <audio ref={errorBeepRef} src={errBeep} />
    </BarcodeScannerContext.Provider>
  );
};

export { BarcodeScannerContext, BarcodeScannerProvider };
