import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function EditPayment({
  handleEditPayment,
  PaymentId,
  onCancel,
  EditingPaymentData,
}) {
  const [updatedPaymentData, setupdatedPaymentData] = useState({
    ...EditingPaymentData,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedPaymentData((prevData) => ({ ...prevData, [name]: value }));
  };
  function handleSelectChange(selectedOption, field) {
    setupdatedPaymentData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : null,
    }));
  }
  useEffect(() => {
    setupdatedPaymentData({ ...EditingPaymentData });
  }, [EditingPaymentData]);
    return (
      <div className="col-md-6">
        <div className="card  bg-dark text-white">
          <div className="card-body ">
            <h5 className="card-title">Edit Payment</h5>
            <div className="mb-3 text-start mt-3">
              <div className="fs-6 text-danger w-25" onClick={onCancel}>
                <HighlightOffIcon />
              </div>
              <form
                onSubmit={(event) =>
                  handleEditPayment(event, PaymentId, updatedPaymentData)
                }
              >
                <div className="mb-3 text-start mt-3">
                  <label htmlFor="paymentInput1" className="form-label">
                    Purpose
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="paymentInput1"
                    placeholder="Purpose"
                    name="purpose"
                    value={updatedPaymentData.purpose}
                    onChange={handleInputChange}
                    required
                  />{" "}
                  <label htmlFor="paymentInput2" className="form-label">
                    Recipient
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="paymentInput2"
                    placeholder="Recipient"
                    name="recipient"
                    value={updatedPaymentData.recipient}
                    onChange={handleInputChange}
                    required
                  />{" "}
                  <label htmlFor="paymentInput3" className="form-label">
                    Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="paymentInput3"
                    placeholder="Amount"
                    name="amount"
                    value={updatedPaymentData.amount}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-lg pm w-100 mt-2 p-3">
                  Update Payment
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EditPayment;
