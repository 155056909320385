import React, { useEffect, useState } from "react";
import agroStoreOutedProductService from "../../../../../services/agro_store_outed_products.service";
import OutedProductInvoices from "../../../../components/xlsx/OutedProductInvoices";
// import OutedProductInvoices from "../../../../components/xlsx/OutedProductInvoices";
const getDataFromLocalStorage = () => {
  const data = localStorage.getItem("hash_ops_data");
  return data ? JSON.parse(data) : [];
};
function AgroOutedProductInvoice() {
  const data = getDataFromLocalStorage();
  const Products = data.products || [];
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching
      try {
        const response =
          await agroStoreOutedProductService.getOutedProductsByIds(Products);
        if (response.status === "success") {
          setProducts(response.data);
        
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <OutedProductInvoices Products={products} isLoading={isLoading} />
    </div>
  );
}

export default AgroOutedProductInvoice;
