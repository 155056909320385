import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import VerifiedIcon from "@mui/icons-material/Verified";
import TodayIcon from "@mui/icons-material/Today";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
function CustomerStatsCard({ data, type }) {
  const { employee } = useAuth();
  const filteredData = data.filter(
    (customer) => customer.branch === employee?.employee_branch
  );
  // Calculate male and female counts
  const maleCount = filteredData.filter(
    (customer) => customer.customer_sex === "Male"
  ).length;
  const femaleCount = filteredData.filter(
    (customer) => customer.customer_sex === "Female"
  ).length;

  // Calculate active customers
  const activeCustomers = filteredData.filter(
    (customer) => customer.isActive
  ).length;

  // Calculate today's new customers
  const today = new Date().toISOString().slice(0, 10); // Get today's date in ISO format (YYYY-MM-DD)
  const todayNewCustomers = filteredData.filter(
    (customer) => customer.created_at.slice(0, 10) === today
  ).length;

  // Calculate new male customers
  const newMaleCustomers = filteredData.filter(
    (customer) => customer.customer_sex === "Female" && customer.isNew
  ).length;
  // Calculate new customers for the current month by gender (M and F)
  const currentMonth = new Date().getMonth() + 1; // Get current month (1-12)
  const currentYear = new Date().getFullYear(); // Get current year
  const currentMonthNewCustomersM = filteredData.filter((customer) => {
    const customerDate = new Date(customer.created_at);
    return (
      customerDate.getMonth() + 1 === currentMonth &&
      customerDate.getFullYear() === currentYear &&
      customer.customer_sex === "Male"
    );
  }).length;
  const currentMonthNewCustomersF = filteredData.filter((customer) => {
    const customerDate = new Date(customer.created_at);
    return (
      customerDate.getMonth() + 1 === currentMonth &&
      customerDate.getFullYear() === currentYear &&
      customer.customer_sex === "Female"
    );
  }).length;
  const activeMaleCustomers = filteredData.filter(
    (customer) => customer.customer_sex === "Male" && customer.isActive
  ).length;
  const inactiveMaleCustomers = maleCount - activeMaleCustomers;

  const activeFemaleCustomers = filteredData.filter(
    (customer) => customer.customer_sex === "Female" && customer.isActive
  ).length;
  const inactiveFemaleCustomers = femaleCount - activeFemaleCustomers;

  return type !== "custom" ? (
    <>
      <div className="container-fluid mt-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-3">
            <div className="text-white ovr-6 rounded d-flex align-items-center justify-content-between p-4">
              <i className="customer-icon">
                <GroupIcon className="customer-icon" />
              </i>
              <div className="ms-3">
                <p className="mb-2">Total Customers</p>
                <h6 className="mb-0">Male: {maleCount}</h6>
                <h6 className="mb-0">Female: {femaleCount}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="text-white rounded d-flex ovr-8 align-items-center justify-content-between p-4">
              <i className="customer-icon">
                <VerifiedIcon className="customer-icon" />
              </i>
              <div className="ms-3 ">
                <p className="mb-2">Customers Activity</p>
                <div className="activity">
                  <h6 className="mb-0">
                    Active: M: {activeMaleCustomers} F: {activeFemaleCustomers}
                  </h6>
                  <h6 className="mb-0">
                    Inactive M: {inactiveMaleCustomers} F:{" "}
                    {inactiveFemaleCustomers}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="text-white rounded d-flex ovr-10 align-items-center justify-content-between p-4">
              <i className="customer-icon">
                <TodayIcon className="customer-icon" />
              </i>
              <div className="ms-3">
                <p className="mb-2">Today New Customers</p>
                <h6 className="mb-0">{todayNewCustomers}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="text-white rounded d-flex ovr-7 align-items-center justify-content-between p-4">
              <i className="customer-icon">
                <CalendarMonthIcon className="customer-icon" />
              </i>
              <div className="ms-3">
                <p className="mb-2">CM New Customers</p>
                <h6 className="mb-0">Male: {currentMonthNewCustomersM}</h6>
                <h6 className="mb-0">Female: {currentMonthNewCustomersF}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="col-sm-12 col-md-6 col-xl-6 mb-4 mt-3">
        <div className="h-100 bg-dark border border-light text-white  rounded p-4">
          <div className="d-flex align-items-center border-bottom border-gray pb-3 justify-content-between mb-2">
            <p className="mb-0">Customers Report</p>
            <Link className="text-danger ">Show All</Link>
          </div>
          <table className="table table-dark table-borderless table-striped-columns  align-middle  justify-content-center">
            <tbody>
              <tr className="align-middle">
                <td>
                  <div className="d-flex text-start">
                    <h6 className="mb-0 ms-3">
                      Today's Total Gained Customers
                    </h6>
                  </div>
                </td>
                <td>
                  <span className="custom-pill-yellow ">Today</span>
                </td>
                <td>
                  {todayNewCustomers <= 29 ? (
                    <span className="badge bg-primary">
                      {todayNewCustomers}{" "}
                    </span>
                  ) : (
                    <span className="badge rounded-pill bg-success">
                      {todayNewCustomers}
                    </span>
                  )}
                </td>
              </tr>
              <tr className="align-middle">
                <td>
                  <div className="d-flex text-start">
                    <h6 className="mb-0 ms-3">
                      Current Month's Total Gained Customers
                    </h6>
                  </div>
                </td>
                <td>
                  <span className="custom-pill-yellow ">Current Month</span>
                </td>
                <td>
                  <span className="badge bg-light text-dark ">
                    Male:
                    <span className="badge bg-success rounded-0 ms-1">
                      {currentMonthNewCustomersM}
                    </span>
                  </span>
                  <br />
                  <span className="badge bg-light text-dark ">
                    Femail:
                    <span className="badge bg-success rounded-0 ms-1">
                      {currentMonthNewCustomersF}
                    </span>
                  </span>
                </td>
              </tr>
              <tr className="align-middle">
                <td>
                  <div className="d-flex text-start">
                    <h6 className="mb-0 ms-3">All Customers</h6>
                  </div>
                </td>
                <td>
                  <span className="custom-pill-yellow ">All Time</span>
                </td>
                <td>
                  <span className="badge bg-light text-dark ">
                    Male:
                    <span className="badge bg-success rounded-0 ms-1">
                      {maleCount}
                    </span>
                  </span>
                  <br />
                  <span className="badge bg-light text-dark ">
                    Female:
                    <span className="badge bg-success rounded-0 ms-1">
                      {femaleCount}
                    </span>
                  </span>
                </td>
              </tr>{" "}
              <tr className="align-middle">
                <td>
                  <div className="d-flex text-start">
                    <h6 className="mb-0 ms-3">Inactive Customers</h6>
                  </div>
                </td>
                <td>
                  <span className="custom-pill-yellow ">All Time</span>
                </td>
                <td>
                  <span className="badge bg-light text-dark ">
                    Male:
                    <span className="badge bg-danger rounded-0 ms-1">
                      {inactiveMaleCustomers}
                    </span>
                  </span>
                  <br />
                  <span className="badge bg-light text-dark ">
                    Female:
                    <span className="badge bg-danger rounded-0 ms-1">
                      {inactiveFemaleCustomers}
                    </span>
                  </span>
                </td>
              </tr>
              <tr className="align-middle">
                <td>
                  <div className="d-flex text-start">
                    <h6 className="mb-0 ms-3">Active Customers</h6>
                  </div>
                </td>
                <td>
                  <span className="custom-pill-yellow ">All Time</span>
                </td>
                <td>
                  <span className="badge bg-light text-dark ">
                    Male:
                    <span className="badge bg-success  rounded-0 ms-1">
                      {activeMaleCustomers}
                    </span>
                  </span>
                  <br />
                  <span className="badge bg-light text-dark ">
                    Female:
                    <span className="badge bg-success rounded-0 ms-1">
                      {activeFemaleCustomers}
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default CustomerStatsCard;
