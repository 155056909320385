import React from "react";
import { Link } from "react-router-dom";

function SurveyNoticeMessage({ submissionStatus }) {
  return (
    <Link
      to="/dashboard/employee/survey"
      className="text-decoration-none surveynoticetxt "
    >
      <div className="alert custom-alert-danger" role="alert">
        {submissionStatus}
      </div>
    </Link>
  );
}

export default SurveyNoticeMessage;
