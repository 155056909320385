import React, { useState, useEffect } from "react";

import vetStoreOutedProductsService from "../../../../../services/vet_store_outed_products.service";
import {
  useVetStoreAvailableProducts,
  useVetOutedProducts,
} from "../../../../components/APIs/FetchAPIs";
import BranchService from "../../../../../services/branch.service";
import StoreOutedProducts from "../../../../components/Products/StoreOutedProducts";
function VetOutedProducts() {
  const [branchs, setBranchs] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { vetoutedProducts, isLoading } = useVetOutedProducts();
  const { vetStoreAvailableProduct ,handleFetchData} = useVetStoreAvailableProducts();

  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this outed product?")) {
      try {
        const response =
          await vetStoreOutedProductsService.deleteOutedVetProduct(ProductId);
        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();
    try {
      const response = await vetStoreOutedProductsService.updateOutedVetProduct(
        ProductId,
        updatedProductData
      );
      if (response.status === "success") {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.location.href = "/dashboard/store/vet/outed-products";
        }, 2000);
      } else {
        setError("Failed to update outed product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError(`Error: ${error}`);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <StoreOutedProducts
      handleDeleteProduct={handleDeleteProduct}
      OutedProducts={vetoutedProducts}
      isLoading={isLoading}
      successMessage={successMessage}
      HandleEdit={handleEdit}
      products={vetStoreAvailableProduct}
      branchs={branchs}
      servicetype={"vetstore"}
      handleFetchData={handleFetchData}
    />
  );
}

export default VetOutedProducts;
