import React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
function SmallQuantityProducts({ products, expiredDate, showDates }) {
  // Get the current date
  const currentDate = new Date();
  let totalQuantity = 0;
  let totalPrice = 0;

  products.forEach((product) => {
    totalQuantity += parseInt(product.product_quantity);
    totalPrice += parseFloat(product.product_total_price);
  });

  // Filter expired products based on the expiration date and quantity
  const expiredProducts = products.filter((product) => {
    return parseInt(product.product_quantity) <= 5;
  });
  const totalSQ = expiredProducts.length;
  return (
    <div>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4 text-white">
          <div className="col-sm-12 col-xl-12">
            <div className="ovr-8 rounded d-flex align-items-center justify-content-between p-4">
              <i>--</i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total SQ Products</p>
                <h6 className="mb-0">{totalSQ}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Display Expired Products */}
      <div className="container-fluid pt-4 px-4 mb-5">
        <div className="secondarybg text-center rounded p-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="mb-0 text-primary">Recent SQ</h6>
            {/* Link to show all expired products */}
            <Link className="xpm">Show All</Link>
          </div>
          {/* Table to display expired products */}
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover table-dark table-striped">
              {" "}
              <thead>
                <tr className="text-white">
                  <th scope="col">Product ID</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Type</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">EXP Date</th>
                  <th scope="col">Added Date</th>
                </tr>
              </thead>
              <tbody>
                {expiredProducts.map((product, index) => (
                  <tr key={index}>
                    <td>{product.product_id}</td>
                    <td>{product.product_name}</td>
                    <td>{product.product_type}</td>
                    <td>{product.product_quantity}</td>

                    <td>
                      <span className="badge rounded-pill bg-primary">
                        {formatDistanceToNow(
                          parseISO(product.expiration_date),
                          { addSuffix: true }
                        )}
                      </span>
                    </td>

                    {showDates ? (
                      <td>
                        <span className="sold_date-format">
                          {format(
                            parseISO(product.added_date),
                            "MM/dd/yyyy HH:mm:ss"
                          )}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge rounded-pill bg-light text-dark">
                          {formatDistanceToNow(parseISO(product.added_date), {
                            addSuffix: true,
                          })}
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallQuantityProducts;
