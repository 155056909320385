import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

function AgroTransProfileBarChart({ soldProducts }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (soldProducts && soldProducts.length > 0) {
      // Filter soldProducts for the current month
      const today = new Date();
      const currentMonthStart = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );

      const filteredSoldProducts = soldProducts.filter((item) => {
        const itemDate = new Date(item.sold_date);
        return itemDate >= currentMonthStart && itemDate <= today;
      });

      // Extract product names, total prices, and sold dates
      const productNames = filteredSoldProducts.map(
        (item) => item.product_name
      );
      const productTotalPrices = filteredSoldProducts.map(
        (item) => item.product_total_price
      );
      const soldDates = filteredSoldProducts.map((item) => item.sold_date);

      // Destroy the previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Render the Bar Chart
      const ctx = document
        .getElementById("AgroTransProfileLineChart")
        .getContext("2d");
      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: productNames,
          datasets: [
            {
              label: "Product Total Price",
              data: productTotalPrices,
              backgroundColor: getRandomColors(productNames.length),
              borderColor: "#ffffff",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                title: function (tooltipItem) {
                  return productNames[tooltipItem[0].dataIndex];
                },
                label: function (context) {
                  const soldDate = new Date(soldDates[context.dataIndex]);
                  const totalPrice = productTotalPrices[context.dataIndex];
                  return [
                    "Sold Date: " + soldDate.toDateString(),
                    "Total Money: " + totalPrice,
                  ];
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: "Products Name",
              },
            },
            y: {
              min: 0,
              title: {
                display: true,
                text: "Total Price",
              },
              ticks: {
                stepSize: 100, // Adjust as needed
              },
            },
          },
        },
      });
    }
  }, [soldProducts]);

  // Function to generate an array of random colors
  const getRandomColors = (length) => {
    const colors = [];
    const letters = "0123456789ABCDEF";
    for (let i = 0; i < length; i++) {
      let color = "#";
      for (let j = 0; j < 6; j++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      colors.push(color);
    }
    return colors;
  };

  return <canvas id="AgroTransProfileLineChart"></canvas>;
}

export default AgroTransProfileBarChart;
