import React, { useState } from "react";


function EditCustomerForm({
  customerId,
  onCancel,
  editingCustomer,
  handleEditCustomer,
  Error,
}) {
  const [customerData, setCustomerData] = useState({
    customer_first_name: "",
    customer_last_name: "",
    customer_sex: "",
    customer_address: "",
    customer_phone: "",
    ...editingCustomer,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomerData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-info text-start div-center">
      <div className="bg-dark rounded p-4 p-sm-5 my-4 mx-3">
        <div className="d-flex align-items-center justify-content-center ">
          <h3 className="text-white text-bg-gradient ">Edit Customer Form</h3>
        </div>
        {Error && (
          <div className="validation-error" role="alert">
            {Error}
          </div>
        )}
        <button
          type="button"
          className="btn btn-secondary w-100"
          onClick={onCancel}
        >
          Cancel
        </button>
        <form
          onSubmit={(event) =>
            handleEditCustomer(event, customerId, customerData)
          }
        >
          <div className="form-floating mb-3 m-1">
            <input
              type="text"
              className="form-control"
              id="floatingTextFN"
              name="customer_first_name"
              value={customerData.customer_first_name}
              onChange={handleInputChange}
              placeholder="John"
              required
            />
            <label htmlFor="floatingText">Customer first name</label>
          </div>
          <div className="form-floating mb-3 m-1">
            <input
              type="text"
              className="form-control"
              id="floatingTextLN"
              name="customer_last_name"
              value={customerData.customer_last_name}
              onChange={handleInputChange}
              placeholder="Doe"
              required
            />
            <label htmlFor="floatingText">Customer last name</label>
          </div>
          {/* Additional customer fields go here */}
          <div className="form-floating mb-3">
            {/* Example: Gender */}
            <select
              className="form-select form-select-custom"
              id="floatingSelectCS"
              name="customer_sex"
              value={customerData.customer_sex}
              onChange={handleInputChange}
              required
            >
              <option selected hidden>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <label htmlFor="floatingSelect">Customer Gender</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputCA"
              name="customer_address"
              value={customerData.customer_address}
              onChange={handleInputChange}
              placeholder="Customer Address"
              required
            />
            <label htmlFor="floatingInput">Customer Address</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="tel"
              className="form-control"
              id="floatingInputCP"
              name="customer_phone"
              value={customerData.customer_phone}
              onChange={handleInputChange}
              placeholder="Customer Phone"
              required
            />
            <label htmlFor="floatingInput">Customer Phone</label>
          </div>
          {/* Additional customer fields go here */}
          <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
            Edit customer
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditCustomerForm;
