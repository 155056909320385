import React from "react";
import EmployeeProfileUpload from "./EmployeeProfileUpload";
import EmployeeProfileView from "./EmployeeProfileView";
import { useAuth } from "../../../Context/AuthContext";

function ChangeProfile() {
  const { employee } = useAuth();
  return (
    <div className="changepro ">
      <div className="mb-5 mt-5">
        <div className="profile-img">
          <EmployeeProfileView
            picstyle={{
              borderRadius: "50%",
              width: "255px",
              height: "255px",
            }}
          />
          <h2 className="mb-5 font-monospace icoo">
            {employee?.employee_first_name} {employee?.employee_last_name}
          </h2>
          <EmployeeProfileUpload />
          <p className="mt-1 text-gray">
            Please ensure that the uploaded picture is a minimum of 1000 KB in
            size.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChangeProfile;
