import React, { useEffect, useState } from "react";
import { useVetShowroomCloneProducts } from "../../../../components/APIs/FetchAPIs";
import ShowroomProducts from "../../../../components/Products/ShowroomProducts";
import vetShowroomCloneService from "../../../../../services/vet_showroom_clone_product.service";


function VetShowroomCloneProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { vetShowroomCloneProducts, isLoading, APIError } =
    useVetShowroomCloneProducts();
  

  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this Clone product?")) {
      try {
        const response =
          await vetShowroomCloneService.deleteVetShowroomCloneProduct(
            ProductId
          );

        if (response.status === 200) {
          const responseData = await response.json(); // Parse response JSON

          setSuccessMessage(responseData.message); // Display server message
        } else {
          throw new Error("Error: Clone Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();

    try {
      const response =
        await vetShowroomCloneService.updateVetShowroomCloneProduct(
          ProductId,
          updatedProductData
        );

      if (response.status === "success") {
        // Provide user feedback about success
        setSuccessMessage("Product updated successfully.");
        // Redirect after a delay
        setTimeout(() => {
          window.location.href = "/dashboard/showroom/vet/clone/product";
        }, 2000);
      } else {
        // Handle other response statuses
        setError("Failed to update clone product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing clone product:", error);
      setError("Error editing  clone product. Please try again.");
    }
  };

 
  return (
    <div>
      <ShowroomProducts
        products={vetShowroomCloneProducts}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        type={"clone"}
        service={"Vet"}
      />
    </div>
  );
}

export default VetShowroomCloneProducts;
