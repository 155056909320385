import React, { useState, useEffect } from "react";

import agroStoreOutedProductsService from "../../../../../services/agro_store_outed_products.service";
import { useAgroAvailableStoreProducts, useAgroOutedProducts } from "../../../../components/APIs/FetchAPIs";
import BranchService from "../../../../../services/branch.service";
import StoreOutedProducts from "../../../../components/Products/StoreOutedProducts";
function AgroOutedProducts() {
  const [branchs, setBranchs] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { agrooutedProducts, isLoading } = useAgroOutedProducts();
  const { agroStoreAvailableProduct,handleFetchData } = useAgroAvailableStoreProducts();
  // ,
  // console.log("data", agrooutedProducts);
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this outed product?")) {
      try {
        const response =
          await agroStoreOutedProductsService.deleteOutedAgroProduct(ProductId);
        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();
    try {
      const response =
        await agroStoreOutedProductsService.updateOutedAgroProduct(
          ProductId,
          updatedProductData
        );
      if (response.status === "success") {
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => {
          window.location.href = "/dashboard/store/agro/outed-products";
        }, 2000);
      } else {
        setError("Failed to update sold product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BranchService.getAllBranchs();
        if (response && response.data && response.data.length !== 0) {
          setBranchs(response.data);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <StoreOutedProducts
      handleDeleteProduct={handleDeleteProduct}
      OutedProducts={agrooutedProducts}
      isLoading={isLoading}
      successMessage={successMessage}
      HandleEdit={handleEdit}
      products={agroStoreAvailableProduct}
      branchs={branchs}
      handleFetchData={handleFetchData}
    />
  );
}

export default AgroOutedProducts;
