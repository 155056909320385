import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import Invoice from "../Invoice/Invoice";
import Dashboard from "../Dashboard/dashboard";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchIcon from "@mui/icons-material/Search";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import employeeService from "../../../services/employee.service";
const getDataFromLocalStorage = () => {
  const data = localStorage.getItem("hash_ops_data");
  return data ? JSON.parse(data) : [];
};

// Component to generate PDF
const OutedProductInvoices = ({ Products, isLoading }) => {
  const data = getDataFromLocalStorage();
  const lsdata = localStorage.getItem("hash_ops_data");

  const totalProducts = lsdata !== "false" ? Products.length : 0;

  let productsTotalPrice = 0;
  // Calculate Products Total Price
  if (lsdata !== "false") {
    Products.forEach((product) => {
      productsTotalPrice +=
        parseFloat(product.product_unit_price) *
        parseInt(product.product_quantity);
    });
  }

  const [showInvoice, setShowInvoice] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const employeeObject = employeeData.length > 0 ? employeeData[0] : {};

  const handleShowInvoice = () => {
    setShowInvoice(true);
  };

  const handleHideInvoice = () => {
    setShowInvoice(false);
  };
  const RegenerateHandler = () => {
    window.location.reload();
    setShowInvoice(true);
  };
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await employeeService.getEmployeeByBranchAndService(
          data.Moreinfo.ToBranch,
          data.Moreinfo.Service
        );

        if (response.status === "success") {
          setEmployeeData(response.data);
        } else {
          console.error(`Error fetching employee info: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching employee info:", error.message);
      }
    };

    fetchEmployeeData();
  }, []);
  // Function to display forbidden alert
  const handleRemoveAll = () => {
    alert("You cannot perform this action.");
  };
  // Function to remove data from local storage after user confirmation
  const removeFromLocalStorage = () => {
    const confirmed = window.confirm(
      "Are you sure you finished downloading the PDF?"
    );
    if (confirmed) {
      localStorage.setItem("hash_ops_data", false);
      window.history.back();

    } else {
      // console.log("Data removal canceled.");
    }
  };

  return (
    <Dashboard>
      {lsdata !== "false" && (
        <>
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4 text-white">
              <div className="col-sm-4 col-xl-4">
                <div className="ovr-8 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <ShoppingCartOutlinedIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3 text-end">
                    <p className="mb-2">Total Products</p>
                    <h6 className="mb-0">{totalProducts}</h6>
                  </div>
                </div>
              </div>{" "}
              <div className="col-sm-4 col-xl-4">
                <div className="ovr-1 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <AccessTimeIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3 text-end">
                    <p className="mb-2">Deliver To</p>
                    <h6 className="mb-0">
                      {data.Moreinfo.ToBranch} AOSS CENTER
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xl-4">
                <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <LocalShippingIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3 text-end">
                    <p className="mb-2">Receiver Name</p>
                    <h6 className="mb-0">
                      {employeeObject.employee_first_name}{" "}
                      {employeeObject.employee_last_name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4 mb-5 ">
            <div className="bg-dark text-center rounded p-4 border ">
              <div className=" mb-4">
                <div className="d-flex justify-content-end mb-4 align-items-center ">
                  <Link
                    className={`btn btn-sm btn-success me-3 ${
                      isLoading ? "disabled" : ""
                    }`}
                    onClick={removeFromLocalStorage}
                    
                  >
                    I Agreed And Downloaded
                  </Link>
                  <Link
                    className={`btn btn-sm btn-danger me-3 `}
                    onClick={handleRemoveAll}
                  >
                    Remove all
                  </Link>
                  <Link
                    className={`btn btn-sm btn-info  ${
                      isLoading ? "disabled" : ""
                    }`}
                    onClick={RegenerateHandler}
                  >
                    Re-Generate Invoice
                  </Link>
                </div>

                <div className="d-lg-flex d-md-flex   d-sm-block justify-content-between  mb-4 align-items-center">
                  <div className="col-sm-12 col-xl-6 me-2">
                    <div className="invicecard text-start rounded h-100 p-4">
                      <h6 className="mb-4 text-warning">Outed Goods Info</h6>
                      <dl className="row mb-0">
                        <dt className="col-sm-4">Deliver Branch</dt>
                        <dd className="col-sm-8">
                          {data.Moreinfo.DeliverBranch} AOSS Center
                        </dd>
                        <dt className="col-sm-4">Deliverd To</dt>
                        <dd className="col-sm-8">
                          {data.Moreinfo.ToBranch} AOSS Center
                        </dd>
                        <dt className="col-sm-4">Deliverd By</dt>
                        <dd className="col-sm-8">
                          {data.Moreinfo.EmployeeName}
                        </dd>
                        <dt className="col-sm-4">Receiver</dt>
                        <dd className="col-sm-8">
                          {" "}
                          {employeeObject.employee_first_name}{" "}
                          {employeeObject.employee_last_name}
                        </dd>
                        <dt className="col-sm-4">Deliverd Time</dt>
                        <dd className="col-sm-8">
                          {data.Moreinfo.currentTime}
                        </dd>
                        <dt className="col-sm-4 ">Deliverd Date</dt>
                        <dd className="col-sm-8">
                          {data.Moreinfo.currentDate}
                        </dd>
                      </dl>
                    </div>
                  </div>{" "}
                  <div className="col-sm-12 col-xl-6">
                    <div className="invicecardtwo rounded h-100 p-4 pb-5 ">
                      <h6 className="mb-4 text-warning text-start">
                        Outed Goods Balance Info
                      </h6>

                      <h1>
                        TOTAL: <span>ETB</span> {productsTotalPrice}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="text-white" />
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="text-white text-start">
                  Ready To Print/Download
                </h3>
                {showInvoice ? (
                  <Link
                    className="btn btn-sm btn-danger me-3"
                    onClick={handleHideInvoice}
                  >
                    Hide Invoice
                  </Link>
                ) : (
                  <Link
                    className={`btn btn-sm btn-success me-3 ${
                      isLoading ? "disabled" : ""
                    }`}
                    onClick={handleShowInvoice}
                  >
                    Show Invoice
                  </Link>
                )}
              </div>
              <hr className="text-white" />

              {showInvoice && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PDFViewer width="800" height="1170">
                    <Invoice
                      products={Products}
                      total={productsTotalPrice}
                      employeeData={employeeObject}
                      Info={data.Moreinfo}
                    />
                  </PDFViewer>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
};

export default OutedProductInvoices;
