import React from "react";

function Loader() {
  const pathname = window.location.pathname;
  const isDashboard = pathname.includes("dashboard");
  return (
    <div className="loader_container">
      <div className="main_loader">
        <div className="spinnerin"></div>
      </div>
      <div className="justify-content-center align-items-center loader__con">
        <div
          className={`logo_loader ${
            isDashboard ? "logo_loader_dashboard" : "logo_loader_public"
          } `}
        ></div>
        {isDashboard && (
          <div className="justify-content-center mt-2  ">
            <h3 className="text-info text-start ms-4">
              AOSS CENTER <span className="text-warning">SOFTWARE</span>
            </h3>
            <p className="text-white text-start ms-4">
              From Showroom to Sale: Master Your Business Flow!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Loader;
