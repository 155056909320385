import React from "react";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import SyncIcon from "@mui/icons-material/Sync";
function PendingProducts({ products, showDates, handleFetchData, isLoading }) {
  return (
    <div>
      <div className="container-fluid pt-4 px-4 mb-5">
        <div className="secondarybg text-center rounded p-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="mb-0 text-white">Incoming...</h6>
            <Link className="xpm">Show All</Link>
            {isLoading ? (
              <SyncIcon className="fetchiconOnloading" />
            ) : (
              <CloudSyncIcon
                onClick={handleFetchData ? handleFetchData : undefined}
                className="refetchdataicon"
              />
            )}
          </div>
          <div className="table-responsive">
            {products.length !== 0 ? (
              <>
                <table className="table text-start align-middle table-bordered table-hover table-dark table-striped">
                  {" "}
                  <thead>
                    <tr className="text-white">
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Store Outed Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => (
                      <tr key={index}>
                        <td>{product.product_id}</td>
                        <td>{product.product_name}</td>
                        <td>{product.product_type}</td>
                        <td>{product.product_quantity}</td>
                        {showDates ? (
                          <td>
                            <span className="sold_date-format">
                              {format(
                                parseISO(product.outed_date),
                                "MM/dd/yyyy HH:mm:ss"
                              )}
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span className="badge rounded-pill bg-success text-white">
                              {formatDistanceToNow(
                                parseISO(product.outed_date),
                                {
                                  addSuffix: true,
                                }
                              )}
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <h6 className="mb-0 text-white">
                  No Incoming/Padding product(s) yet.
                </h6>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingProducts;
