import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import AddCardIcon from "@mui/icons-material/AddCard";
import PaidIcon from "@mui/icons-material/Paid";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import EditCredit from "./EditCredit";
function Credit({
  handleApprovePayment,
  TotalCredits,
  credits,
  unpaidCredits,
  paidCredits,
  handleDeleteCredit,
  handleEditCredit,
  Error,
  successMessage,
  isLoading,
}) {
  const [showDates, setShowDates] = useState(false);
  const [editingCredit, setEditingCredit] = useState(null);
  const handleEdit = (credits) => {
    setEditingCredit(credits);
  };
  const handleCancelEdit = () => {
    setEditingCredit(null);
  };
  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  return (
    <Dashboard>
      {editingCredit ? (
        <EditCredit
          CreditId={editingCredit.credit_id}
          editingCredit={editingCredit}
          onCancel={handleCancelEdit}
          handleEditCredit={handleEditCredit}
          successMessage={successMessage}
        />
      ) : (
        <>
          <div className="container-fluid pt-4 px-4 mb-5">
            {Error && <div className="alert alert-danger">{Error}</div>}
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <div className="row g-4">
              <div className="col-sm-6 col-xl-4">
                <div className=" text-white revenue-div  rounded d-flex align-items-center justify-content-between p-4">
                  <i className="revenue-icon">
                    <PaymentIcon className="customer-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Credits</p>
                    <h6 className="mb-0">{TotalCredits}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="text-white rounded d-flex revenue-div align-items-center justify-content-between p-4">
                  <i className="revenue-icon">
                    <PaidIcon className="customer-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Paid Credits</p>
                    <h6 className="mb-0">{paidCredits.length || "0"}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4">
                <div className="text-white rounded d-flex revenue-div  align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-area fa-3x revenue-icon">
                    <MoneyOffIcon className="customer-icon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Unpaid Credits</p>
                    <h6 className="mb-0">{unpaidCredits.length || "0"}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-start mb-1">
            <Link className="btn-close-white " onClick={handleShowDates}>
              {showDates ? "Hide dates" : "Show dates"}
            </Link>
          </div>
          <div className="mt-5 mb-5">
            {credits.length === 0 && isLoading ? (
              <div className="nonetable_loader"></div>
            ) : (
              <></>
            )}
          </div>

          <div className="">
            {credits.map((Credit, index) => (
              <div key={index} className="card border-info mb-3 bg-dark ">
                <div className="justify-content-end  card-header border-info text-white bg-gradient d-flex justify-content-between ">
                  <h3 className="">Credit</h3>
                  <button
                    onClick={() =>
                      handleApprovePayment(Credit.credit_id, Credit.status)
                    }
                    disabled={Credit.status === "Paid"}
                    className={`btn btn-md btn-outline-success text-white ${
                      Credit.status === "Paid" ? "disabledButton" : ""
                    }`}
                  >
                    Approve Payment
                  </button>
                </div>{" "}
                <div className="card-body text-white">
                  <h3 className="card-title text-decoration-underline ">
                    Credit of{" "}
                    <Link
                      to={`/agro/customer/transaction-profile/${Credit.post_id}`}
                      className="text-info"
                    >
                      {Credit.customer_name}
                    </Link>
                  </h3>
                  <div className="rounded  p-4 d-flex text-start">
                    <div className="text-center me-lg-5 dsmnone">
                      <PersonIcon className="icon-person   " />
                      <Link
                        to={`/agro/customer/transaction-profile/${Credit.post_id}`}
                        className="text-decoration-none text-primary btn btn-sm btn-outline-info "
                      >
                        <h5 className="fs-5 text-white">
                          {Credit.customer_name}
                        </h5>
                      </Link>
                    </div>
                    <div className="  me-5 ">
                      <dl className="row mb-0">
                        <dt className="col-sm-4">Added Date</dt>
                        <dd className="col-sm-8">
                          {" "}
                          {showDates ? (
                            <span className="badge badge-pill bg-primary text-white">
                              {format(
                                parseISO(Credit.added_date),
                                "MM/dd/yyyy HH:mm:ss"
                              )}
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-info  text-white">
                              {formatDistanceToNow(
                                parseISO(Credit.added_date),
                                {
                                  addSuffix: true,
                                }
                              )}
                            </span>
                          )}
                        </dd>
                        <dt className="col-sm-4">Employee Name</dt>
                        <dd className="col-sm-8">{Credit.employee_name}</dd>
                        <dt className="col-sm-4">Product Name</dt>
                        <dd className="col-sm-8">{Credit.product_name}</dd>
                        <dt className="col-sm-4">Product Unit</dt>
                        <dd className="col-sm-8">{Credit.product_unit}</dd>
                        <dt className="col-sm-4">Product Type</dt>
                        <dd className="col-sm-8">{Credit.product_type}</dd>
                        <dt className="col-sm-4 text-truncate">Quantity</dt>
                        <dd className="col-sm-8">{Credit.product_quantity}</dd>
                        <dt className="col-sm-4">Unit Price</dt>
                        <dd className="col-sm-8">
                          {Credit.product_unit_price}
                        </dd>
                        <dt className="col-sm-4">Total Price</dt>
                        <dd className="col-sm-8">
                          {Credit.product_total_price}
                        </dd>
                        <dt className="col-sm-4">Branch</dt>
                        <dd className="col-sm-8">{Credit.branch}</dd>{" "}
                        <dt className="col-sm-4">Payback Day</dt>
                        <dd className="col-sm-8">
                          <span className="badge badge-pill bg-white text-black">
                            {format(parseISO(Credit.payback_day), "MM/dd/yyyy")}
                          </span>
                        </dd>{" "}
                        <dt className="col-sm-4">Status</dt>
                        {Credit.status === "Unpaid" ? (
                          <dd className="badge badge-pill bg-warning badge-w15 text-black">
                            {Credit.status}
                          </dd>
                        ) : (
                          <dd className="badge badge-pill bg-success badge-w15 text-white">
                            {Credit.status} ✔
                          </dd>
                        )}
                      </dl>
                    </div>
                    <div className="d-lg-flex d-sm-block text-center">
                      <div className="d-block m-1">
                        <Link
                          onClick={() => handleEdit(Credit)}
                          className="btn btn-sm  btn-success p-2"
                        >
                          <AddCardIcon className="text-white" />
                        </Link>{" "}
                      </div>
                      <div className="d-block m-1">
                        {" "}
                        <Link
                          onClick={() => handleDeleteCredit(Credit.credit_id)}
                          className="btn btn-sm btn-danger p-2"
                        >
                          <CreditCardOffIcon className="text-white" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default Credit;
