import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VetEmployeeReportService from "../../../services/vet_employee_report.service";
import AgroEmployeeReportService from "../../../services/agro_employee_report.service";
import { useAuth } from "../../../Context/AuthContext";

function Employeereportmessage() {
  const { employee } = useAuth();
  const [employeeReports, setEmployeeReport] = useState([]);
  const [vetemployeeReports, setVetEmployeeReport] = useState([]);
  const EmployeeID = localStorage.getItem("EmployeeID");
  const Veterinariancriteria = ["Veterinarian"];
  const Agronomistcriteria = [
    "Agronomist",
    "Environmental Manager",
    "Agricultural Engineer",
    "Environmental Engineer",
    "Irrigation Engineer",
    "Agri-Tech Specialist",
    "Agribusiness Manager",
    "Agricultural Marketing Specialist",
    "Agricultural Assistant",
    "HR Manager in Agriculture",
  ];
  const isVeterinarian =
    employee?.field_of_study &&
    Veterinariancriteria.includes(employee.field_of_study);
  const isAgronomist =
    employee?.field_of_study &&
    Agronomistcriteria.includes(employee.field_of_study);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        let response;
        if ([1].includes(employee.employee_role)) {
          response = await AgroEmployeeReportService.getReportByEmployeeID(
            EmployeeID
          );
        } else if ([2].includes(employee.employee_role)) {
          response = await VetEmployeeReportService.getReportByEmployeeID(
            EmployeeID
          );
        }

        if (response) {
          if (response.status === "success") {
            if ([1].includes(employee.employee_role)) {
              setEmployeeReport(response.data);
            } else if ([2].includes(employee.employee_role)) {
              setVetEmployeeReport(response.data);
            }
          } else {
            console.error(`Error fetching employee report: ${response.status}`);
          }
        } else {
          console.error("Empty response received from the server");
        }
      } catch (error) {
        console.error("Error fetching employee report:", error.message);
      }
    };

    fetchReport();

    const intervalId = setInterval(fetchReport, 120000);

    return () => clearInterval(intervalId);
  }, [EmployeeID, employee.employee_role]);

  const reportsToDisplay = isVeterinarian
    ? vetemployeeReports
    : isAgronomist
    ? employeeReports
    : [];
  return (
    <div>
      {reportsToDisplay.map((report) => {
        const reportDate = new Date(report.report_date);
        const today = new Date();
        const differenceInDays = Math.floor(
          (today - reportDate) / (1000 * 60 * 60 * 24)
        );

        // Display the message if it's within 3 days
        if (differenceInDays < 3) {
          return (
            <div className="card border-danger m-3 bgalert ">
              <div className="card-header text-white border-danger">
                <strong>Unrecorded Sales Data Detected❗</strong>
              </div>
              <div className="card-body text-danger">
                <h5 className="card-title">From AOSS Center Software</h5>
                <p className="card-text">
                  {report.report_description}..
                  <Link
                    to={`${
                      [1, 5].includes(employee.employee_role)
                        ? "/dashboard/employee/agro/my-reports"
                        : [2, 5].includes(employee.employee_role)
                        ? "/dashboard/employee/vet/my-reports"
                        : ""
                    }`}
                    className="btn btn-outline-danger btn-sm text-white m-1"
                  >
                    Show Details
                  </Link>
                </p>
              </div>
            </div>
          );
        }
        //
        return null;
      })}
    </div>
  );
}

export default Employeereportmessage;
