import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  SIunits,
  agrochemicalDrugTypes,
  veterinaryDrugTypes,
} from "../UnitsAndTypes/UnitsAndTypes";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useBranchs } from "../APIs/FetchAPIs";
function EditStoreAvailableProducts({
  service,

  handleEdit,
  ProductId,
  onCancel,
  editingProduct,
  products,
  APIError,
  successMessage,
}) {
  const [updatedProductData, setupdatedProductData] = useState({
    product_name: "",
    product_type: "",
    product_unit: "",
    product_quantity: "",
    product_unit_price: "",
    branch: "",
    expiration_date: "",
    ...editingProduct,
  });

  const [serverError, setServerError] = useState("");
  const { branchs } = useBranchs();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedProductData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (selectedOption, field) => {
    setupdatedProductData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleCreateOption = (inputValue, field) => {
    const newOption = { value: inputValue, label: inputValue };
    setupdatedProductData((prevData) => ({
      ...prevData,
      [field]: inputValue,
    }));
    return newOption;
  };

  const productOptions = products.map((agroproduct) => ({
    value: agroproduct.product_name,
    label: agroproduct.product_name,
  }));

  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-black text-start div-center">
      <div className="bg-dark rounded p-4 p-sm-5 my-4 mx-3">
        <div className="d-flex align-items-center justify-content-between  w-100">
          <h3 className="text-white text-bg-gradient">Edit Product Form</h3>
          <div className=" fs-6   text-danger " onClick={onCancel}>
            <HighlightOffIcon />
          </div>
        </div>
        {serverError && (
          <div className="alert alert-success text-dark " role="alert">
            {serverError}
          </div>
        )}
        {successMessage && (
          <div className="alert alert-success text-dark " role="alert">
            {successMessage}
          </div>
        )}

        {APIError && (
          <div className="alert-danger" role="alert">
            {APIError}
          </div>
        )}
        <form
          onSubmit={(event) => handleEdit(event, ProductId, updatedProductData)}
        >
          <div className="mb-3">
            <CreatableSelect
              isClearable
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "product_name")
              }
              onCreateOption={(inputValue) =>
                handleCreateOption(inputValue, "product_name")
              }
              options={productOptions}
              value={
                updatedProductData.product_name
                  ? {
                      value: updatedProductData.product_name,
                      label: updatedProductData.product_name,
                    }
                  : null
              }
              placeholder="Search for a product..."
            />
          </div>
          <div className="mb-3">
            <Select
              options={SIunits}
              placeholder="Product SI unit"
              value={
                SIunits.find(
                  (unit) => unit.value === updatedProductData.product_unit
                ) || null
              }
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "product_unit")
              }
            />
          </div>
          <div className="mb-3">
            <Select
              options={
                service === "vet" ? veterinaryDrugTypes : agrochemicalDrugTypes
              }
              placeholder="Product Type"
              value={
                service === "vet"
                  ? veterinaryDrugTypes.find(
                      (type) => type.value === updatedProductData.product_type
                    ) || null
                  : agrochemicalDrugTypes.find(
                      (type) => type.value === updatedProductData.product_type
                    ) || null
              }
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "product_type")
              }
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">QTY</span>
            <input
              type="text"
              className="form-control p-3"
              id="floatingInput"
              placeholder="Product QTY"
              name="product_quantity"
              value={updatedProductData.product_quantity}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">ETB</span>
            <input
              type="number"
              className="form-control p-3"
              id="floatingInput"
              placeholder="Product Unit Price"
              name="product_unit_price"
              value={updatedProductData.product_unit_price}
              onChange={handleInputChange}
            />
            <span className="input-group-text">.00</span>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">EXP Date</span>
            <input
              type="text"
              className="form-control p-3 disabledInput"
              id="floatingInput"
              placeholder="Product Unit Price"
              name="expiration_date"
              value={updatedProductData.expiration_date}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-floating mt-2">
            <select
              className="form-select"
              id="branch"
              name="branch"
              value={updatedProductData.branch}
              onChange={handleInputChange}
            >
              <option value="">All Branches</option>
              {branchs.map((branch) => (
                <option key={branch.branch_id} value={branch.city}>
                  {branch.city}
                </option>
              ))}
            </select>
            <label htmlFor="floatingBranch">Filter by Branch</label>
          </div>

          <button type="submit" className="btn btn-lg pm w-100 mt-2 p-3">
            Update Product
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditStoreAvailableProducts;
