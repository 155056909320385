// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

const OrderProduct = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};
const getAllOrders = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get All Orders: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting All Orders:", error.message);
    throw error;
  }
};
const getOrderByCustomerId = async (customerId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order/${customerId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get orders by customer ID: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting orders by customer ID:", error.message);
    throw error;
  }
};
const updateCustomerOrder = async (OrderId, updatedCreditData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedCreditData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order/${OrderId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to update order: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error updating order:", error.message);
    throw error;
  }
};
const updateCustomerCreditStatus = async (OrderId, newStatus) => {
  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit/status/${OrderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: newStatus }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to update credit: ${response.status}`);
    }

    const data = await response.json();
    return data; // or return { status: "success", message: "Credit status updated" };
  } catch (error) {
    throw new Error(`Error updating credit: ${error.message}`);
  }
};
const deleteCustomerOrder = async (OrderId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order/${OrderId}`,
      requestOptions
    );

    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }
    if (!response.ok) {
      throw new Error(`Failed to delete Order!: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error deleting Order!:", error.message);
    throw error;
  }
};

const sellOrderedProduct = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };
  // console.info(formData);
  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/order/sell`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions to other componentes
const vetCustomerOrderService = {
  OrderProduct,
  updateCustomerOrder,
  updateCustomerCreditStatus,
  deleteCustomerOrder,
  getOrderByCustomerId,
  getAllOrders,
  sellOrderedProduct,
};

export default vetCustomerOrderService;
