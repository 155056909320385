import React, { useEffect, useState } from "react";
import { useVetStoreCloneProducts } from "../../../../components/APIs/FetchAPIs";
import vetStoreCloneService from "../../../../../services/vet_store_clone_products.service";
import ShowroomProducts from "../../../../components/Products/ShowroomProducts";


function VetStoreCloneProducts() {


  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { vetStoreCloneProducts, isLoading, APIError } =
    useVetStoreCloneProducts();
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this Clone product?")) {
      try {
        const response = await vetStoreCloneService.deleteVetStoreCloneProduct(
          ProductId
        );

        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Clone Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();
    try {
      const response = await vetStoreCloneService.updateVetStoreCloneProduct(
        ProductId,
        updatedProductData
      );
      if (response.status === "success") {
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => {
          window.location.href = "/dashboard/store/vet/clone-products";
        }, 2000);
      } else {
        setError("Failed to update clone product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing clone product:", error);
      setError("Error editing  clone product. Please try again.");
    }
  };
 
  return (
    <div>
      <ShowroomProducts
        products={vetStoreCloneProducts}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        type={"clone"}
        service={"store"}
        servicetype={"vtstore"}
      />
    </div>
  );
}

export default VetStoreCloneProducts;
