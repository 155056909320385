// Import from the env
const api_url = process.env.REACT_APP_API_URL;

// A function to send post request to create a new employee
const createVetCustomer = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };
  const response = await fetch(
    `${api_url}/api/vet/add-customer`,
    requestOptions
  );
  return response;
};

// A function to send get request to get all Customers
const getAllCustomers = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(`${api_url}/api/vet/customers`, requestOptions);
  return response;
};
const deleteCustomer = async (CustomerID) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(
    `${api_url}/api/vet/customer/${CustomerID}`,
    requestOptions
  );
  return response;
};

const editCustomer = async (CustomerID, customerData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(customerData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/${CustomerID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to edit customer: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error editing customer:", error.message);
    throw error;
  }
};

const getCustomerByPostId = async (postId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer/by-post/${postId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get customer: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting customer:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const VetcustomerService = {
  createVetCustomer,
  getAllCustomers,
  deleteCustomer,
  editCustomer,
  getCustomerByPostId,
};

export default VetcustomerService;
