import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
function Weeklyavalable({ Report }) {
  const [data, setData] = useState([]);
  const [IsDisplay, setDisplay] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();

    // Calculate the start and end dates for the previous week
    const previousWeekStartDate = new Date(currentDate);
    previousWeekStartDate.setDate(currentDate.getDate() - currentDay - 6); // Set to the start of the previous week
    const previousWeekEndDate = new Date(previousWeekStartDate);
    previousWeekEndDate.setDate(previousWeekStartDate.getDate() + 6); // Set to the end of the previous week

    // Filter weekly reports for the previous week
    const filteredData = Report.filter((report) => {
      const reportDate = new Date(report.date.replace(" ", "T"));
      return (
        reportDate >= previousWeekStartDate && reportDate <= previousWeekEndDate
      );
    });
    setData(filteredData);

    // Determine if the component should be displayed
    const twoDaysAfterEndDate = new Date(previousWeekEndDate);
    twoDaysAfterEndDate.setDate(previousWeekEndDate.getDate() + 2);
    const shouldDisplay =
      currentDate <= twoDaysAfterEndDate && currentDate >= previousWeekEndDate;
    setDisplay(shouldDisplay);
  }, [Report]);
  const exportToExcel = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();

    const previousWeekStartDate = new Date(currentDate);
    previousWeekStartDate.setDate(currentDate.getDate() - currentDay - 6);
    const previousWeekEndDate = new Date(previousWeekStartDate);
    previousWeekEndDate.setDate(previousWeekStartDate.getDate() + 6);

    const startDateString = previousWeekStartDate
      .toLocaleDateString()
      .replaceAll("/", "-");
    const endDateString = previousWeekEndDate
      .toLocaleDateString()
      .replaceAll("/", "-");

    const fileName = `Weekly_Sales_Report_${startDateString}_to_${endDateString}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };
  // console.log("yes or no?", IsDisplay);
  return (
    <div>
      {IsDisplay && (
        <div className="card-body  wkslcard-body ">
          <h6 className="card-title ">Weekly Sales Report</h6>
          <p className="card-text fs-6">
            Your weekly sales report is ready for download!
          </p>
          <button
            onClick={exportToExcel}
            className="btn btn-sm btn-outline-success w-100"
          >
            Download
          </button>
        </div>
      )}
    </div>
  );
}

export default Weeklyavalable;
