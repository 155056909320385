// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to sell an agricultural product
const addCustomerCredit = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};

// Function to get all Credits
const getAllCredits = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get All Credits: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting All Credits:", error.message);
    throw error;
  }
};

// Function to get sold agricultural products by customer ID
const getCreditByCustomerId = async (customerId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit/${customerId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get credits by customer ID: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting credits by customer ID:", error.message);
    throw error;
  }
};

// Function to update sold agricultural product
const updateCustomerCredit = async (CreditId, updatedCreditData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedCreditData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit/${CreditId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to update credit: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error updating credit:", error.message);
    throw error;
  }
};

const updateCustomerCreditStatus = async (CreditId, newStatus) => {
  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit/status/${CreditId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": getAuthToken(),
        },
        body: JSON.stringify({ status: newStatus }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to update credit: ${response.status}`);
    }

    const data = await response.json();
    return data; // or return { status: "success", message: "Credit status updated" };
  } catch (error) {
    throw new Error(`Error updating credit: ${error.message}`);
  }
};

const deleteCustomerCredit = async (CreditId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vet/customer-credit/${CreditId}`,
      requestOptions
    );

    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }
    if (!response.ok) {
      throw new Error(`Failed to delete Credit: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error deleting Credit:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetCustomerCreditService = {
  addCustomerCredit,
  updateCustomerCredit,
  updateCustomerCreditStatus,
  deleteCustomerCredit,
  getCreditByCustomerId,
  getAllCredits,
};

export default vetCustomerCreditService;
