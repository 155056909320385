// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to sell an agricultural product
const sellVetProduct = async (saleProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(saleProductData),
  };
  // console.log("the service", saleProductData);
  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/sell-product`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};

// Function to get all sold agricultural products
const getAllSoldVetProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/sold-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to get all sold products: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all sold products:", error.message);
    throw error;
  }
};

// Function to get sold agricultural products by customer ID
const getSoldVetProductsByCustomerId = async (customerId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/sold-product/${customerId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get sold products by customer ID: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting sold products by customer ID:", error.message);
    throw error;
  }
};

// Function to update sold agricultural product
const updateSoldVetProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/sold-product/${productId}`,
      requestOptions
    );
    if (response.status === 400) {
      throw new Error(
        "he server could not understand the request due to invalid syntax. Please check your request and try again."
      );
    }
    if (!response.ok) {
      throw new Error(`Failed to update sold product: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error updating sold product:", error.message);
    throw error;
  }
};

const deleteSoldVetProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/sold-product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete sold product: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting sold product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetShowroomService = {
  sellVetProduct,
  getAllSoldVetProducts,
  getSoldVetProductsByCustomerId,
  updateSoldVetProduct,
  deleteSoldVetProduct,
};

export default vetShowroomService;
