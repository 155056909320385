import React, { useEffect, useState } from "react";
import agroStoreStoreService from "../../../../../services/agro_store_available_products.service";
import AddedProductInvoices from "../../../../components/xlsx/AddedProductInvoices";
const getDataFromLocalStorage = () => {
  const data = localStorage.getItem("hash_op_data");
  return data ? JSON.parse(data) : [];
};
function AgroAddedProductInvoice() {
  const data = getDataFromLocalStorage();
  const Products = data.product || [];
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching
      try {
        const response = await agroStoreStoreService.getAddedProductsByIds(
          Products
        );
        if (response.status === "success") {
          setProducts(response.data);
         
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <AddedProductInvoices Products={products} isLoading={isLoading} />
    </div>
  );
}

export default AgroAddedProductInvoice;
