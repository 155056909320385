// Import from the env
const api_url = process.env.REACT_APP_API_URL;

const getReportByEmployeeID = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vetemployee/reports/${EmployeeID}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get employee report: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting employee report:", error.message);
    throw error;
  }
};
const getRemindersByEmployeeID = async (EmployeeID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/vetemployee/reminders/${EmployeeID}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get employee report: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting employee report:", error.message);
    throw error;
  }
};
const DELETEreminder = async (reportId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  try {
    const response = await fetch(
      `${api_url}/api/vetemployee/reminders/${reportId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to delete reminder: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error deleting reminder:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetEmployeeReportService = {
  getReportByEmployeeID,
  getRemindersByEmployeeID,
  DELETEreminder,
};
export default vetEmployeeReportService;
