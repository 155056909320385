// frontendShowroomService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to add a addPayment
const addPayment = async (paymentData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(paymentData),
  };

  try {
    const response = await fetch(`${api_url}/api/a/payment`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(
        data.error || `Failed to add payment: ${response.status}`
      );
    }

    return data;
  } catch (error) {
    console.error("Error adding payment:", error.message);
    throw error;
  }
};

// Function to get all agricultural showroom products
const getAllBalances = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(`${api_url}/api/a/balance`, requestOptions);
    if (!response.ok) {
      throw new Error(`Failed to get all agro balances: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all agro balances:", error.message);
    throw error;
  }
};
const getAllPayments = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(`${api_url}/api/a/payments`, requestOptions);
    if (!response.ok) {
      throw new Error(`Failed to get all payments: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all payments:", error.message);
    throw error;
  }
};

// Function to update sold agricultural product
const addBalance = async (balanceId, BalanceData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(BalanceData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/a/payments/balance/${balanceId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to Add Petty Cash balance: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error Adding Petty Cash balance:", error.message);
    throw error;
  }
};
const deletePayment = async (PaymentID) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  try {
    const response = await fetch(
      `${api_url}/api/a/payment/${PaymentID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to delete payment: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error deleting payment:", error.message);
    throw error;
  }
};
const updatePayment = async (PaymentID, updatedPaymentData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedPaymentData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/a/payment/${PaymentID}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to update payment: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error updating payment:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const agroPettyCash = {
  getAllBalances,
  getAllPayments,
  addBalance,
  addPayment,
  deletePayment,
  updatePayment,
};

export default agroPettyCash;
