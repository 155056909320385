import React from "react";

function PictureViewer({ style, pic }) {
  if (!pic) return null;

  const byteArray = new Uint8Array(pic.data);
  const byteString = String.fromCharCode.apply(null, byteArray);
  const base64String = btoa(byteString);
  return (
    <img
      src={`data:image/jpeg/png;base64,${base64String}`}
      alt="Employee"
      style={{
        ...style,
      }}
    />
  );
}

export default PictureViewer;
