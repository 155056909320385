import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import CopyrightIcon from "../../../assets/images/CopyrightIcon.png";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center", // Center items vertically
    justifyContent: "center", // Center items horizontally
    marginTop: 5,
    textAlign: "center",
  },
  reportTitle: {
    fontSize: 16,
    textAlign: "center",
  },
  CopyrightIconstyle: {
    width: 13,
    height: 13,
    marginLeft: 2,
    marginRight: 2,
    marginTop: -3,
  },
});

const CopyRight = () => {
  const currentYear = new Date().getFullYear(); // Get current year dynamically
  return (
    <View style={styles.titleContainer}>
      <Text>Copyright</Text>
      <Image style={styles.CopyrightIconstyle} src={CopyrightIcon} />
      <Text>{currentYear} AOSS CENTER SOFTWARE, All Rights Reserved</Text>
    </View>
  );
};

export default CopyRight;
