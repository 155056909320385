import React from "react";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceSignature from "./InvoiceSignature";
import logo from "../../../assets/images/aoss_logo.png";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import CopyRight from "./CopyRight";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 64,
    height: 64,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const Invoice = ({ invoice, employeeData, Info, total, products, service }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <InvoiceTitle
        title={`${
          service === "addedproducts" ? "GOODS IN NOTE" : "GOODS OUT NOTE"
        }`}
      />
      <InvoiceNo Info={Info} />
      <BillTo Info={Info} service={service} />
      <InvoiceItemsTable invoice={invoice} total={total} products={products} />
      <InvoiceSignature
        Info={Info}
        employeeData={employeeData}
        service={service}
      />
      <InvoiceThankYouMsg />
      <CopyRight />
    </Page>
  </Document>
);

export default Invoice;
