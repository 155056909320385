import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard/dashboard";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import VerifiedIcon from "@mui/icons-material/Verified";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const StoreTruthTrack = ({
  initialBalanceData,
  handleAddInitialBalancs,
  Error,
  SuccessMessage,
  data,
}) => {
  const [Deposit, setDeposit] = useState(false);
  const { employee, isAdmin } = useAuth();
  const [Newbalance, setNewbalance] = useState(null);
  const handleDeposit = (balance) => {
    setNewbalance(balance);
    setDeposit(!Deposit);
  };
  const CreditAndSold = data?.why?.showroomOutBalance;
  const calculatedBalance =
    data?.why?.initialBalance + data?.why?.showroomINBalance - CreditAndSold;

  const [updatedBalanceData, setupdatedBalanceData] = useState({
    amount: "",
    ...Newbalance,
  });
  // console.log(data);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedBalanceData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <Dashboard>
      <div className="d-lg-flex mb-4 mt-3 d-md-flex d-sm-block justify-content-between mb-4 align-items-center">
        <div className="col-sm-12 col-xl-12">
          <div
            className={`${
              data.verificationMessage !== "Verified"
                ? "unverified-continer rounded h-100 p-4 pb-5"
                : "verified-continer rounded h-100 p-4 pb-5"
            }`}
          >
            <h6 className="mb-4 text-warning text-start">TruthTrack Says:</h6>
            {data.verificationMessage !== "Verified" ? (
              <>
                <NewReleasesIcon className="truthveriIcon" />
              </>
            ) : (
              <>
                <VerifiedIcon className="truthveriIcon" />
              </>
            )}

            <h1>You Are {data.verificationMessage}!</h1>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-xl-12 mt-3 mb-4 me-2">
        <div className="whyveridata text-start rounded h-100 p-4">
          <h6 className="mb-4 text-warning">
            Here is why you are {data?.verificationMessage}
          </h6>
          <dl className="row mb-0">
            <dt className="col-sm-4">Current Showroom Balance</dt>
            <dd className="col-sm-8">
              <span className="badge rounded-pill bg-light text-dark">
                ETB {data?.why?.currentShowroomBalance}
              </span>
            </dd>
            <dt className="col-sm-4">Initial Showroom Balance</dt>
            <dd className="col-sm-8">
              <span className="badge rounded-pill bg-light text-dark">
                ETB {data?.why?.initialBalance}
              </span>
            </dd>
            <dt className="col-sm-4">Showroom IN Balance</dt>
            <dd className="col-sm-8">
              <span className="badge rounded-pill bg-light text-dark">
                ETB {data?.why?.showroomINBalance}
              </span>
            </dd>
            <dt className="col-sm-4">Showroom Out Balance</dt>
            <dd className="col-sm-8">
              <dd className="col-sm-8">
                <span className="badge rounded-pill bg-light text-dark">
                  ETB {data?.why?.showroomOutBalance}
                </span>
              </dd>
            </dd>
          </dl>
          <div className="calculator-truth text-center">
            <h3 className="text-white">
              Balance Deffernce: {calculatedBalance}{" "}
            </h3>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        {Error && (
          <div
            className="alert alert-danger text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            <HighlightOffIcon className="me-3 errricon" />
            <div className="text-center erri">{Error}</div>
          </div>
        )}
        {SuccessMessage && (
          <div
            className="alert alert-success d-flex align-items-center "
            role="alert"
          >
            <CheckCircleIcon className="bi flex-shrink-0 me-2" />
            <div>{SuccessMessage}</div>
          </div>
        )}
        <div className="col-md-12 mb-5">
          <div className="gradient-border-pseudo-- p-4">
            {initialBalanceData.map((balance, index) => (
              <div key={index} className="card-body">
                <h3>ETB {balance.amount}</h3>
                <p>(Initial Balance: ETB {balance.amount})</p>
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <Link
                      className="btn btn-md btn-outline-light "
                      onClick={() => handleDeposit(balance)}
                    >
                      Add Initial Balance
                    </Link>
                    {Deposit ? (
                      <>
                        <form
                          onSubmit={(event) =>
                            handleAddInitialBalancs(
                              event,
                              Newbalance.balance_id,
                              updatedBalanceData
                            )
                          }
                        >
                          <input
                            type="number"
                            className="form-control mt-2"
                            id="paymentInput0"
                            placeholder="Amount"
                            name="amount"
                            value={updatedBalanceData.amount}
                            onChange={handleInputChange}
                          />
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                            disabled={!isAdmin}
                          >
                            Add ETB {updatedBalanceData.amount}
                          </button>
                        </form>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default StoreTruthTrack;
