import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import loginService from "../../../services/login.service";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import logo from "../../../assets/images/aoss_center_logo.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useAuth } from "../../../Context/AuthContext";

const Login = () => {
  const location = useLocation();
  const [employee_email, setEmail] = useState("");
  const [employee_password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serverError, setServerError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disabled state
  const authMessage = localStorage.getItem("authMessage");
  const { isLogged } = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!employee_email && !employee_password) {
      setServerError("Please enter your credentials first");
      return;
    }
    // Client-side validations
    if (!employee_password) {
      setServerError("Please enter your password");
      return;
    }
    let valid = true;

    if (!employee_email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!employee_email.includes("@")) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!employee_password || employee_password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) {
      return;
    }

    // Disable the button during the server response
    setIsButtonDisabled(true);

    // Form submission
    const formData = {
      employee_email,
      employee_password,
    };

    try {
      const loginEmployee = await loginService.logIn(formData);
      const response = await loginEmployee.json();

      if (response.status === "success") {
        localStorage.setItem("employeeID", JSON.stringify(response.data));
        if (location.pathname === "/login") {
          window.location.replace("/verify-otp");
        } else {
          window.location.reload();
        }
      } else {
        setServerError(response.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setServerError("An error has occurred. Please try again later.");
    } finally {
      // Enable the button after the server response
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="">
      <div>
        {authMessage && (
          <div
            className="alert bg-warning  text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            {/* <HighlightOffIcon className="me-3 errricon" /> */}
            <h6 className="text-center erri">{authMessage}</h6>
          </div>
        )}
        {serverError && (
          <div
            className="alert alert-danger text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            <HighlightOffIcon className="me-3 errricon" />
            <div className="text-center erri">{serverError}</div>
          </div>
        )}
        {passwordError && (
          <div
            className="alert alert-danger text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            <HighlightOffIcon className="me-3 errricon" />
            <div className="text-center erri">{passwordError}</div>
          </div>
        )}
        {emailError && (
          <div
            className="alert alert-danger text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            <HighlightOffIcon className="me-3 errricon" />
            <div className="text-center erri">{emailError}</div>
          </div>
        )}

        <form
          transition-style="in:wipe:top-right"
          className="form_main_ mt-3 mb-3"
          onSubmit={handleSubmit}
        >
          <img src={logo} width={90} />

          <p className="heading_">Login</p>

          <div className="inputContainer_">
            <AlternateEmailIcon className="login-icons me-1 " />
            <input
              placeholder="Email"
              id="username"
              className={`inputField_ ${isLogged ? "disabled" : ""}`}
              type="text"
              name="employee_email"
              value={employee_email}
              disabled={isLogged}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <div className="inputContainer_">
            <KeyIcon className="login-icons me-1" />
            <input
              placeholder="Password"
              id="password"
              className="inputField_"
              type="password"
              name="employee_password"
              value={employee_password}
              disabled={isLogged}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>

          <button
            id="button_"
            type="submit"
            className={isButtonDisabled ? "disabledButton" : ""}
            disabled={isLogged}
          >
            {isButtonDisabled ? "Logging in..." : "Login"}
          </button>

          <div className="signupContainr_ mt-2">
            <p>For Only Center's Employees!</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
