// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

const AddBranch = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(`${api_url}/api/branches`, requestOptions);
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};

const deleteBranch = async (BranchId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/branches/${BranchId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete branch: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting branch:", error.message);
    throw error;
  }
};
const getAllBranchs = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(`${api_url}/api/branches`, requestOptions);

    if (!response.ok) {
      throw new Error(`Failed to get all branches: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all branches:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const BranchService = {
  AddBranch,
  deleteBranch,
  getAllBranchs,
};

export default BranchService;
