import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js/auto";

function WeeklyproductqtyLineChart({ data }) {
  const chartRef = useRef(null);
  const [salesData, setSalesData] = useState([]);
  // Create gradient

  useEffect(() => {
    if (data && data.length > 0) {
      // Initialize an object to store weekly sales quantities
      const weeklySalesQuantities = {};

      // Aggregate sales data by week for the last 8 weeks
      const currentDate = new Date();
      for (let i = 7; i >= 0; i--) {
        const weekStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - i * 7
        );
        const weekEndDate = new Date(
          weekStartDate.getFullYear(),
          weekStartDate.getMonth(),
          weekStartDate.getDate() + 6
        );
        const weekKey = getWeekKey(weekStartDate); // Get the week key for aggregation

        // Initialize weekly sales quantity to 0
        weeklySalesQuantities[weekKey] = 0;

        // Aggregate sales data for the week
        data.forEach((item) => {
          const itemDate = new Date(item.added_date);
          if (itemDate >= weekStartDate && itemDate <= weekEndDate) {
            weeklySalesQuantities[weekKey] += parseInt(
              item.product_quantity,
              10
            );
          }
        });
      }

      // Extract weeks and corresponding quantities
      const labels = Object.keys(weeklySalesQuantities);
      const salesData = Object.values(weeklySalesQuantities);

      setSalesData(salesData);

      // Destroy the previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Render the Line Chart
      const ctx = document
        .getElementById("WeeklyproductqtyLineChart")
        .getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 300); // Adjust the gradient coordinates as needed
      gradient.addColorStop(0, "rgba(54, 162, 235, 0.8)"); // Start color
      gradient.addColorStop(1, "#0000"); // End color
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Weekly Added Product",
              data: salesData,
              backgroundColor: gradient,
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 2,
              pointBackgroundColor: "rgba(54, 162, 235, 1)",
              pointBorderColor: "#ffffff",
              pointHoverBackgroundColor: "#ffffff",
              pointHoverBorderColor: "rgba(54, 162, 235, 1)",
              pointRadius: 4, // Increase point size
              fill: {
                target: "origin",
                above: gradient,
              },
            },
          ],
        },
        options: {
          indexAxis: "x", // Display weeks on the x-axis
          scales: {
            y: {
              beginAtZero: true,
              suggestedMin: 0, // Start y-axis at 0
              suggestedMax: Math.max(...salesData) * 1.1, // Set maximum scale for y-axis with 10% padding
            },
          },
          animation: {
            duration: 0, // Disable animations
          },
        },
        plugins: {
          tooltip: {
            backgroundColor: "rgba(54, 162, 235, 0.8)",
          },
        },
      });
    }
  }, [data]);

  // Function to get the week key
  const getWeekKey = (date) => {
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay()
    );
    // Truncate time part
    weekStart.setHours(0, 0, 0, 0);
    return weekStart.toISOString().split("T")[0]; // Use ISO string as the key (without time)
  };

  return <canvas id="WeeklyproductqtyLineChart"></canvas>;
}

export default WeeklyproductqtyLineChart;
