import React from "react";
import page_footer_conlogo from "../../../assets/images/footer-logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="page_footer_con">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-3">
            <p className="mb-4">
              <img
                src={page_footer_conlogo}
                alt="Image"
                className="img-fluid"
              />
            </p>
          </div>
          <div className="col-lg-3">
            <h3 className="page_footer_con-heading">
              <span>Our Center</span>
            </h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/partners">Partners</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>

              <li>
                <Link to="projects">Projects</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h3 className="page_footer_con-heading">
              <span>Our Services</span>
            </h3>
            <ul className="list-unstyled">
              <li>
                <Link>Seed Solutions</Link>
              </li>
              <li>
                <Link>Livestock Essentials</Link>
              </li>
              <li>
                <Link>Farming Tools & Equipment</Link>
              </li>
              <li>
                <Link>Agrochemicals & Plant Nutrients</Link>
              </li>
              <li>
                <Link>Advisory & Training Services</Link>
              </li>{" "}
              <li>
                <Link>Support Services</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h3 className="page_footer_con-heading">
              <span>Contact</span>
            </h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/partners">Our Partners</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="pt-4 foter_copyright">
              <p>
                Copyright &copy; {new Date().getFullYear()}. All Rights Reserved
                Buee Agricultural One Stop Shop Center
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
