import React, { useState, useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { useEmployeeActivity } from "../../APIs/EmployeeAPI";


function ContributionStatusDoughnutChart() {
  const { employeeActivity } = useEmployeeActivity();
  const chartRef = useRef(null);

  useEffect(() => {
    // Process the employeeActivity data to get the count of each status
    const statusData = employeeActivity.reduce((acc, activity) => {
      acc[activity.status] = (acc[activity.status] || 0) + 1;
      return acc;
    }, {});

    // Extract labels and data for Chart.js
    const labels = Object.keys(statusData);
    const data = Object.values(statusData);

    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Render the Doughnut Chart
    const ctx = document
      .getElementById("contributionStatusDoughnutChart")
      .getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: labels.map((status) => colorMapping[status]), // Use color mapping for each status
          },
        ],
      },
    });
  }, [employeeActivity]);

  return <canvas id="contributionStatusDoughnutChart"></canvas>;
}

const colorMapping = {
  Poor: "#ff0303",
  Fair: "#FFFF00",
  Remarkable: "#1d1dd1",
  Good: "#00FF00",
};

export default ContributionStatusDoughnutChart;
