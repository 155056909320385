import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agrocustomerService from "../../../../../services/agro_customer.service";
import agroSoldProduct from "../../../../../services/agro_sold_product.service";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";
import agroCustomerCreditService from "../../../../../services/agro_customer_credit.service";
import agroCustomerOrderService from "../../../../../services/agro_customer_order.service";
import TransactionProfile from "../../../../components/Customer/TransactionProfile";

function AgroTransactionProfile() {
  const [customer, setCustomer] = useState(null);
  const [credits, setCredits] = useState(null);
  const [orders, setOrders] = useState(null);
  const [soldproduct, setSoldProducts] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { agroproducts, APIError } = useAgroShowroomProducts();
  const { post_id } = useParams();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await agrocustomerService.getCustomerByPostId(post_id);

        if (response.status === "success") {
          setCustomer(response.data);
        } else {
          console.error(`Error fetching customer: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching customer:", error.message);
      }
    };

    fetchCustomer();
  }, [post_id]);
  useEffect(() => {
    const fetchtSoldProduct = async () => {
      try {
        const response = await agroSoldProduct.getSoldAgroProductsByCustomerId(
          customer.customer_id
        );

        if (response.status === "success") {
          setSoldProducts(response.data);
        } else {
          console.error(`Error fetching Sold Products: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching sold products:", error.message);
      }
    };

    if (customer) {
      fetchtSoldProduct();
    }
  }, [customer]);

  useEffect(() => {
    const fetchtCredits = async () => {
      try {
        const response = await agroCustomerCreditService.getCreditByCustomerId(
          customer.customer_id
        );

        if (response.status === "success") {
          setCredits(response.data);
        } else {
          console.error(`Error fetching credits: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching credits:", error.message);
      }
    };
    if (customer) {
      fetchtCredits();
    }
  }, [customer]);

  useEffect(() => {
    const fetchtOrders = async () => {
      try {
        const response = await agroCustomerOrderService.getOrderByCustomerId(
          customer.customer_id
        );
        if (response.status === "success") {
          setOrders(response.data);
        } else {
          console.error(`Error fetching orders: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching orders:", error.message);
      }
    };
    if (customer) {
      fetchtOrders();
    }
  }, [customer]);

  const SellOrder = async (formData) => {
    try {
      const Data = {
        ...formData,
        customer_full_name: `${customer.customer_first_name} ${customer.customer_last_name}`,
      };
      // Make a request to your service to sell the ordered product
      const response = await agroCustomerOrderService.sellOrderedProduct(Data);

      // Handle the response from the service
      if (response.status === "true") {
        setSuccess(response.message);
        // You can perform additional actions here if needed
      } else {
        setError(`Error selling order: ${response.status}`);
        // Handle error cases here
      }
    } catch (error) {
      setError("Error selling order:", error.message);
      // Handle error cases here
    }
  };

  const addCredit = async (formData) => {
    try {
      const response = await agroCustomerCreditService.addCustomerCredit(
        formData
      );
      if (response.status === "true") {
        
        return { success: true, message: response.message };
      } else {
        return { success: false, error: response.error };
      }
    } catch (error) {
      throw new Error(error.message || error.toString());
    }
  };
    const handleOrder = async (formData) => {
      try {
        const response = await agroCustomerOrderService.OrderProduct(formData);
        if (response.status === "true") {
          return { success: true, message: response.message };
        } else {
          return { success: false, error: response.error };
        }
      } catch (error) {
        throw new Error(error.message || error.toString());
      }
    };
  return (
    <TransactionProfile
      orders={orders}
      credits={credits}
      soldproduct={soldproduct}
      products={agroproducts}
      customer={customer}
      success={success}
      error={error}
      APIError={APIError}
      SellOrder={SellOrder}
      addCredit={addCredit}
      handleOrder={handleOrder}
    />
  );
}
export default AgroTransactionProfile;
