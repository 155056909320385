import React, { useEffect, useState } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import ProductFormFields from "../../../../components/Forms/AddProductFormFields";
import agroAvailableStoreService from "../../../../../services/agro_store_available_products.service";
import { useAgroAvailableStoreProducts } from "../../../../components/APIs/FetchAPIs";
import { useAuth } from "../../../../../Context/AuthContext";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function AddAgroStoreProduct() {
  const { agroStoreAvailableProduct, APIError } =
    useAgroAvailableStoreProducts();
  const { employee } = useAuth();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [priceChangeConfirmed, setPriceChangeConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const EmployeeBranch = employee?.employee_branch;
  const [agroStoreProductData, setAgroStoreProductData] = useState({
    product_name: "",
    product_type: "",
    product_unit: "",
    product_quantity: "",
    product_unit_price: "",
    branch: "",
    expiration_date: "",
  });
  const [isProductCreated, setIsProductCreated] = useState(false);
  useEffect(() => {
    setAgroStoreProductData((prevData) => ({
      ...prevData,
      branch: EmployeeBranch || "",
    }));
  }, [EmployeeBranch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Validate inputs
    if (
      !agroStoreProductData.product_name ||
      !agroStoreProductData.product_type ||
      !agroStoreProductData.product_unit ||
      !agroStoreProductData.product_quantity ||
      !agroStoreProductData.product_unit_price ||
      !agroStoreProductData.expiration_date
    ) {
      setError("Please fill out all fields.");
      setLoading(false);
      return;
    }
    // Validate product_quantity
    if (isNaN(Number(agroStoreProductData.product_quantity))) {
      setError("Product Quantity must be a valid number.");
      setLoading(false);
      return;
    }

    // Validate expiration date
    const currentDate = new Date();
    const selectedDate = new Date(agroStoreProductData.expiration_date);
    if (selectedDate < currentDate) {
      setError(
        "Expiration date must be greater than or equal to the current date."
      );
      setLoading(false);
      return;
    }

    // Check if the price change has been confirmed
    if (!priceChangeConfirmed) {
      // Validate product_unit_price only if the price change hasn't been confirmed
      const selectedProduct = agroStoreAvailableProduct.find((product) => {
        return (
          product.product_name === agroStoreProductData.product_name &&
          product.branch === agroStoreProductData.branch &&
          product.product_unit === agroStoreProductData.product_unit
        );
      });

      if (selectedProduct) {
        const enteredPrice = parseFloat(
          agroStoreProductData.product_unit_price
        );
        const existingPrice = parseFloat(selectedProduct.product_unit_price);

        if (enteredPrice !== existingPrice) {
          const priceDifference = enteredPrice - existingPrice;
          const message = `Warning: A price change has occurred!
        \nProduct Name: ${selectedProduct.product_name}
        \nCurrent Product Unit Price: Birr ${existingPrice}
        \nEntered Product Unit Price: Birr ${enteredPrice}
        \nPrice Difference: Birr ${priceDifference}
        \nBefore clicking 'Confirm Price Change,' please ensure that you decide to sell the product at the new price of Birr ${enteredPrice} or update the price in the showroom.`;
          setConfirmationMessage(message); // Set confirmation message
          setNewPrice(enteredPrice); // Set new price
          setShowConfirmationModal(true); // Show confirmation modal
          setLoading(false);
          return;
        }
      }
    }
    // Service identifier
    const Service = "Agro";

    // Check for existing service in local storage
    const savedData = localStorage.getItem("hash_op_data");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (
        parsedData.Info &&
        parsedData.Info.Service &&
        parsedData.Info.Service !== Service
      ) {
        setError(
          `Error: A product(s) from ${parsedData.Info.Service} has already been added. Please complete downloading it before trying to add another.`
        );
        setLoading(false);
        return;
      }
    }
    // If no validation errors, proceed to submit the product
    try {
      const response = await agroAvailableStoreService.addAgroStoreProduct(
        agroStoreProductData
      );

      if (response.status === "true") {
        // Provide user feedback about success
        const savedProducts = localStorage.getItem("hash_op_data");
        let products = savedProducts ? JSON.parse(savedProducts).product : [];

        // Check if products is not an array (for some reason)
        if (!Array.isArray(products)) {
          products = []; // Initialize products as an empty array
        }

        // Now you can proceed to push the new product
        products.push(response.data);

        localStorage.setItem(
          "hash_op_data",
          JSON.stringify({
            product: products,
            Info: {
              currentDate: currentDate.toLocaleDateString(),
              currentTime: currentDate.toLocaleTimeString(),
              EmployeeName: `${employee?.employee_first_name} ${employee?.employee_last_name}`,
              AddedBranch: employee?.employee_branch,
              Service: Service,
            },
          })
        );

        setLoading(false);
        setSuccessMessage("Product Added successfully.");
        // Redirect after a delay
        setTimeout(() => {
          window.location.href = "/dashboard/store/agro/clone-products";
        }, 4000);
      } else {
        // Handle other response statuses
        setError("Failed to add product. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding product:", error);
      setError("Error adding product. Please try again.");
    }
  };

  const handleConfirmPriceChange = () => {
    // Update the product unit price with the new price
    setAgroStoreProductData({
      ...agroStoreProductData,
      product_unit_price: newPrice,
    });

    // Close the confirmation modal
    setShowConfirmationModal(false);
    setPriceChangeConfirmed(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgroStoreProductData({ ...agroStoreProductData, [name]: value });
  };

  const handleDateChange = (date) => {
    setAgroStoreProductData((prevData) => ({
      ...prevData,
      expiration_date: date instanceof Date ? date : new Date(date),
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setAgroStoreProductData({
      ...agroStoreProductData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleCreateOption = (inputValue, name) => {
    setAgroStoreProductData({
      ...agroStoreProductData,
      [name]: inputValue,
    });
  };

  const handleCancelModal = () => {
    setShowConfirmationModal(false);
  };

  const productOptions = agroStoreAvailableProduct.map((agroproduct) => ({
    value: agroproduct.product_name,
    label: agroproduct.product_name,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 55,
      minHeight: 55,
    }),
  };

  return (
    <Dashboard>
      <div className=" container-fluid col-sm-12 col-xl-6  ">
        <div className="bg-dark rounded h-100 p-4">
          <h4 className="mb-4 text-info ">Add Product</h4>
          {APIError && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <ErrorIcon className="bi flex-shrink-0 me-2" />
              <div>{APIError}</div>
            </div>
          )}
          {successMessage && (
            <div
              className="alert alert-success d-flex align-items-center "
              role="alert"
            >
              <CheckCircleIcon className="bi flex-shrink-0 me-2" />
              <div>{successMessage}</div>
            </div>
          )}
          {Error && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <ErrorIcon className="bi flex-shrink-0 me-2" />
              <div>{Error}</div>
            </div>
          )}
          <ConfirmationModal
            show={showConfirmationModal}
            message={confirmationMessage}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmPriceChange}
          />
          <form onSubmit={handleSubmit} className="text-start">
            <ProductFormFields
              productOptions={productOptions}
              productData={agroStoreProductData}
              handleDateChange={handleDateChange}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleCreateOption={handleCreateOption}
              customStyles={customStyles}
              isProductCreated={isProductCreated}
              setIsProductCreated={setIsProductCreated}
              type="store"
            />
            {loading ? (
              <button
                disabled
                className="btn btn-lg pm w-100 mt-2 p-3 text-center"
              >
                <div className="btn-loader"></div>
              </button>
            ) : (
              <button type="submit" className="btn btn-lg pm w-100 mt-2 p-3">
                {priceChangeConfirmed
                  ? "I agreed to the price change"
                  : "Add Product"}
              </button>
            )}
          </form>
        </div>
      </div>
    </Dashboard>
  );
}

export default AddAgroStoreProduct;
