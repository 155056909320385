// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to sell an agricultural product
const sellAgroProduct = async (saleProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(saleProductData),
  };
  // console.log("the service", saleProductData);
  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/sell-product`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }
    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};

// Function to get all sold agricultural products
const getAllSoldAgroProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/sold-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all sold agro products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all sold agro products:", error.message);
    throw error;
  }
};

// Function to get sold agricultural products by customer ID
const getSoldAgroProductsByCustomerId = async (customerId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/sold-product/${customerId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get sold agro products by customer ID: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting sold agro products by customer ID:",
      error.message
    );
    throw error;
  }
};

// Function to update sold agricultural product
const updateSoldAgroProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/sold-product/${productId}`,
      requestOptions
    );
     if (response.status === 400) {
       throw new Error("Not authorized to perform this action!");
     }
    if (!response.ok) {
      throw new Error(`Failed to update sold agro product: ${response.status}`);
    }
    if (response.status === 403) {
      throw new Error("Not authorized to perform this action!");
    }
       
    return response.json();
  } catch (error) {
    console.error("Error updating sold agro product:", error.message);
    throw error;
  }
};

const deleteSoldAgroProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/agro/sold-product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete sold agro product: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting sold agro product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const agroShowroomService = {
  sellAgroProduct,
  getAllSoldAgroProducts,
  getSoldAgroProductsByCustomerId,
  updateSoldAgroProduct,
  deleteSoldAgroProduct,
};

export default agroShowroomService;
