import React from "react";
import ContributionGraph from "./ContributionGraph";
import { useEmployeeActivity } from "../APIs/EmployeeAPI";

function Contribution() {
  const { employeeActivity } = useEmployeeActivity();
  const width = "100%";
  const height = 250;
  return (
    <div>
      <ContributionGraph
        data={employeeActivity}
        width={width}
        height={height}
        margin="20px"
      />
    </div>
  );
}

export default Contribution;
