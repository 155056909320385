import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
function EmployeeReminder({ data, DELETEreminder, successMessage, isLoading }) {
  const [searchInput, setSearchInput] = useState("");
  const [showDates, setShowDates] = useState(false);
  const [copiedRowIndex, setCopiedRowIndex] = useState(null);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  const filteredData = data
    ? data.filter((data) => {
        const fieldsToSearch = [
          "report_name",
          "report_id",
          "report_description",
          "employee_name",
          "report_date",
        ];
        return fieldsToSearch.some((field) =>
          String(data[field]).toLowerCase().includes(searchInput.toLowerCase())
        );
      })
    : [];
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  const handleCopyDescription = (index) => {
    navigator.clipboard.writeText(filteredData[index].report_description);
    setCopiedRowIndex(index);
    setTimeout(() => {
      setCopiedRowIndex(null);
    }, 2000);
  };

  return (
    <Dashboard>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4 text-white">
          <div className="col-sm-12 col-xl-12">
            <div className="ovr-3 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <NotificationImportantIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <h5 className="mb-2">Total</h5>
                <h3 className="mb-0">{data.length}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid  pt-4 px-4 mb-3">
        <div className="row d-flex g-4 text-white">
          <div className="d-flex align-items-center">
            <div className="input-group w-50">
              <input
                type="search"
                value={searchInput}
                onChange={handleSearch}
                className="form-control"
                placeholder="Search feedbacks"
              />
              <div className="input-group-append">
                <button className="btn btn-danger" type="button">
                  <i className="fa ">
                    <SearchIcon />
                  </i>
                </button>
              </div>
            </div>
            <div className="ms-3">
              <button
                className="btn btn-light btn-sm me-2"
                onClick={handleShowDates}
              >
                {showDates ? "Hide Date" : "Show Date"}
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      <div className="mt-5 mb-5">
        {filteredData.length === 0 && isLoading ? (
          <div className="nonetable_loader"></div>
        ) : (
          <></>
        )}
      </div>
      <table className="table table-dark table-striped  ">
        <tbody>
          {filteredData.map((data, index) => (
            <tr
              key={index}
              className="align-middle w-100 border border-1  justify-content-center pt-2 pb-4"
            >
              <td>{data.report_description}</td>
              <td>
                {copiedRowIndex === index ? (
                  <span className="ms-2 badge rounded-pill bg-success text-white">
                    Name copied!
                  </span>
                ) : (
                  <ContentPasteIcon
                    onClick={() => handleCopyDescription(index)}
                  />
                )}
              </td>

              {showDates ? (
                <td>
                  <span className="badge rounded-pill bg-primary">
                    {format(parseISO(data.report_date), "MM/dd/yyyy HH:mm:ss")}
                  </span>
                </td>
              ) : (
                <td>
                  <span className="badge rounded-pill bg-warning  text-dark">
                    {formatDistanceToNow(parseISO(data.report_date), {
                      addSuffix: true,
                    })}
                  </span>
                </td>
              )}
              <td>
                <button
                  type="button"
                  onClick={() => DELETEreminder(data.report_id)} // Call DELETEreminder with reportId
                  className="btn btn-success"
                >
                  <TaskAltIcon className="me-1" /> Completed
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Dashboard>
  );
}

export default EmployeeReminder;
