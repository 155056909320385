import React, { useState } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import { useVetPendingproducts } from "../../../../components/APIs/FetchAPIs";
import PendingProducts from "../../../../components/Products/PendingProducts";

function VetPendingProduct() {
  const { vetpendingproducts, APIError, isLoading, handleFetchData } =
    useVetPendingproducts();

  const [showDates, setShowDates] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  return (
    <Dashboard>
      <div className="container-fluid pt-4 px-4 mb-5">
        <h3 className="text-gray">Pending Products...</h3>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-light btn-sm me-2"
            onClick={handleShowDates}
          >
            {showDates ? "Hide Added Date" : "Show Added Date"}
          </button>
        </div>

        <PendingProducts
          products={vetpendingproducts}
          showDates={showDates}
          handleFetchData={handleFetchData}
          isLoading={isLoading}
        />
      </div>
    </Dashboard>
  );
}

export default VetPendingProduct;
