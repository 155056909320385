import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../../Context/AuthContext";
import Dashboard from "../Dashboard/dashboard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
function AddCredit({ customer, onCancel, products, APIError, addCredit }) {
  const [searchinput, setSearchInput] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [saleQuantity, setSaleQuantity] = useState("");
  const [serverError, setServerError] = useState(null);
  const [PaybackDay, setPaybackDay] = useState(dayjs(null));
  const [successMessage, setSuccessMessage] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { employee } = useAuth();
  const customerId = customer.customer_id;
  const employee_name = employee?.employee_first_name;
  const customer_name = customer.customer_first_name;
  const post_id = customer.post_id;
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSelectProduct = (productId) => {
    setSelectedProductId(productId);
  };

  const handleSellProduct = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    // Check if saleQuantity is a positive number
    // if (!saleQuantity || isNaN(saleQuantity) || parseFloat(saleQuantity) <= 0) {
    //   setServerError("Please enter a valid product quantity.");
    //   setIsButtonDisabled(true);
    //   return;
    // }

    // console.log(PaybackDay.format("YYYY-MM-DD"));
    // Check if PaybackDay is set
    if (PaybackDay.format("YYYY-MM-DD") === "Invalid Date") {
      setServerError("Please select a payback date.");
      setIsButtonDisabled(false);
      return;
    }

    const selectedProduct = products.find(
      (product) => product.product_id === selectedProductId
    );

    if (!selectedProduct) {
      setServerError("Please select a product.");
      setIsButtonDisabled(true);
      return;
    }

    const formData = {
      post_id,
      customer_id: customerId,
      customer_name,
      employee_name,
      product_id: selectedProduct.product_id,
      product_name: selectedProduct.product_name,
      product_type: selectedProduct.product_type,
      product_unit: selectedProduct.product_unit,
      product_quantity: parseFloat(saleQuantity),
      product_unit_price: selectedProduct.product_unit_price,
      branch: employee?.employee_branch,
      payback_day: PaybackDay.format("YYYY-MM-DD"),
    };

    try {
      const response = await addCredit(formData);

      if (response.success) {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        setServerError(response.error);
        setIsButtonDisabled(true);
      }
    } catch (error) {
      setServerError(error.message || error.toString());
    } finally {
      setIsButtonDisabled(false);
    }
  };
  // const handleClearSelection = () => {
  //   // Clear the selected product
  //   setSelectedProductId("");
  //   setSaleQuantity(""); // Optionally, reset the sale quantity as well
  // };
  const filteredproduct = products
    ? products
        .filter((product) => {
          const fieldsToSearch = [
            "product_id",
            "product_name",
            "product_type",
            "product_unit",
            "product_unit_price",
          ];
          return fieldsToSearch.some((field) =>
            String(product[field])
              .toLowerCase()
              .includes(searchinput.toLowerCase())
          );
        })
        .slice(0, 5) // Limit to the first 5 matching products
    : [];

  const handleDateChange = (date) => {
    setPaybackDay(date);
  };

  return (
    <Dashboard>
      <div>
        {/* {shouldRenderChild && <SoldProducts SoldProducts={products} />} */}
        {serverError && <div className="alert alert-danger">{serverError}</div>}
        {APIError && <div className="alert alert-danger">{APIError}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className=" tab-header text-white text-start  ">
          <div className="text-start  w-50 mt-4 text-white">
            <label>
              <SearchIcon className="me-2" />
              Search Product
            </label>
            <input
              className="form-control form-control-lg mb-3 w-50 p-3 mt-1 form_search"
              type="search"
              placeholder="Search Product"
              value={searchinput}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="col-sm-12 col-xl-12">
          {searchinput && filteredproduct.length > 0 ? (
            <>
              <div className="bg-secondary rounded h-100 p-4 ">
                <h4 className="mb-4 text-start text-white">Products</h4>
                <div className="table-responsive table-responsive-form">
                  <table className="table table-borderless table-dark ">
                    <thead>
                      <tr>
                        <th scope="col">Product ID</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Product Type</th>
                        <th scope="col">Product Unit </th>
                        <th scope="col">Product Qty</th>
                        <th scope="col">Price</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredproduct.map((products) => (
                        <tr key={products.product_id}>
                          <th scope="row">{products.product_id}</th>
                          <td>{products.product_name}</td>
                          <td>{products.product_type}</td>
                          <td>{products.product_unit}</td>
                          <td>{products.product_quantity}</td>
                          <td>{products.product_unit_price}</td>
                          <td>
                            <button
                              className={`btn btn-md btn-primary ${
                                selectedProductId === products.product_id
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSelectProduct(products.product_id)
                              }
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <div className="col-sm-12 col-xl-12 text-white">
              {searchinput && (
                <div className="alert alert-warning ">
                  <h6>Your search returned no matches. </h6>
                </div>
              )}
            </div>
          )}
          <div className="bsecondary rounded h-100 p-4 mt-3 mb-5 ">
            <div className="d-flex justify-content-between ">
              <h4 className="mb-4 text-start text-white">
                {selectedProductId
                  ? "Selected Product"
                  : "Please Select Product"}
              </h4>
              <button
                type="button"
                onClick={handleSellProduct}
                className={`btn btn-lg btn-success mb-2${
                  isButtonDisabled ? "disabledButton" : ""
                }`}
                disabled={!selectedProductId}
              >
                {!isButtonDisabled ? "Adding Credit..." : "Add Credit"}
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-borderless table-dark text-start form-table">
                <thead className="form-thead">
                  <tr className="form-tr">
                    <th className="form-th" scope="col">
                      Product ID
                    </th>
                    {selectedProductId && (
                      <>
                        <th className="form-th" scope="col">
                          Product Name
                        </th>
                        <th className="form-th" scope="col">
                          Product Type
                        </th>
                        <th className="form-th" scope="col">
                          Product Unit
                        </th>
                        <th className="form-th" scope="col">
                          Payback Day
                        </th>
                        <th className="form-th" scope="col">
                          Enter Product QTY
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="form-tbody">
                  <tr className="form-tr">
                    <td className="form-td">
                      <input
                        type="text"
                        className="form-control w-50 disabledInput"
                        value={selectedProductId}
                        disabled
                      />
                    </td>
                    {selectedProductId && (
                      <>
                        <td className="form-td">
                          <input
                            type="text"
                            className="form-control w-50 disabledInput"
                            value={
                              products.find(
                                (products) =>
                                  products.product_id === selectedProductId
                              )?.product_name || ""
                            }
                            disabled
                          />
                        </td>
                        <td className="form-td">
                          <input
                            type="text"
                            className="form-control w-50 disabledInput"
                            value={
                              products.find(
                                (products) =>
                                  products.product_id === selectedProductId
                              )?.product_type || ""
                            }
                            disabled
                          />
                        </td>
                        <td className="form-td">
                          <div className="input-group mb-3 w-75 ">
                            <span className="input-group-text">ETB</span>
                            <input
                              type="text"
                              className="form-control w-50 disabledInput"
                              value={
                                products.find(
                                  (products) =>
                                    products.product_id === selectedProductId
                                )?.product_unit_price || ""
                              }
                              disabled
                            />
                          </div>
                        </td>
                        <td className="form-td">
                          <div className="input-group mb-3 w-75">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                className="w-75 rounded-1 text-white bg-light border-black"
                                clearable
                                format="DD/MM/YYYY"
                                placeholder="payback day"
                                name="payback_day"
                                value={PaybackDay}
                                onChange={handleDateChange}
                              />
                            </LocalizationProvider>
                          </div>
                        </td>
                        <td className="form-td">
                          <div className="input-group mb-3 w-75">
                            <span className="input-group-text">QTY</span>
                            <input
                              type="text"
                              className="form-control w-50"
                              value={saleQuantity}
                              onChange={(e) => setSaleQuantity(e.target.value)}
                              required
                            />
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default AddCredit;
