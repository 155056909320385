// Function to read the data from the user's local storage
const getAuth = async () => {
  const employee = await JSON.parse(localStorage.getItem("employee"));
  if (employee && employee.employee_token) {
    const decodedToken = await decodeTokenPayload(employee.employee_token);

    // Check if the token is expired
    if (isTokenExpired(decodedToken)) {
      handleTokenExpiration();
      return {};
    }

    employee.employee_role = decodedToken.employee_role;
    employee.employee_id = decodedToken.employee_id;
    employee.employee_first_name = decodedToken.employee_first_name;
    employee.employee_last_name = decodedToken.employee_last_name;
    employee.employee_email = decodedToken.employee_email;
    employee.field_of_study = decodedToken.field_of_study;
    employee.employee_branch = decodedToken.employee_branch;
    employee.employee_salary = decodedToken.employee_salary;
    employee.employee_phone = decodedToken.employee_phone;
    employee.employee_privilege = decodedToken.employee_privilege;
    // Set the EmployeeID in local storage
    localStorage.setItem("EmployeeID", employee.employee_id);
    localStorage.setItem("employeeBranch", employee.employee_branch);

    return employee;
  } else {
    return {};
  }
};

// Function to decode the payload from the token
// The purpose of this code is to take a JWT token, extract its payload, decode it from Base64Url encoding, and then convert the decoded payload into a JavaScript object for further use and manipulation
const decodeTokenPayload = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );
  return JSON.parse(jsonPayload);
};

// Function to check if the token has expired
const isTokenExpired = (decodedToken) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp && decodedToken.exp < currentTime;
};

// Function to handle token expiration
const handleTokenExpiration = () => {
  localStorage.removeItem("employee");
  localStorage.setItem("authMessage", "Your session has expired. Please log in again.");
  window.location.href = "/login"; // Navigate to the login page
};

// Periodically check if the token has expired
const checkTokenExpirationInterval = setInterval(() => {
  const employee = JSON.parse(localStorage.getItem("employee"));
  if (employee && employee.employee_token) {
    const decodedToken = decodeTokenPayload(employee.employee_token);
    if (isTokenExpired(decodedToken)) {
      handleTokenExpiration();
      clearInterval(checkTokenExpirationInterval); // Stop further checks
    }
  }
}, 60000); // Check every minute (adjust as needed)
export default getAuth;
