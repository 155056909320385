import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between", // Justify contents between the containers
    backgroundColor: "#74ca33",
    padding: 7,
    paddingTop: 20,
    paddingBottom: 20,
    width: "100%",
    borderRadius: 53,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

const InvoiceThankYouMsg = () => (
  <View style={styles.titleContainer}>
    <Text>Website: www.aosscenter.com </Text>{" "}
    <Text>Phone: +251-922225363 </Text>{" "}
    <Text>Address: ETH, Buee City administration</Text>{" "}
  </View>
);

export default InvoiceThankYouMsg;
