// frontendStoreService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to add an agricultural store product
const addVetStoreProduct = async (agroStoreProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(agroStoreProductData),
  };
  try {
    const response = await fetch(
      `${api_url}/api/store/vet/add-product`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to add agro store product: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error adding agro store product:", error.message);
    throw error;
  }
};

// Function to get all agricultural store products
const getAllVetStoreProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro store products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all agro store products:", error.message);
    throw error;
  }
};

const updateVetStoreProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update agro store product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating agro store product:", error.message);
    throw error;
  }
};
const deleteVetStoreProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete store product: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting store product:", error.message);
    throw error;
  }
};
const getAddedProductsByIds = async (ProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify({ ids: ProductData }),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/added-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all agro store added products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all agro store added products:",
      error.message
    );
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetStoreService = {
  addVetStoreProduct,
  getAllVetStoreProducts,
  updateVetStoreProduct,
  deleteVetStoreProduct,
  getAddedProductsByIds,
};

export default vetStoreService;
