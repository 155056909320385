import React, { useState } from "react";
import StoreTruthTrack from "../../../../components/TruthTracks/StoreTruthTrack";
import vetStoreTruthTrackService from "../../../../../services/vet_store_truth-track.service";
import {
  useVetStoreInitialBalance,
  useVetStoreTruthTrack,
} from "../../../../components/APIs/FetchAPIs";

function VetStoreTruthTrack() {
  const [SuccessMessage, setSuccessMessage] = useState("");
  const { vetStoreTruthTrack } = useVetStoreTruthTrack();
  const { vetStoreInitialBalance } = useVetStoreInitialBalance();
  const [Error, setError] = useState("");

  const handleAddInitialBalancs = async (event, BalanceId, BalanceData) => {
    event.preventDefault();
    if (BalanceData.amount === "" || BalanceData.amount === 0) {
      alert("Please enter a valid amount to update.");
    }
    try {
      const response = await vetStoreTruthTrackService.AddInitialBalancs(
        BalanceId,
        BalanceData
      );

      if (response.status === "success") {
        setSuccessMessage("Initial Balance updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to updated Initial Balance. Please try again.");
      }
    } catch (error) {
      console.error("Error updating balance:", error);
      setError("Error updating balance. Please try again.");
    }
  };

  return (
    <div>
      <StoreTruthTrack
        initialBalanceData={vetStoreInitialBalance}
        SuccessMessage={SuccessMessage}
        Error={Error}
        data={vetStoreTruthTrack}
        handleAddInitialBalancs={handleAddInitialBalancs}
      />
    </div>
  );
}

export default VetStoreTruthTrack;
