import React from "react";
import img0 from "../../../assets/projects/Picture1.png";
import img2 from "../../../assets/projects/photo_2024-02-06_02-17-40.jpg";
import img3 from "../../../assets/projects/Picture5.png";

function Projects() {
  return (
    <div
      id="projects"
      className="container overflow-hidden justify-content-center"
    >
      <h1 className="mb-4 d5 text-center Titel_con">
        Our <span className="tspan">Projects</span>
      </h1>
      <hr className="w-50 mx-auto mb-5 mb-xl-4 border-info-subtle" />
      <div className="row gy-4 justify-content-center">
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div className="card" style={{ width: "100%" }}>
            <img src={img0} className="card-img-top" alt="img" />
            <div className="card-body">
              <h6 className="card-text">
                Wheat production by irrigation for demonstration
              </h6>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div className="card" style={{ width: "100%" }}>
            <img src={img2} className="card-img-top" alt="img" />
            <div className="card-body">
              <h6 className="card-text">Chick rearing for demonstration</h6>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div className="card" style={{ width: "100%" }}>
            <img src={img3} className="card-img-top" alt="img" />
            <div className="card-body">
              <h6 className="card-text">Vegetable production for demonstration</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
