import React, { useState, useEffect } from "react";
import { useAgroAvailableStoreProducts } from "../../../../components/APIs/FetchAPIs";
import agroStoreAvailableProductsService from "../../../../../services/agro_store_available_products.service";
import StoreAvailableProducts from "../../../../components/Products/StoreAvailableProducts";

function AgrotoreAvailableProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { agroStoreAvailableProduct, isLoading, APIError,handleFetchData } =
    useAgroAvailableStoreProducts();
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response =
          await agroStoreAvailableProductsService.deleteAgroStoreProduct(
            ProductId
          );

        if (response.status === 200) {
          const responseData = await response.json(); // Parse response JSON

          setSuccessMessage(responseData.message); // Display server message
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();

    try {
      const response =
        await agroStoreAvailableProductsService.updateAgroStoreProduct(
          ProductId,
          updatedProductData
        );

      if (response.status === "success") {
        // Provide user feedback about success
        setSuccessMessage("Product updated successfully.");
        // Redirect after a delay
        setTimeout(() => {
          window.location.href = "/dashboard/store/agro/available-products";
        }, 2000);
      } else {
        // Handle other response statuses
        setError("Failed to update product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
    }
  };

  // Call handleSubmit with ProductId and updatedProductData

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);
  return (
    <div>
      <StoreAvailableProducts
        products={agroStoreAvailableProduct}
        Error={Error}
        successMessage={successMessage}
        handleDeleteProduct={handleDeleteProduct}
        isLoading={isLoading}
        APIError={APIError}
        handleEditsubmit={handleEdit}
        handleFetchData={handleFetchData}
      />
    </div>
  );
}

export default AgrotoreAvailableProducts;
