import React, { useState } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import drpd from "../../../../assets/images/drpd.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAllEmployeesReport } from "../../../components/APIs/EmployeeAPI";
import employeeService from "../../../../services/employee.service";
// import ContributionGraph from "../../../components/ContributionGraph/ContributionGraph";
import { Link } from "react-router-dom";
function AllEmployeesActivity() {
  const { allEmployeesReport, setAllEmployeesReport, isLoading } = useAllEmployeesReport();
   
  const [dropdownVisibility, setDropdownVisibility] = useState(
    allEmployeesReport.map(() => false)
  );

  const totalReports = allEmployeesReport.reduce(
    (acc, item) => acc + item.report.length,
    0
  );
  const toggleDropdown = (index) => {
    const newVisibility = [...dropdownVisibility];
    newVisibility[index] = !newVisibility[index];
    setDropdownVisibility(newVisibility);
  };
  const totalEmployees = allEmployeesReport.length;

  const [successMessage, setSuccessMessage] = useState("");

  const DELETEreport = async (reportId) => {
    try {
      const response = await employeeService.DELETEreport(reportId);
      if (response.status === 200) {
        const responseData = await response.json();
        setAllEmployeesReport((prevData) =>
          prevData.filter((item) => item.report_id !== reportId)
        );
        setSuccessMessage(responseData.message);
        setTimeout(() => {
          setSuccessMessage(null);
          window.location.reload();
        }, 3000);
      } else {
        throw new Error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };
  return (
    <Dashboard>
      <div>
        <div className="container-fluid pt-4 px-4">
          <div className="row g-4 text-white">
            <div className="col-sm-6 col-xl-6">
              <div className="ovr-10 rounded d-flex align-items-center justify-content-between p-4">
                <i>Report</i>
                <div className="ms-3 text-end">
                  <p className="mb-2">Total Reports</p>
                  <h6 className="mb-0">{totalReports}</h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-6">
              <div className="ovr-7 rounded d-flex align-items-center justify-content-between p-4">
                <i>Employees</i>
                <div className="ms-3 text-end">
                  <p className="mb-2">Total Employees</p>
                  <h6 className="mb-0">{totalEmployees}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid  pt-4 px-4 mb-2">
          <div className="aedjr text-center rounded p-4">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 text-white text-center ">
                All Employees Daily Job Report
              </h3>
            </div>
          </div>
        </div>
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        <div className="mt-5 mb-5">
          {allEmployeesReport.length === 0 && isLoading ? (
            <div className="nonetable_loader"></div>
          ) : (
            <></>
          )}
        </div>
        {allEmployeesReport &&
          allEmployeesReport.map((item, index) => (
            <div
              className="container-fluid pt-4 px-4 "
              key={item.activity?.activity_Id}
            >
              <div className="secondarybg report_licon text-center rounded p-4 ">
                <div className="justify-content-between d-flex align-items-center">
                  <div className="d-flex align-items-center ">
                    <div className="report-defualt-icon-con  me-3">
                      <AccountCircleIcon className="report_adi" />
                    </div>
                    <div className="report-defualt-name-con text-start">
                      <h5 className="mb-0 text-white">
                        {item.info?.employee_first_name}{" "}
                        {item.info?.employee_last_name}
                      </h5>
                      <p className="mb-0 text-white">Employee</p>
                      <p className="mb-0 text-white">
                        {" "}
                        {item.info?.field_of_study}
                      </p>
                    </div>
                  </div>
                  {item.report.length === 0 ? (
                    <span className="badge me-1 ms-1 bg-success">
                      There is no report yet!
                    </span>
                  ) : (
                    <button type="button" className="btn btn-outline-info">
                      Total
                      <span className="badge me-1 ms-1 bg-danger">
                        {item.report.length}
                      </span>
                      <span className="sr-only">Report(s)</span>
                    </button>
                  )}

                  <div className="d-flex align-items-center ">
                    <div className="report-defualt-icon-con  me-3">
                      <img
                        src={drpd}
                        width={60}
                        className={
                          dropdownVisibility[index] ? "isDRP" : "noDRP"
                        }
                        onClick={() => toggleDropdown(index)}
                      />
                    </div>
                  </div>
                </div>
                {dropdownVisibility[index] && (
                  <div className=" align-items-center rdropdownconteaner ">
                    <div className="report-defualt-icon-con  me-3">
                      {item.report.length > 0 ? (
                        item.report.map((item, index) => (
                          <div className="card bg-dark text-white mt-1" key={index}>
                            <div className="card-header border border-danger  justify-content-between  d-flex align-items-center ">
                              <div className="d-flex ">
                                <h6 className="me-2">Report Name:</h6>
                                <h6>{item.report_name}</h6>
                              </div>{" "}
                              <div className="d-flex ">
                                <h6 className="me-2">Reporter:</h6>
                                <h6>{item.reported_by}</h6>
                              </div>{" "}
                              <div className="d-flex ">
                                <h6 className="me-2">Detected Date:</h6>
                                <h6>{item.report_date}</h6>
                              </div>
                              <Link
                                className="btn btn-md btn-danger"
                                onClick={() => DELETEreport(item.report_id)}
                              >
                                Delete
                              </Link>
                            </div>
                            <div className="card-body">
                              <div className=" mb-0 text-start">
                                <p>{item.report_description}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className=" text-center">
                          <h5 className="mb-0 text-success">
                            There is no report yet!
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </Dashboard>
  );
}

export default AllEmployeesActivity;
