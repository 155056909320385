// frontendService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to sell an agricultural product
const OutVetProduct = async (OutProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(OutProductData),
  };
  try {
    const response = await fetch(
      `${api_url}/api/store/vet/out-product`,
      requestOptions
    );
    if (!response.status === "true") {
      throw new Error(`Error: ${response.error}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error :", error.message);
    throw error;
  }
};
const getOutedProductsByIds = async (ProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify({ ids: ProductData }),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/outed-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all vet store outed products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all vet store outed products:",
      error.message
    );
    throw error;
  }
};
// Function to get all outed agricultural products
const getAllOutedVetProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/outed-products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all outed vet products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all outed vet products:", error.message);
    throw error;
  }
};

// Function to update outed agricultural product
const updateOutedVetProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/outed-product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to update outed vet product: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error updating outed vet product:", error.message);
    throw error;
  }
};

const deleteOutedVetProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/store/vet/outed-product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(
        `Failed to delete outed vet product: ${response.status}`
      );
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting outed vet product:", error.message);
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetStoreOutedProductsService = {
  OutVetProduct,
  getAllOutedVetProducts,
  updateOutedVetProduct,
  deleteOutedVetProduct,
  getOutedProductsByIds,
};

export default vetStoreOutedProductsService;
