import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  SIunits,
  agrochemicalDrugTypes,
  veterinaryDrugTypes,
} from "../UnitsAndTypes/UnitsAndTypes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useBranchs } from "../APIs/FetchAPIs";
const FilterListCard = ({
  showFilterCard,
  handleToggleFilterCard,
  handleFilterByBranch,
  handleFilterByProductUnit,
  handleFilterByProductType,
  branchFilter,
  productUnitFilter,
  productTypeFilter,
  handleSetStartDate,
  handleSetEndDate,
  startDate,
  endDate,
  service,
}) => {
  const { branchs } = useBranchs();
  return (
    <div className={`filter-card ${showFilterCard ? "active" : ""}`}>
      <h3 className="text-primary ">Filter Options</h3>

      <div className="form-floating mt-2">
        <select
          className="form-select"
          id="floatingBranch"
          value={branchFilter}
          onChange={handleFilterByBranch}
        >
          <option value="">All Branches</option>
          {branchs.map((branch) => (
            <option key={branch.branch_id} value={branch.city}>
              {branch.city}
            </option>
          ))}
        </select>
        <label htmlFor="floatingBranch">Filter by Branch</label>
      </div>

      <Select
        options={SIunits}
        className="select- text-start mt-4"
        classNamePrefix="select"
        placeholder="Filter by SI Unit"
        isClearable={true}
        value={
          SIunits.find((option) => option.value === productUnitFilter) || {
            value: "",
            label: "Filter by SI Unit...",
          }
        }
        onChange={handleFilterByProductUnit}
      />

      <Select
        options={
          service === "vet" || service === "Vet"
            ? veterinaryDrugTypes
            : agrochemicalDrugTypes
        }
        placeholder="Product Type"
        className="select- text-start mt-4 mb-3"
        isClearable={true}
        value={
          service === "vet" || service === "Vet"
            ? veterinaryDrugTypes.find(
                (option) => option.value === productTypeFilter
              ) || { value: "", label: "Product Type..." }
            : agrochemicalDrugTypes.find(
                (option) => option.value === productTypeFilter
              ) || { value: "", label: "Product Type..." }
        }
        onChange={handleFilterByProductType}
      />
      <label>Start date</label>
      <div className="form-floating mb-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="mui-datepicker w-100 form-select-date-custom"
            value={startDate}
            onChange={(date) => handleSetStartDate(date)}
          />
        </LocalizationProvider>
      </div>

      <label>End date</label>
      <div className="form-floating mb-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="mui-datepicker w-100 form-select-date-custom"
            value={endDate}
            onChange={(date) => handleSetEndDate(date)}
          />
        </LocalizationProvider>
      </div>
      {/* Button to apply filters (you can customize the text) */}
      <button
        className="btn btn-lg btn-info text-white mt-4"
        onClick={handleToggleFilterCard}
      >
        Apply Filters
      </button>
    </div>
  );
};

export default FilterListCard;
