import React from "react";

export function getCurrentDate() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  return `${month} ${day < 10 ? "0" + day : day}, ${year}`;
}

export function CurrentDate() {
  const currentDate = getCurrentDate();
  return (
    <div>
      <p>Current Date: {currentDate}</p>
    </div>
  );
}
