// Import from the env
const api_url = process.env.REACT_APP_API_URL;

// A function to send post request to create a new employee
const createEmployee = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };
  const response = await fetch(`${api_url}/api/employee`, requestOptions);
  return response;
};

// A function to send get request to get all employees
const getAllEmployees = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(`${api_url}/api/employees`, requestOptions);
  return response;
};

// A function to send a delete request to delete an employee by ID
const deleteEmployee = async (employeeId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  const response = await fetch(
    `${api_url}/api/employee/${employeeId}`,
    requestOptions
  );
  return response;
};

const getEmployeeByBranchAndService = async (
  employeeBranch,
  reqemployeeService
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/currnet-employee/${employeeBranch}/${reqemployeeService}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get employees report: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting employees report:", error.message);
    throw error;
  }
};
// A function to send put request to update an existing employee
const editEmployee = async (employeeId, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/employee/${employeeId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to edit employee: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error editing employee:", error.message);
    throw error;
  }
};
const getAllEmployeeReports = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/admin/all-employees/report`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to get all employees report: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error getting  all employees report:", error.message);
    throw error;
  }
};
const DELETEreport = async (reportId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };
  try {
    const response = await fetch(
      `${api_url}/api/admin/employee/report/${reportId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to delete report: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error deleting report:", error.message);
    throw error;
  }
};
// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const employeeService = {
  createEmployee,
  getAllEmployees,
  deleteEmployee,
  editEmployee,
  getAllEmployeeReports,
  DELETEreport,
  getEmployeeByBranchAndService,
};

export default employeeService;
