import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import feedbackService from "../../../services/survey.service";
import PictureViewer from "../upload/PictureViewer";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";

import { format } from "date-fns";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
function Feedback({ surveys, totalsurvey, isLoading }) {
  const [searchInput, setSearchInput] = useState("");
  const [showDates, setShowDates] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  const filteredsurveys = surveys
    ? surveys.filter((survey) => {
        const fieldsToSearch = [
          "employeeInfo.employee_id",
          "employeeInfo.employee_first_name",
          "employeeInfo.employee_last_name",
          "employeeInfo.employee_branch",
          "employeeInfo.field_of_study",
          "feedback.q1_rating",
          "feedback.q2_achievements",
          "feedback.q3_response_speed",
          "feedback.q4_improvement_areas",
          "feedback.q5_how_satisfied_on_working_environment",
          "feedback.q6_comfortability_with_platforms_and_software",
          "feedback.q7_software_challenges_and_difficulties",
          "feedback.q8_describe_customer_satisfaction",
          "feedback.q9_recurring_issues_from_customers",
          "feedback.q10_improvement_areas_to_customer_satisfaction",
          "feedback.q11_anything_else",
        ];
        return fieldsToSearch.some((field) =>
          String(getNestedValue(survey, field))
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];

  // Helper function to get nested values
  function getNestedValue(obj, path) {
    return path.split(".").reduce((acc, key) => acc[key], obj);
  }
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <Dashboard>
      <>
        <div className="container-fluid pt-4 px-4">
          <div className="row g-4 text-white">
            <div className="col-sm-12 col-xl-12">
              <div className="ovr-3 rounded d-flex align-items-center justify-content-between p-4">
                <i>
                  <ChatIcon className="newsalesicon" />
                </i>
                <div className="ms-3 text-end">
                  <h5 className="mb-2">Total Feedbacks</h5>
                  <h3 className="mb-0">{totalsurvey}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid  pt-4 px-4">
          <div className="row d-flex g-4 text-white">
            <div className="d-flex align-items-center">
              <div className="input-group w-50">
                <input
                  type="search"
                  value={searchInput}
                  onChange={handleSearch}
                  className="form-control"
                  placeholder="Search feedbacks"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fa ">
                      <SearchIcon />
                    </i>
                  </button>
                </div>
              </div>
              <div className="ms-3">
                <button
                  className="btn btn-outline-light btn-sm me-2"
                  onClick={handleShowDates}
                >
                  {showDates ? "Hide Added Date" : "Show Added Date"}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5">
          {filteredsurveys.length === 0 && isLoading ? (
            <div className="nonetable_loader"></div>
          ) : (
            <></>
          )}
        </div>
        {filteredsurveys.map((survey, index) => (
          <div
            key={index}
            className="card custom-card-fb text-start text-white  mb-3 border-0 "
          >
            <div className="card-header text-start d-flex align-items-center ">
              <PictureViewer
                style={{
                  alignItems: "center",
                  maxWidth: "80px",
                  maxHeight: "80px",
                  borderRadius: "20%",
                  backgroundColor: "white",
                }}
                pic={survey.employeeProfile.employee_profile}
              />
              <div className="ms-3 fb-emp-inf">
                <h5>
                  {survey.employeeInfo.employee_first_name}{" "}
                  {survey.employeeInfo.employee_last_name}
                </h5>
                <span className="text-sm-start text-gray d-block txt-mysm">
                  From: {survey.employeeInfo.employee_branch} AOSS Center.
                </span>
                <span className="text-sm-start text-gray d-block txt-mysm">
                  {survey.employeeInfo.field_of_study}
                </span>
              </div>
            </div>
            <div className="card-body custom-card-body-fb">
              <div className=" custom-card-body-fb-child rounded h-100 p-4">
                <dl>
                  <ol className="row mb-0">
                    <li className="col-sm-4 card_lis">
                      Rating Productivity during this month:
                    </li>
                    <dd className="col-sm-8">{survey.feedback.q1_rating}/5</dd>

                    <li className="col-sm-4 card_lis">Achievements: </li>
                    <dd className="col-sm-8">
                      {survey.feedback.q2_achievements}
                    </dd>

                    <li className="col-sm-4 card_lis">Response Speed: </li>
                    <dd className="col-sm-8">
                      {survey.feedback.q3_response_speed}/10
                    </dd>

                    <li className="col-sm-4 card_lis">Improvement Areas:</li>
                    <dd className="col-sm-8">
                      {survey.feedback.q4_improvement_areas}
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Satisfacti On Working Environment:
                    </li>
                    <dd className="col-sm-8">
                      {" "}
                      {survey.feedback.q5_how_satisfied_on_working_environment}
                      /10
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Comfortability On Platforms & Software:
                    </li>
                    <dd className="col-sm-8">
                      {
                        survey.feedback
                          .q6_comfortability_with_platforms_and_software
                      }
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Challenges & Difficulties On The software :
                    </li>
                    <dd className="col-sm-8">
                      {" "}
                      {survey.feedback.q7_software_challenges_and_difficulties}
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Describe Customer Satisfaction:
                    </li>
                    <dd className="col-sm-8">
                      {" "}
                      {survey.feedback.q8_describe_customer_satisfaction}
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Recurring Issues from Customers:
                    </li>
                    <dd className="col-sm-8">
                      {" "}
                      {survey.feedback.q9_recurring_issues_from_customers}
                    </dd>

                    <li className="col-sm-4 card_lis">
                      Improvement Areas for Customer Satisfaction:
                    </li>
                    <dd className="col-sm-8">
                      {" "}
                      {
                        survey.feedback
                          .q10_improvement_areas_to_customer_satisfaction
                      }
                    </dd>

                    <li className="col-sm-4 card_lis">Anything Else: </li>
                    <dd className="col-sm-8">
                      {" "}
                      {survey.feedback.q11_anything_else}
                    </dd>

                    <li className="col-sm-4 card_lis"> Submission Date: </li>

                    {showDates ? (
                      <dd className="col-sm-8">
                        <span className="custom-pill-pink">
                          {format(
                            parseISO(survey.feedback.submission_date),
                            "MM/dd/yyyy HH:mm:ss"
                          )}
                        </span>
                      </dd>
                    ) : (
                      <dd className="col-sm-8">
                        <span className="custom-pill-yellow">
                          {formatDistanceToNow(
                            parseISO(survey.feedback.submission_date),
                            {
                              addSuffix: true,
                            }
                          )}
                        </span>
                      </dd>
                    )}
                  </ol>
                </dl>
              </div>
            </div>
          </div>
        ))}
      </>
    </Dashboard>
  );
}

export default Feedback;
