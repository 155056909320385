import React, { useEffect } from "react";

const Weather = () => {
  useEffect(() => {
    (function (d, s, id) {
      if (d.getElementById(id)) {
        if (window.__TOMORROW__) {
          window.__TOMORROW__.renderWidget();
        }
        return;
      }
      const fjs = d.getElementsByTagName(s)[0];
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";

      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "tomorrow-sdk");
  }, []); // This empty array makes useEffect run only once on component mount
  const containerStyle = {
    paddingBottom: "22px",
    position: "relative",
  };

  const childStyle = {
    position: "absolute",
    bottom: "0",
    transform: "translateX(-50%)",
    left: "50%",
  };

  return (
    <>
      <h1 className="m-3">Waether 🌥️</h1>
      <div
        className="tomorrow"
        data-location-id="038587"
        data-language="EN"
        data-unit-system="METRIC"
        data-skin="dark"
        data-widget-type="aqiPollen"
        style={containerStyle}
      >
        <a
          href="https://www.tomorrow.io/weather-api/"
          rel="nofollow noopener noreferrer"
          target="_blank"
          style={childStyle}
        >
          <img
            alt="Powered by the Tomorrow.io Weather API"
            src="https://weather-website-client.tomorrow.io/img/powered-by.svg"
            width="250"
            height="18"
          />
        </a>
      </div>
    </>
  );
};

export default Weather;
