import React from "react";

function ConfirmationModal({ show, message, onCancel, onConfirm }) {
  return (
    <div
      className={`modal bg-gradient fade ${show ? "show" : ""}`}
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden={!show}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content text-bg-dark border-danger w-100 ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Price Change Confirmation
            </h5>
            <button
              type="button"
              className="close bg-danger text-white border-4 "
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <h6 className="modal-body text-warning ">{message}</h6>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirm}
            >
              Confirm Price Change
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
