import React, { useState } from "react";
import ShowroomTruthTrack from "../../../../components/TruthTracks/ShowroomTruthTrack";
import agroShowroomTruthTrackService from "../../../../../services/agro_showroom_truth-track.service";
import {
  useAgroShowroomTruthTrack,
  useAgroShowromInitialBalance,
} from "../../../../components/APIs/FetchAPIs";

function AgroShowroomTruthTrack() {
  const [SuccessMessage, setSuccessMessage] = useState("");
  const { agroShowroomTruthTrack } = useAgroShowroomTruthTrack();
  const { agroShowroomInitialBalance } = useAgroShowromInitialBalance();
  const [Error, setError] = useState("");

  const handleAddInitialBalancs = async (event, BalanceId, BalanceData) => {
    event.preventDefault();
    if (BalanceData.amount === "" || BalanceData.amount === 0) {
      alert("Please enter a valid amount to update.");
    }
    try {
      const response = await agroShowroomTruthTrackService.AddInitialBalancs(
        BalanceId,
        BalanceData
      );

      if (response.status === "success") {
        setSuccessMessage("Initial Balance updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to updated Initial Balance. Please try again.");
      }
    } catch (error) {
      console.error("Error updating balance:", error);
      setError("Error updating balance. Please try again.");
    }
  };

  return (
    <div>
      <ShowroomTruthTrack
        initialBalanceData={agroShowroomInitialBalance}
        SuccessMessage={SuccessMessage}
        Error={Error}
        data={agroShowroomTruthTrack}
        handleAddInitialBalancs={handleAddInitialBalancs}
      />
    </div>
  );
}

export default AgroShowroomTruthTrack;
