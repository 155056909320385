import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 12,
    marginBottom: 30,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  recipientContainer: {
    marginTop: 8,
    flexDirection: "column",
  },
  recipientText: {
    marginBottom: 4,
    fontWeight: "bold",
  },
});

const InvoiceSignature = ({ Info, employeeData, service }) => (
  <View style={styles.titleContainer}>
    <View style={styles.recipientContainer}>
      <Text style={styles.reportTitle}>DELIVERED</Text>
      <Text style={styles.recipientText}>Name: {Info.EmployeeName}</Text>
      <Text style={styles.recipientText}>Signature:</Text>
      <Text style={styles.recipientText}>
        Date: {Info.currentDate} {Info.currentTime}
      </Text>
    </View>
    {service !== "addedproducts" && (
      <View style={styles.recipientContainer}>
        <Text style={styles.reportTitle}>RECIPIENT</Text>
        <Text style={styles.recipientText}>
          Name: {employeeData.employee_first_name}{" "}
          {employeeData.employee_last_name}
        </Text>
        <Text style={styles.recipientText}>Signature:</Text>
        <Text style={styles.recipientText}>Date:</Text>
      </View>
    )}

    <View style={styles.recipientContainer}>
      <Text style={styles.reportTitle}>APPROVED BY</Text>
      <Text style={styles.recipientText}>Name:</Text>
      <Text style={styles.recipientText}>Signature:</Text>
      <Text style={styles.recipientText}>Date:</Text>
    </View>
  </View>
);

export default InvoiceSignature;
