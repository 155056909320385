import React, { useState } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import "./Backup.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Link } from "react-router-dom";
import Excelicon from "../../../../assets/images/Excel-icon.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { getCurrentDate } from "../../../../util/currentdate";
import useBackupData from "./useBackupData";

function BackUp() {
  const { backupData, exporting, handleExport, loading, error } = useBackupData();
    
  const currentDate = getCurrentDate();
const filteredBackupData = backupData.filter(
  (file) => file.data && file.data.length > 0
);
  return (
    <Dashboard>
      <div className="container mb-3">
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
          <Link
            to=""
            className="d-flex text-white align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
          >
            <span className="fs-3">Backup Files</span>
          </Link>

          <ul className="nav nav-pills">
            <li className="nav-item">
              <button
                disabled
                className="btn btn-outline-gray text-white"
                aria-current="page"
              >
                <FilterListIcon className="text-white" />
                Filter
              </button>
            </li>
            <li className="nav-item">
              <Link className="nav-link">Features</Link>
            </li>
          </ul>
        </header>
      </div>
      <div id="main-content" className="file_manager text-start">
        <div className="container">
          <div className="row clearfix">
            {filteredBackupData.map((file, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-12">
                <div className="filecard">
                  <div className="file">
                    <Link to="" className="text-decoration-none">
                      <div className="hover">
                        <button
                          onClick={() => handleExport(file)}
                          type="button"
                          disabled={loading}
                          className="btn btn-icon btn-success"
                        >
                          <CloudDownloadIcon className="fs-3 text-white " />
                        </button>
                        {/* {exporting.agroproducts && (
                        <ExportToXLSX
                          fileName="Agro_Showroom_Products.xlsx"
                          data={agroproducts}
                        />
                      )} */}
                      </div>
                      <div className="icon rounded-5 ">
                        <img src={Excelicon} alt="" className="rounded-5" />
                      </div>
                      <div className="file-name">
                        <p className="m-b-5 ">{file.fileName}</p>
                        <small>
                          Size: {file.size}
                          <span className="date ">{currentDate}</span>
                        </small>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>{" "}
    </Dashboard>
  );
}

export default BackUp;
