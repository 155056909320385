import React from "react";
import { Link } from "react-router-dom";

function CreditTable({ credits, service }) {
  return (
    <div>
      {credits && (
        <>
          <div className="m-2  ">
            <div className="transaction rounded p-4">
              <h6 className="mb-4 text-center">Cradit History</h6>
              <div className="table-responsive">
                <table className="table table-hover table-striped table-dark  ">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Unit</th>
                      <th scope="col">QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Status</th>
                      <th scope="col">More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {credits.map((Credit, index) => (
                      <tr key={index}>
                        <th scope="row">{Credit.credit_id}</th>

                        <td>{Credit.product_name}</td>

                        <td>{Credit.product_type}</td>
                        <td>{Credit.product_unit}</td>
                        <td>{Credit.product_quantity}</td>

                        <td>{Credit.product_unit_price}</td>
                        <td>{Credit.product_total_price}</td>
                        <td>{Credit.branch}</td>
                        <td>
                          {Credit.status === "Unpaid" ? (
                            <span className="badge badge-pill bg-warning text-black">
                              {Credit.status}
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-success text-white">
                              {Credit.status} ✔
                            </span>
                          )}
                        </td>

                        <td>
                          <Link
                            to={
                              service === "vet"
                                ? "/dashboard/showroom/vet/customer/credits"
                                : "/dashboard/showroom/agro/customer/credits"
                            }
                            className="btn btn-sm btn-outline-info "
                          >
                            Detail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CreditTable;
