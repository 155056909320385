import React, { useState } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";
import SmallQuantityProducts from "../../../../components/Products/SmallQuantityProducts";

function AgroSRSmallQuantityProducts() {
  const { agroproducts, isLoading } = useAgroShowroomProducts();
  const [showDates, setShowDates] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  return (
    <Dashboard>
      <div className="container-fluid pt-4 px-4 mb-5">
        <h3 className="text-gray">Small Quantity Products</h3>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-light btn-sm me-2"
            onClick={handleShowDates}
          >
            {showDates ? "Hide Added Date" : "Show Added Date"}
          </button>
        </div>

        <SmallQuantityProducts
          products={agroproducts}
          showDates={showDates}
        />
      </div>
    </Dashboard>
  );
}

export default AgroSRSmallQuantityProducts;
