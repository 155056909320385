import React, { useEffect, useState } from "react";
import Dashboard from "../../../../components/Dashboard/dashboard";
import agroCustomerOrderService from "../../../../../services/agro_customer_order.service";
import OrderList from "../../../../components/Orders/Order";
import EditOrder from "../../../../components/Orders/EditOrder";
import { useAgroShowroomProducts } from "../../../../components/APIs/FetchAPIs";

function AGOrders() {
  const [showDates, setShowDates] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState("");
  const [Orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [totalDelivered, setTotalDelivered] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [editingOrder, setEditingOrder] = useState(null);
  const { agroproducts } = useAgroShowroomProducts();
   const [loadingOrders, setLoadingOrders] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  const [showFullNames, setshowFullNames] = useState(false);
  const handleShowFullNames = () => {
    setshowFullNames(!showFullNames);
  };
useEffect(() => {
  const fetchAllOrders = async () => {
    setLoadingOrders(true); // Set loadingOrders to true before fetching data
    try {
      const response = await agroCustomerOrderService.getAllOrders();
      if (response.status === "success") {
        setOrders(response.data);
        setTotal(response.data.length);

        let deliveredCount = 0;
        let pendingCount = 0;
        response.data.forEach((order) => {
          if (order.order.status === "Delivered") {
            deliveredCount++;
          } else if (order.order.status === "pending") {
            pendingCount++;
          }
        });
        setTotalDelivered(deliveredCount);
        setTotalPending(pendingCount);
      } else {
        console.error(`Error fetching all orders: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching all orders:", error.message);
    } finally {
      setLoadingOrders(false); // Set loadingOrders to false after fetching data
    }
  };

  fetchAllOrders();
}, []);
  const DELETEOrder = async (OrderId) => {
    try {
      const response = await agroCustomerOrderService.deleteCustomerOrder(
        OrderId
      );

      if (response.status === 200) {
        const responseData = await response.json(); // Parse response JSON
        setOrders((prevData) =>
          prevData.filter((item) => item.order.order_id !== OrderId)
        );
        setSuccessMessage(responseData.message); // Display server message

        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        throw new Error("Error: Product not found");
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };
  const handleEditOrder = async (event, OrderId, updatedOrderData) => {
    event.preventDefault();

    try {
      const response = await agroCustomerOrderService.updateCustomerOrder(
        OrderId,
        updatedOrderData
      );

      if (response.status === "success") {
        // Provide user feedback about success
        setSuccessMessage("Order updated successfully!");
        // Redirect after a delay
        setTimeout(() => {
          window.location.href = "/dashboard/agro/customer/orders";
        }, 2000);
      } else {
        // Handle other response statuses
        setError("Failed to update order. Please try again.");
      }
    } catch (error) {
      console.error("Error editing order:", error);
      setError("Error editing order. Please try again.");
    }
  };
  const handleCancelEdit = () => {
    setEditingOrder(null);
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <Dashboard>
      {editingOrder ? (
        <>
          <EditOrder
            products={agroproducts}
            editingOrder={editingOrder}
            OrderId={editingOrder?.order_id || null}
            onCancel={handleCancelEdit}
            SuccessMessage={SuccessMessage}
            handleEditOrder={handleEditOrder}
          />
        </>
      ) : (
        <>
          {" "}
          <div className="container-fluid pt-4 px-4 mb-5">
            <h3 className="text-gray">Orders</h3>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-outline-light btn-sm me-2"
                onClick={handleShowDates}
              >
                {showDates ? "Hide Added Date" : "Show Added Date"}
              </button>
              <button
                className="btn-ccgray text-white btn-sm me-2"
                onClick={handleShowFullNames}
              >
                {showFullNames ? "Hide Full Names" : "Show Full Names"}
              </button>
            </div>

            <OrderList
              Orders={Orders}
              totalPending={totalPending}
              totalDelivered={totalDelivered}
              total={total}
              DELETEOrder={DELETEOrder}
              showDates={showDates}
              SuccessMessage={SuccessMessage}
              setEditingOrder={setEditingOrder}
              searchInput={searchInput}
              handleSearch={handleSearch}
              showFullNames={showFullNames}
              handleShowFullNames={handleShowFullNames}
              isLoading={loadingOrders}
            />
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default AGOrders;
