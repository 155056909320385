import React from "react";

function Unauthorized() {
  return (
    <div className="unauthorized-container d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h1 className="display-1">403</h1>
        <h2 className="mb-4">Access Denied</h2>
        <p className="lead">
          You don't have the authorization to access the page you requested.
        </p>
        <a href="/" className="btn btn-primary mt-3">
          Go to Homepage
        </a>
      </div>
    </div>
  );
}

export default Unauthorized;
