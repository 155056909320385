// frontendShowroomService.js
const api_url = process.env.REACT_APP_API_URL;

// Function to add an agricultural showroom product
const addVetShowroomProduct = async (ShowroomProductData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(ShowroomProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/add-product`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to add showroom product: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error adding showroom product:", error.message);
    throw error;
  }
};

// Function to get all agricultural showroom products
const getAllVetShowroomProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/products`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all showroom products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error getting all showroom products:", error.message);
    throw error;
  }
};

// Function to update sold agricultural product
const updateVetShowroomProduct = async (productId, updatedProductData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
    body: JSON.stringify(updatedProductData),
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/product/${productId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Failed to update  showroom product: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error updating showroom product:", error.message);
    throw error;
  }
};
const deleteVetShowroomProduct = async (productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/product/${productId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to delete showroom product: ${response.status}`);
    }

    return response; // Returning response to check status in handleDeleteProduct
  } catch (error) {
    console.error("Error deleting showroom product:", error.message);
    throw error;
  }
};

const getPendingProducts = async (branch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getAuthToken(),
    },
  };

  try {
    const response = await fetch(
      `${api_url}/api/showroom/vet/pending-products/${branch}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(
        `Failed to get all vet pending products products: ${response.status}`
      );
    }

    return response.json();
  } catch (error) {
    console.error(
      "Error getting all vet pending products products:",
      error.message
    );
    throw error;
  }
};

// Function to retrieve the authentication token from localStorage
const getAuthToken = () => {
  const employeeData = JSON.parse(localStorage.getItem("employee"));
  return employeeData ? employeeData.employee_token : null;
};

// Export all the functions
const vetShowroomService = {
  addVetShowroomProduct,
  getAllVetShowroomProducts,
  updateVetShowroomProduct,
  deleteVetShowroomProduct,
  getPendingProducts,
};

export default vetShowroomService;
