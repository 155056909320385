import React, { useState } from "react";
import emailGif from "../../../../assets/Gif/email_gif.gif";
import Dashboard from "../../../components/Dashboard/dashboard";
import SendIcon from "@mui/icons-material/Send";
import { useAuth } from "../../../../Context/AuthContext";
import helpService from "../../../../services/help.service";

function HelpRequest() {
  const { employee } = useAuth();
  const [selectedOption, setSelectedOption] = useState("");
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [serverError, setServerError] = useState("");
  const [success, setSuccess] = useState("");
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState("");

  const adjustmentId = localStorage.getItem("adjustment");
  const employeeName = employee?.employee_first_name;
  const employeeId = employee?.employee_id;
  const branch = employee?.employee_branch;
  const employeeRole = employee?.employee_role;

  const getEmployeeRoleString = (role) => {
    switch (role) {
      case 1:
        return "Employee-Agronomist";
      case 2:
        return "Employee-Veterinarian";
      case 3:
        return "Store Manager";
      case 4:
        return "Manager";
      case 5:
        return "Admin";
      default:
        return "";
    }
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowReasonInput(selectedValue === "Other");
    if (selectedValue !== "Other") {
      setTopic(selectedValue);
    }
  };

  const handleReasonChange = (e) => {
    if (selectedOption === "Other") {
      setTopic(e.target.value);
    } else {
      setReason(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const helpData = {
      topic,
      description,
      reason,
      adjustment_id: adjustmentId,
      employee_name: employeeName,
      employee_role: getEmployeeRoleString(employeeRole),
      employee_id: employeeId,
      branch,
    };
    try {
      const response = await helpService.addHelpRequest(helpData);
      if (response.status === "success") {
        setSuccess(response.message);
        localStorage.removeItem("adjustment");
      } else {
        const data = await response.json();
        if (data && data.error) {
          setServerError(data.error);
          console.error("Server error:", data.error); // Log server error
        }
      }
    } catch (error) {
      setServerError(
        error.message || "An error occurred while sending the request."
      );
    }
  };

  return (
    <Dashboard>
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="col-sm-12 col-xl-6 ">
          {serverError && (
            <div className="alert alert-danger" role="alert">
              {serverError}
            </div>
          )}
          {success ? (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          ) : (
            <>
              <div
                className=" rounded h-100 p-4"
                style={{ background: "#0076b5" }}
              >
                <img
                  alt=""
                  src={emailGif}
                  className="text-center"
                  width="200"
                />
                <h6 className="mb-4 text-white">Send Request</h6>
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      name="topic"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      required
                    >
                      <option value="">Open this select menu</option>
                      <option value="Request For Unrecorded Sales Data Report Deletion">
                        Request For Unrecorded Sales Data Report Deletion
                      </option>
                      <option value="Sold Product Correction">
                        Sold Product Correction
                      </option>
                      <option value="Showroom Product Information Adjustment">
                        Showroom Product Information Adjustment
                      </option>
                      <option value="Customer Information Adjustment">
                        Customer Information Adjustment
                      </option>
                      <option value="Confirmation Information Adjustment">
                        Confirmation Information Adjustment
                      </option>
                      <option value="Petty Payment Information Adjustment">
                        Petty Payment Information Adjustment
                      </option>
                      <option value="Credit Information Adjustment">
                        Credit Information Adjustment
                      </option>
                      <option value="Other">Other</option>
                    </select>
                    <label htmlFor="floatingSelect">Topic</label>
                  </div>
                  {showReasonInput && (
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Describe the reason why you selected 'Other'."
                        id="floatingTextarea"
                        style={{ height: "150px" }}
                        value={topic}
                        onChange={handleReasonChange}
                      ></textarea>
                    </div>
                  )}
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                      style={{ height: "150px" }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    <label htmlFor="floatingTextarea">
                      {" "}
                      Leave a description here
                    </label>
                  </div>
                  <div className="form-floating mt-3">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                      style={{ height: "150px" }}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                    <label htmlFor="floatingTextarea">
                      Leave a reason here
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-danger text-white m-3"
                  >
                    Send <SendIcon />
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </Dashboard>
  );
}

export default HelpRequest;
