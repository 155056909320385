import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import { useAuth } from "../../../Context/AuthContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreatableSelect from "react-select/creatable";

function AddCustomer({
  serverError,
  setFormData,
  formData,
  handleSubmit,
  isSubmitting,
  customerData,
  addressOptions,
}) {
  const handleRadioChange = (event) => {
    setFormData({ ...formData, customer_sex: event.target.value });
  };
  const handleChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    } else {
      // Handle the change event from CreatableSelect
      setFormData({ ...formData, customer_address: event.value });
    }
  };
  const handleSelectChange = (selectedOption) => {
    handleSelectChange(selectedOption, "customer_address");
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 42,
      backgroundColor: "#b7b7b7",
    }),
  };
  const handleCreateOption = (inputValue, name) => {
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  return (
    <Dashboard>
      <div className="container-fluid  px-4 col-md-6 ">
        <div className="bg-dark p-4 text-secondary text-start ">
          {serverError && (
            <div className="alert alert-danger" role="alert">
              {serverError}
            </div>
          )}
          <h6 className="mb-4 text-light">Add New Customer</h6>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fullname" className="form-label">
                Customer First Name
              </label>
              <input
                type="text"
                className="form-control custom-form-control"
                id="fullname"
                name="customer_first_name"
                value={formData.customer_first_name}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="mb-3">
              <label htmlFor="fullname" className="form-label">
                Customer Last Name
              </label>
              <input
                type="text"
                className="form-control custom-form-control"
                id="fullname"
                name="customer_last_name"
                value={formData.customer_last_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="pb-1">
              <h6 className="mb-3">Customer Sex</h6>
              <div className="btn-group" role="group">
                <input
                  type="radio"
                  className="btn-check"
                  id="btnradio1"
                  autocomplete="off"
                  name="customer_sex"
                  value="Male"
                  onChange={handleRadioChange}
                  checked={formData.customer_sex === "Male"}
                  required
                />
                <label className="btn btn-outline pm" htmlFor="btnradio1">
                  Male
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  id="btnradio2"
                  value="Female"
                  name="customer_sex"
                  onChange={handleRadioChange}
                  checked={formData.customer_sex === "Female"}
                  required
                />
                <label className="btn btn-outline pm" htmlFor="btnradio2">
                  Female
                </label>{" "}
                <input
                  type="radio"
                  className="btn-check"
                  id="btnradio3"
                  value="Other"
                  name="customer_sex"
                  onChange={handleRadioChange}
                  checked={formData.customer_sex === "Other"}
                  required
                />
                <label className="btn btn-outline pm" htmlFor="btnradio3">
                  Other
                </label>
              </div>
             

              <div className="mt-3">
                <label htmlFor="address" className="form-label">
                  Customer Address
                </label>
                <CreatableSelect
                  className="hight"
                  styles={customStyles}
                  classNamePrefix="select"
                  name="customer_address"
                  onChange={handleChange}
                  onCreateOption={(inputValue) => {
                    handleCreateOption(inputValue, "customer_address");
                  }}
                  options={addressOptions}
                  value={
                    formData.customer_address
                      ? {
                          value: formData.customer_address,
                          label: formData.customer_address,
                        }
                      : null
                  }
                  placeholder="Search customers address"
                />
              </div>
              <div className="mt-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Customer Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control custom-form-control"
                  id="phoneNumber"
                  aria-describedby="phoneNumber"
                  name="customer_phone"
                  value={formData.customer_phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mt-3 mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="address">
                Agree
              </label>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`btn btn-lg w-100 pm ${
                isSubmitting ? "_submited" : ""
              }`}
            >
              {isSubmitting ? (
                <>
                  <div className="loader2 "></div>
                </>
              ) : (
                "Create a Customer"
              )}
            </button>
          </form>
        </div>
      </div>
    </Dashboard>
  );
}

export default AddCustomer;
