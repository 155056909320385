const api_url = process.env.REACT_APP_API_URL;
// console.log(`API URL: ${api_url}`);
// A function to send the login request to the server 
const logIn = async (formData) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  };
  // console.log("About to send request");
  // console.log(requestOptions.body);
  const response = await fetch(`${api_url}/api/employee/login`, requestOptions);
  return response;
}
// A function to verify OTP using Axios
async function verifyOTP(employeeId, otp) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ employee_id: employeeId, otp: otp }),
    };

    const response = await fetch(
      `${api_url}/api/employee/verify-otp`,
      requestOptions
    );

    // Assuming the API returns a JSON response
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    return {
      status: "fail",
      message: "An error occurred during OTP verification.",
    };
  }
}

// A function to log out the user
const logOut = () => {
  localStorage.removeItem("employee");
  localStorage.removeItem("EmployeeID");
};


// Export the functions 
module.exports = {
  logIn,
  verifyOTP,
  logOut
};